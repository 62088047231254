<div class="card card-tasks">
	<div class="card-header" style="cursor: default; padding-top: 0px; min-height: 0px">
		<div style="padding-top: 15px; display: inline-block">
			<h6 class="title d-inline">alert-control-panel</h6>
		</div>

		<button mat-button style="display: inline; float: right; padding-top: 5px" (click)="onCancel()" matTooltip="Close" [matTooltipPosition]="'below'">
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>
	</div>
	<div class="card-body" style="overflow: hidden !important">
		<lib-loading-spinner *ngIf="isLoading" [loadingMessage]="'Loading ...'" [vehicle]="'plane'"></lib-loading-spinner>
		<div *ngIf="!isLoading" style="width: 100%; height: 100%">

			<lib-kendo-grid-parent
				[gridData]="gridData"
				[gridSettings]="gridSettings"
				[style.height.%]="100"
				filterable="menu"
				[sortable]="true"
				[reorderable]="true"
				[resizable]="true"
				[groupable]="true"
				[exportable]="false"
				[columnMenu]="false"
				[gridContextMenuItems]="gridContextMenuItems"
				*ngIf="gridData != undefined"

				#alertControlPanelGrid>
			</lib-kendo-grid-parent>

		</div>
	</div>
</div>
