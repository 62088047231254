<lib-loading-spinner *ngIf="isDataLoading" [loadingMessage]="'Loading Central Units Summary Info...'" [vehicle]="'plane'"></lib-loading-spinner>
<div *ngIf="!isDataLoading && (!fromDashboard || widgetObject?.WidgetGateSystemId)" class="card card-tasks">
	<mat-tab-group animationDuration="0ms" (selectedTabChange)="matTabHasChanged($event)">
		<mat-tab *ngFor="let tab of widgetTabs" [label]="tab.textLabel">
			<ng-template mat-tab-label>
				<span
					[ngClass]="{
						criticalAlarmBadge: alertStatus === 3,
							alarmBadge: alertStatus === 1,
							warningBadge: alertStatus === 2
						}"
					[matBadge]="tab.textLabel === 'Alerts' && countOfAlarms > 0 ? countOfAlarms : undefined"
				>
					{{ tab.textLabel }}
				</span>
			</ng-template>
			<div *ngIf="tab.textLabel === 'Data' && tab.rendered" (resized)="onResized($event)" [style.height.%]="100" class="subTabContainer">
				<div *ngIf="!isDataLoading && assetObj?.Id && dataService.cache.assetsObject[assetObj.Id].Tags" class="parent-table-container">
					<lib-kendo-grid-parent [gridData]="dataService.cache.assetsObject[assetObj.Id].Tags" [gridSettings]="kendoGrids.tags.gridSettings" [widgetObject]="widgetObject" (callSave)="saveGridSettings()" [style.height.%]="100" filterable="menu" [sortable]="true" [reorderable]="true" [resizable]="true" #tagDataGrid> </lib-kendo-grid-parent>
				</div>
			</div>
			<div *ngIf="tab.textLabel === 'Alerts' && tab.rendered" class="subTabContainer">
				<div *ngIf="!isDataLoading && assetObj?.Id && dataService.cache.assetsObject[assetObj.Id].ActiveAlarmTags" class="parent-table-container">
					<lib-kendo-grid-parent [gridData]="dataService.cache.assetsObject[assetObj.Id].ActiveAlarmTags" [gridSettings]="kendoGrids.activeAlerts.gridSettings" [widgetObject]="widgetObject" (callSave)="saveGridSettings()" [style.height.%]="100" filterable="menu" [sortable]="true" [reorderable]="true" [resizable]="true" #alarmDataGrid> </lib-kendo-grid-parent>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
<div *ngIf="!isDataLoading && fromDashboard && !widgetObject?.WidgetGateSystemId" class="card card-tasks center" id="card-whole">
	<lib-centered-message centeredMessage="Please select a System from the Navigation tab on the left."></lib-centered-message>
</div>
