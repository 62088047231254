<div class="card card-tasks" id="card-whole" >
	<div class="card-header user-settings">
		<h5 class="title d-inline" style="font-size: x-large">SignalR Testing</h5>
		<p style="display: inline">My SignalR Client Id: {{mySignalRHubConnectionId}}</p>
		<div style="padding: 0px; float: right; margin-right: 20px; margin-left: 20px" tooltip="Close" placement="bottom" (click)="closeDialog()">
			<i class="tim-icons icon-simple-remove"></i>
		</div>
	</div>
	<div class="card-body user-settings" [style.width.%]="100"
	[style.height.%]="100"
	  style="overflow-y: auto; margin-bottom:15px !important; margin-left:10px !important; ">
		<div class="dataElement">
			Message Sent: <strong>{{ timeSent }}</strong>
		</div>
		<div class="dataElement">
			Message Received: <strong>{{ timeReceived }}</strong>
		</div>
		<br />
		<div class="dataElement">
			Total Loopback Time: <strong>{{ totalTime }} ms</strong>
		</div>
		<br />
		<button class="btn btn-success btn-small" type="button" title="Restart Test" (click)="restartTest()">Restart Loopback Test</button>
		<hr/>


		<div *ngIf="displayContent != null" class="spacer recursive-data-navigator" [style.width]="global.isMobile == false ? '700px' : '200px'" >
			<table id="t14543858" >
				<tbody>
					<tr>
						<td class="signalr-header" colspan="2">Incoming <span *ngIf="!global.isMobile">SignalR Messages from All Groups</span><span *ngIf="global.isMobile">Msgs</span></td>
					</tr>
					<tr>
						<th title="System.Int32" class="dataLabel">Batch <span *ngIf="!global.isMobile">Number</span><span *ngIf="global.isMobile">#</span></th>
						<td class="dataElement" [style.width]="global.isMobile == false ? '500px' : '100px'" >{{displayContent.BatchNumber}}</td>
					</tr>
					<tr>
						<th title="System.Int32" class="dataLabel"><span *ngIf="!global.isMobile">Message</span><span *ngIf="global.isMobile">Msg</span> Count</th>
						<td class="dataElement" [style.width]="global.isMobile == false ? '500px' : '100px'">{{displayContent.MessageCount}}</td>
					</tr>
					<tr>
						<th title="System.Single" class="dataLabel"><span *ngIf="!global.isMobile">Messages Per Second</span><span *ngIf="global.isMobile">Msg/Sec</span></th>
						<td class="dataElement" [style.width]="global.isMobile == false ? '500px' : '100px'">{{displayContent.MessagesPerSecond}}</td>
					</tr>
					<tr>
						<th title="System.Single" class="dataLabel"><span *ngIf="!global.isMobile">Average Messages Per Batch</span><span *ngIf="global.isMobile">Avg Msgs/Batch</span></th>
						<td class="dataElement" [style.width]="global.isMobile == false ? '500px' : '100px'">{{displayContent.AverageMessagesPerBatch}}</td>
					</tr>
					<tr *ngIf="!global.isMobile">
						<th title="UserQuery+SignalRMessage" class="dataLabel">Top Parsed Message</th>
						<td>
							<table id="t14543860">
								<tbody>
									<tr>
										<td class="signalr-header" colspan="2" style="border: 1px solid #4C74B2;">SignalRMessage</td>
									</tr>
									<tr id="sum14543859">
										<td class="single-message" colspan="2" style="border: 1px solid #4C74B2;">UserQuery+SignalRMessage</td>
									</tr>
									<tr>
										<th title="System.String" class="dataLabel">Group Name</th>
										<td class="dataElement" [style.width]="global.isMobile == false ? '500px' : '100px'" style="border: 1px solid #4C74B2;">{{displayContent.TopParsedMessage.GroupName}}</td>
									</tr>
									<tr>
										<th title="System.String" class="dataLabel">TagId</th>
										<td class="dataElement" [style.width]="global.isMobile == false ? '500px' : '100px'" style="border: 1px solid #4C74B2;" >{{displayContent.TopParsedMessage.TagId}}</td>
									</tr>
									<tr>
										<th title="System.String" class="dataLabel">DateMS</th>
										<td class="dataElement" [style.width]="global.isMobile == false ? '500px' : '100px'" style="border: 1px solid #4C74B2;">{{displayContent.TopParsedMessage.DateMS}}</td>
									</tr>
									<tr>
										<th title="System.String" class="dataLabel">Value</th>
										<td class="dataElement" [style.width]="global.isMobile == false ? '500px' : '100px'" style="border: 1px solid #4C74B2;">{{displayContent.TopParsedMessage.Value}}</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>

				</tbody>
			</table>
		</div>
		<h3>Add Tag Form</h3>

		<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit($event)">

			<div>
			  <label for="name">
				Name
			  </label>
			  <input id="name" type="text" formControlName="name">
			</div>

			<div>
			  <label for="tagId">
				Tag Id
			  </label>
			  <input id="tagId" type="number" formControlName="tagId">
			</div>

			<button class="button" type="submit">Add</button>

		  </form>
		<h3>Tags Being Watched</h3>
		<div *ngFor="let item of tagIdsList">
			<span>{{ item.name }} </span>
			<span>{{ item.tagId}}</span>
		  </div>

		  <h3>Observations Received For Tags Being Watched</h3>

		<div [style.height.px]="700">
			<lib-kendo-grid-parent
			[gridData]="rawGridData"
			[gridSettings]="gridSettings"
			filterable="menu"
			[sortable]="true"
			[reorderable]="true"
			[resizable]="true"
			*ngIf="rawGridData !== undefined"

			#tagDataGrid
		>
		</lib-kendo-grid-parent>
		</div>
		<h3>Clients Currently Connected After Tester Loaded</h3>

		<div [style.height.px]="700">
			<lib-kendo-grid-parent
			[gridData]="rawGridConnectedClients"
			[gridSettings]="gridSettingsForConnectedClients"
			filterable="menu"
			[sortable]="true"
			[reorderable]="true"
			[resizable]="true"
			*ngIf="rawGridConnectedClients !== undefined"

			#connectedClientsGrid
		>
		</lib-kendo-grid-parent>
		</div>
		<h3>Messages to Unknown Group</h3>

		<div [style.height.px]="700">
			<lib-kendo-grid-parent
			[gridData]="rawGridUnknownMessages"
			[gridSettings]="gridSettingsForUnknownMessages"
			filterable="menu"
			[sortable]="true"
			[reorderable]="true"
			[resizable]="true"
			*ngIf="rawGridUnknownMessages !== undefined"

			#unknownMessagesGrid
		>
		</lib-kendo-grid-parent>
		</div>

		<div *ngIf="displayContent == null && latestStatus != null" class="spacer recursive-data-navigator" [style.width]="global.isMobile == false ? '600px' : '200px'" >
			<table id="latestStatus131351" >
				<tbody>
					<tr>
						<td class="signalr-header" colspan="2">Current Incoming Message</td>
					</tr>
					<tr>
						<th title="System.Int32" class="dataLabel">Code</th>
						<td class="dataElement" >{{latestStatus.Code}}</td>
					</tr>
					<tr>
						<th title="System.Int32" class="dataLabel">Group Name</th>
						<td class="dataElement">{{latestStatus.GroupName}}</td>
					</tr>
					<tr>
						<th title="System.Single" class="dataLabel">Message</th>
						<td class="dataElement">{{latestStatus.ClientObject}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
