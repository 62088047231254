import { Component, OnInit, Input, NgZone } from "@angular/core";


import { filter } from 'rxjs/operators';

import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../../services/data.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { UtilityService } from '../../services/utility.service';
import { ClipboardService } from 'ngx-clipboard';
import { IGlobal } from '../../_models/global.model';
import _ from 'lodash';
import { ITag } from "../../_models/tag.model";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";

@Component({
	selector: 'lib-gpu-quick-view',
	templateUrl: './gpu-quick-view.component.html',
	styleUrls: ['./gpu-quick-view.component.scss'],
})
export class GpuQuickViewComponent implements OnInit {
	@Input() parentAsset: any;
	@Input() parentTagIds: any;
	@Input() widgetObject: any;
	fullDataCacheSubscription: any;
	theme: string;
	assetTags: any;
	isDataLoading: boolean;
	tagGraphSingleModalSubscription: Subscription;
	widgetGroupSettings: any;
	private swalWithBootstrapButtons: any;
	public label = {
		visible: false,
	};
	public progressStyles: { [key: string]: any } = {
		background: 'darkgrey',
	};
	public global: IGlobal = Global;

	public unitStatus = [12374]; // Unit Status
	public con1Active = [1826]; //Con 1 Active
	public con2Active = [57513]; //Con 2 Active
	public contractor = [];
	public estop = [12384]; //E-stop
	public mode28V = [18802]; //Mode 28V
	public mode400Hz = [18803]; //Mode 400Hz
	public mode = [];
	public ampInAvg = [15032]; //Amp In Avg
	public voltInA = [15029]; //Volt in A
	public voltInB = [15030]; //Volt in B
	public voltInC = [15026]; //Volt in C
	public voltInAvg = [2244]; //Volt in Avg
	public ampOutA = [1857]; //Amp Out A
	public ampOutB = [1858]; //Amp Out B
	public ampOutC = [1859]; //Amp Out C
	public ampOutAvg = [1942]; //Amp Out Avg
	public ampOutCon1 = [12227]; //Amp Out Con 1
	public ampOutCon2 = [12225]; //Amp Out Con 2
	public ampOutDC = [1864]; //Amp Out DC
	public voltsOutDC = [1865]; //Amp Out DC
	public frequencyOut = [12221]; //Frequency Out
	public voltOutA = [4440]; //Volt Out A
	public voltOutB = [4442]; //Volt Out B
	public voltOutC = [4444]; //Volt Out C
	public voltOutAvg = [1935]; //Volt Out Avg

	public asset: any;
	public assetModelId: number = 0;

	public hideCertainJBTStandardObservationIds: boolean = false;

	runtimeUpdateInterval: NodeJS.Timeout;

	private allStdIds = [
		12374, 1826, 57513, 12384, 18802, 18803, 15032, 15029, 2244, 15030,
		15026, 1857, 1942, 1858, 1859, 12227, 12225, 12221, 4440, 1935,
		4442, 4444, 1864, 1865
	];
	public assetModelIdsToRestrictCertainJBTStandardObservationIds: Array<any> =
		[70, 69, 68, 67, 66];

	colorChangedSubscription: any;
	cacheJbtStandardObsLookup: any;
	cacheTagLookup: any;
	signalRTagUpdateSubscription: any;
	signalRAssetGroupUpdateSubscription: any;
	subscriptions: Subscription[] = [];
	private componentName: string = 'gpu-quick-view-tab: ';

	tooltipInformationObject: any = {};
	public guid: string;
	
	constructor(
		public dataService: DataService,
		private signalRCore: SignalRCoreService,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		private sanitizer: DomSanitizer,
		private clipboardApi: ClipboardService,
		private utilityService: UtilityService,
		private zone: NgZone
	) {
		this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success',
				cancelButton: 'btn btn-danger',
			},
			buttonsStyling: false,
		});
	}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						if (Global.Theme === 'light') {
							this.theme = 'light';
						} else if (Global.Theme === 'dark') {
							this.theme = 'dark';
						}
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			if (Global.Theme === 'light') {
				this.theme = 'light';
			} else if (Global.Theme === 'dark') {
				this.theme = 'dark';
			}
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((theme: any) => {
				this.theme = theme;
			});

		
	}
	
	tooltipInformation(controllerName) {
		this.tooltipInformationObject = {
			title: '',
			TagId: '',
			TagName: '',
			ListOfStdIdsUsed: this[controllerName],
			StdId: '',
			StdName: '',
			Value: '',
			ValueWhenActive: '',
			Datetime: ''
		};
		if (Global.User.DebugMode) {
			let foundTag = this.assetTags.find((t) =>
				this[controllerName].includes(
					parseInt(t.JBTStandardObservationId)
				)
			);

			if (foundTag !== undefined) {
				var actualTag = this.dataService.cache.tagsObject[foundTag.TagId];
				this.tooltipInformationObject = {
					title: foundTag.Name,
					TagId: foundTag.TagId,
					TagName: foundTag.TagName,
					ListOfStdIdsUsed: this[controllerName],
					StdId: foundTag.JBTStandardObservationId + ": " + foundTag.Name,
					Value: foundTag.Value,
					ValueWhenActive: foundTag.ValueWhenActive,
					Datetime: actualTag.JBTStandardObservationId == 12374 ? actualTag.DateOfLastChangedValueInMS : actualTag.DateInMilliseconds //-- Unit On should have the date/time when the unit was initially turned on, not the latest date/time. --Kirk T. Sherer, October 16, 2024.
				};

				// return (
				// 	'TagId: ' +
				// 	foundTag.TagId +
				// 	'TagName: ' +
				// 	foundTag.TagName +
				// 	' JBTSTDID: ' +
				// 	foundTag.JBTStandardObservationId +
				// 	' JBTStdName: ' +
				// 	foundTag.Name +
				// 	' Value: ' +
				// 	foundTag.Value +
				// 	' ValueWhenActive: ' +
				// 	foundTag.ValueWhenActive
				// );
			}
		}

		// let foundTag = this.assetTags.find(t => {
		// 	return this.[controllerName] === true;
		// })
		// if (Global.User.DebugMode) {
		// 	for (const key in this
		// 		.ObservationIdMappingObjectForTrueFalseValues) {
		// 		if (key == controllerName) {
		// 			let foundTag = this.assetTags.find((t) =>
		// 				this.ObservationIdMappingObjectForTrueFalseValues[
		// 					key
		// 				].includes(t.JBTStandardObservationId)
		// 			);
		// 			if (foundTag !== undefined) {
		// 				return (
		// 					'TagId: ' +
		// 					foundTag.TagId +
		// 					'TagName: ' +
		// 					foundTag.TagName +
		// 					' JBTSTDID: ' +
		// 					foundTag.JBTStandardObservationId +
		// 					' JBTStdName: ' +
		// 					foundTag.Name +
		// 					' Value: ' +
		// 					foundTag.Value +
		// 					' ValueWhenActive: ' +
		// 					foundTag.ValueWhenActive
		// 				);
		// 			}
		// 		}
		// 	}

		// 	for (const key in this
		// 		.ObservationIdMappingObjectForParseFloatValues) {
		// 		if (key == controllerName) {
		// 			let foundTag = this.assetTags.find((t) =>
		// 				this.ObservationIdMappingObjectForParseFloatValues[
		// 					key
		// 				].includes(t.JBTStandardObservationId)
		// 			);
		// 			if (foundTag !== undefined) {
		// 				return (
		// 					'TagId: ' +
		// 					foundTag.TagId +
		// 					'TagName: ' +
		// 					foundTag.TagName +
		// 					' JBTSTDID: ' +
		// 					foundTag.JBTStandardObservationId +
		// 					' JBTStdName: ' +
		// 					foundTag.Name +
		// 					' Value: ' +
		// 					foundTag.Value +
		// 					' ValueWhenActive: ' +
		// 					foundTag.ValueWhenActive
		// 				);
		// 			}
		// 		}
		// 	}
		// }
	}

	onRightClick(event, controllerName) {
		console.log('right click');
		event.preventDefault();
		if (this.tooltipInformationObject.TagId !== '') {
			let stringToReturn =
				'TagId: ' +
				this.tooltipInformationObject.TagId +
				' TagName: ' +
				this.tooltipInformationObject.TagName +
				' ListOfStdIdsUsed: ' +
				this.tooltipInformationObject.ListOfStdIdsUsed +
				' StdId: ' +
				this.tooltipInformationObject.StdId +
				' Datetime: ' +
				this.tooltipInformationObject.Datetime +
				' Value: ' +
				this.tooltipInformationObject.Value +
				' ValueWhenActive: ' +
				this.tooltipInformationObject.ValueWhenActive;
			this.clipboardApi.copyFromContent(stringToReturn);
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Text Copied to Clipboard.',
				title: 'Copied',
			});
		}
	}

	initialize() {
		console.log('Getting tags....');
		this.cacheJbtStandardObsLookup =
			this.dataService.cache.jbtStandardObservationsObject;
		this.cacheTagLookup = this.dataService.cache.tagsObject;

		this.asset = this.dataService.cache.assetsObject[this.parentAsset.Id];
		this.assetModelId = this.asset.AssetModelId; //-- using this for hiding certain elements on the HTML view. Specific GPU assets don't have some of the things being displayed on the GPU Quick View Summary.
		var hideSomeFields =
			this.assetModelIdsToRestrictCertainJBTStandardObservationIds.firstOrDefault(
				(i: any) => {
					return i == this.assetModelId;
				}
			) == null
				? false
				: true;
		this.hideCertainJBTStandardObservationIds = hideSomeFields;
		console.log(
			'this.hideCertainJBTStandardObservationIds = ' +
				this.hideCertainJBTStandardObservationIds
		);

		// if (this.assetModelIdsToRestrictCertainJBTStandardObservationIds.includes(this.assetModelId)) {
		// 	this.hideCertainJBTStandardObservationIds = true;
		// }
		// else {
		// 	this.hideCertainJBTStandardObservationIds = false;
		// }

		this.dataService
			.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
				this.parentAsset.Id.toString(),
				false,
				this.allStdIds.toString()
			)
			.subscribe((data) => {
				Global.User.DebugMode &&
					console.log(
						': dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O',
						data
					);
				this.dataForDisplay(data);
				this.isDataLoading = false;
				this.getSignalRUpdates();
			});
	}

	returnBackgroundColorBasedOffValue(value: any, parameter: any) {
		let progressStylesBasedOffValue: { [key: string]: any } = {
			background: '',
		};
		if (
			(value < 440 && parameter == 'VoltIn') ||
			(value < 110 && parameter == 'VoltOut') ||
			(value < 30 && parameter == 'AmpOut') ||
			(value < 398 && parameter == 'FrequencyOut')
		)
			progressStylesBasedOffValue.background = 'darkgrey';
		else if (
			(value >= 440 && value <= 515 && parameter == 'VoltIn') ||
			(value >= 110 && value <= 120 && parameter == 'VoltOut') ||
			(value >= 30 && value <= 150 && parameter == 'AmpOut') ||
			(value >= 398 && value <= 402 && parameter == 'FrequencyOut')
		)
			progressStylesBasedOffValue.background = 'limegreen';
		else if (
			(value > 515 && parameter == 'VoltIn') ||
			(value > 120 && parameter == 'VoltOut') ||
			(value > 150 && parameter == 'AmpOut') ||
			(value > 402 && parameter == 'FrequencyOut')
		)
			progressStylesBasedOffValue.background = 'red';
		return progressStylesBasedOffValue;
	}

	dataForDisplay(data: any) {
		this.assetTags = data.map((t) => ({
			TagId: t.Id != undefined ? t.Id : t.TagId,
			Name:
				t.JBTStandardObservation?.Name != undefined
					? t.JBTStandardObservation?.Name
					: this.cacheJbtStandardObsLookup[
							parseInt(t.JBTStandardObservationId)
					  ]?.Name != undefined
					? this.cacheJbtStandardObsLookup[
							parseInt(t.JBTStandardObservationId)
					  ]?.Name
					: this.cacheTagLookup[t.Id != undefined ? t.Id : t.TagId]
							?.ShortTagName,
			JBTStandardObservationId: parseInt(t.JBTStandardObservationId),
			JavascriptDate: new Date(t.DateInMilliseconds),
			Value: t.Value != undefined ? t.Value : t.LastObservationTextValue,
			ValueWhenActive: t.ValueWhenActive,
			TagName: t.Name,
		}));

		let UnitStatus = this.assetTags.find((t) =>
			this.unitStatus.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.UnitStatus =
			UnitStatus == undefined
				? false
				: UnitStatus.Value == '1'
				? true
				: false;
		let Estop = this.assetTags.find((t) =>
			this.estop.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.Estop =
			Estop == undefined ? '-' : Estop.Value == '1' ? 'On' : 'Off';

		let Con1Active = this.assetTags.find((t) =>
			this.con1Active.includes(parseInt(t.JBTStandardObservationId))
		);
		let Con2Active = this.assetTags.find((t) =>
			this.con2Active.includes(parseInt(t.JBTStandardObservationId))
		);
		if (Con1Active != undefined && Con1Active.Value == '1') {
			this.assetTags.Contractor = '1';
			this.contractor = this.con1Active;
		} else if (Con2Active != undefined && Con2Active.Value == '1') {
			this.assetTags.Contractor = '2';
			this.contractor = this.con2Active;
		} else this.assetTags.Contractor = undefined;

		let Mode28V = this.assetTags.find((t) =>
			this.mode28V.includes(parseInt(t.JBTStandardObservationId))
		);
		let Mode400Hz = this.assetTags.find((t) =>
			this.mode400Hz.includes(parseInt(t.JBTStandardObservationId))
		);
		if (Mode28V != undefined && Mode28V.Value == '1') {
			this.assetTags.Mode = '28V';
			this.mode = this.mode28V;
		} else if (Mode400Hz != undefined && Mode400Hz.Value == '1') {
			this.assetTags.Mode = '400Hz';
			this.mode = this.mode400Hz;
		} else this.assetTags.Mode = undefined;

		let VoltInA = this.assetTags.find((t) =>
			this.voltInA.includes(parseInt(t.JBTStandardObservationId))
		);
		if (VoltInA != undefined) {
			this.assetTags.VoltInA = parseFloat(VoltInA.Value).toFixed(1);
			this.assetTags.VoltInAColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltInA,
					'VoltIn'
				);
		} else this.assetTags.VoltInA = undefined;

		let VoltInB = this.assetTags.find((t) =>
			this.voltInB.includes(parseInt(t.JBTStandardObservationId))
		);
		if (VoltInB != undefined) {
			this.assetTags.VoltInB = parseFloat(VoltInB.Value).toFixed(1);
			this.assetTags.VoltInBColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltInB,
					'VoltIn'
				);
		} else this.assetTags.VoltInB = undefined;

		let VoltInC = this.assetTags.find((t) =>
			this.voltInC.includes(parseInt(t.JBTStandardObservationId))
		);
		if (VoltInC != undefined) {
			this.assetTags.VoltInC = parseFloat(VoltInC.Value).toFixed(1);
			this.assetTags.VoltInCColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltInC,
					'VoltIn'
				);
		} else this.assetTags.VoltInC = undefined;
		let VoltInAvg = this.assetTags.find((t) =>
			this.voltInAvg.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.VoltInAvg =
			VoltInAvg == undefined
				? '-'
				: parseFloat(VoltInAvg.Value).toFixed(1);

		let AmpOutA = this.assetTags.find((t) =>
			this.ampOutA.includes(parseInt(t.JBTStandardObservationId))
		);
		if (AmpOutA != undefined) {
			this.assetTags.AmpOutA = parseFloat(AmpOutA.Value).toFixed(1);
			this.assetTags.AmpOutAColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.AmpOutA,
					'AmpOut'
				);
		} else this.assetTags.AmpOutA = undefined;

		let AmpOutB = this.assetTags.find((t) =>
			this.ampOutB.includes(parseInt(t.JBTStandardObservationId))
		);
		if (AmpOutB != undefined) {
			this.assetTags.AmpOutB = parseFloat(AmpOutB.Value).toFixed(1);
			this.assetTags.AmpOutBColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.AmpOutB,
					'AmpOut'
				);
		} else this.assetTags.AmpOutB = undefined;

		let AmpOutC = this.assetTags.find((t) =>
			this.ampOutC.includes(parseInt(t.JBTStandardObservationId))
		);
		if (AmpOutC != undefined) {
			this.assetTags.AmpOutC = parseFloat(AmpOutC.Value).toFixed(1);
			this.assetTags.AmpOutCColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.AmpOutC,
					'AmpOut'
				);
		} else this.assetTags.AmpOutC = undefined;
		let AmpOutAvg = this.assetTags.find((t) =>
			this.ampOutAvg.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.AmpOutAvg =
			AmpOutAvg == undefined
				? '-'
				: parseFloat(AmpOutAvg.Value).toFixed(1);

		let VoltOutA = this.assetTags.find((t) =>
			this.voltOutA.includes(parseInt(t.JBTStandardObservationId))
		);
		if (VoltOutA != undefined) {
			this.assetTags.VoltOutA = parseFloat(VoltOutA.Value).toFixed(1);
			this.assetTags.VoltOutAColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltOutA,
					'VoltOut'
				);
		} else this.assetTags.VoltOutA = undefined;

		let VoltOutB = this.assetTags.find((t) =>
			this.voltOutB.includes(parseInt(t.JBTStandardObservationId))
		);
		if (VoltOutB != undefined) {
			this.assetTags.VoltOutB = parseFloat(VoltOutB.Value).toFixed(1);
			this.assetTags.VoltOutBColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltOutB,
					'VoltOut'
				);
		} else this.assetTags.VoltOutB = undefined;

		let VoltOutC = this.assetTags.find((t) =>
			this.voltOutC.includes(parseInt(t.JBTStandardObservationId))
		);
		if (VoltOutC != undefined) {
			this.assetTags.VoltOutC = parseFloat(VoltOutC.Value).toFixed(1);
			this.assetTags.VoltOutCColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltOutC,
					'VoltOut'
				);
		} else this.assetTags.VoltOutC = undefined;

		let FrequencyOut = this.assetTags.find((t) =>
			this.frequencyOut.includes(parseInt(t.JBTStandardObservationId))
		);
		if (FrequencyOut != undefined) {
			this.assetTags.FrequencyOut = parseFloat(
				FrequencyOut.Value
			).toFixed(1);
			this.assetTags.FrequencyOutColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.FrequencyOut,
					'FrequencyOut'
				);
		} else this.assetTags.FrequencyOut = undefined;

		let VoltOutAvg = this.assetTags.find((t) =>
			this.voltOutAvg.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.VoltOutAvg =
			VoltOutAvg == undefined
				? '-'
				: parseFloat(VoltOutAvg.Value).toFixed(1);

		let AmpInAvg = this.assetTags.find((t) =>
			this.ampInAvg.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.AmpInAvg =
			AmpInAvg == undefined ? '-' : parseFloat(AmpInAvg.Value).toFixed(1);
		let AmpOutCon1 = this.assetTags.find((t) =>
			this.ampOutCon1.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.AmpOutCon1 =
			AmpOutCon1 == undefined
				? '-'
				: parseFloat(AmpOutCon1.Value).toFixed(1);
		let AmpOutCon2 = this.assetTags.find((t) =>
			this.ampOutCon2.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.AmpOutCon2 =
			AmpOutCon2 == undefined
				? '-'
				: parseFloat(AmpOutCon2.Value).toFixed(1);
		let AmpOutDC = this.assetTags.find((t) =>
			this.ampOutDC.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.AmpOutDC =
			AmpOutDC == undefined ? '-' : parseFloat(AmpOutDC.Value).toFixed(1);
		let VoltsOutDC = this.assetTags.find((t) =>
			this.voltsOutDC.includes(parseInt(t.JBTStandardObservationId))
		);
		this.assetTags.VoltsOutDC =
			VoltsOutDC == undefined ? '-' : parseFloat(VoltsOutDC.Value).toFixed(1);


		//Get Run Time Data if the Unit is On
		if (this.assetTags.UnitStatus) {
			this.getRuntimeData();
			this.assetTags.Unit = 'RUNNING';
			this.runtimeUpdateInterval = setInterval(() => {
				// Update the runtime values every minute
				this.updateRuntimes();
			}, 100);
		} else {
			this.assetTags.Unit = 'OFF';
			this.assetTags.RunTime = ' ';
		}
	}

	dataUpdate(dataUpdateTagObj: any) {
		//Update Unit Status
		if (
			this.unitStatus.includes(dataUpdateTagObj.JBTStandardObservationId)
		) {
			this.assetTags.UnitStatus =
				dataUpdateTagObj.Value == '1' ? true : false;
			if (this.assetTags.UnitStatus) {
				this.getRuntimeData();
				this.assetTags.Unit = 'RUNNING';
				this.runtimeUpdateInterval = setInterval(() => {
					// Update the runtime values every minute
					this.updateRuntimes();
				}, 100);
			} else {
				this.assetTags.Unit = 'OFF';
				this.assetTags.RunTime = ' ';
			}
		} else if (
			this.con1Active.includes(
				dataUpdateTagObj.JBTStandardObservationId
			) &&
			dataUpdateTagObj.Value == '1'
		) {
			this.assetTags.Contractor = '1';
			this.contractor = this.con1Active;
		} else if (
			this.con2Active.includes(
				dataUpdateTagObj.JBTStandardObservationId
			) &&
			dataUpdateTagObj.Value == '1'
		) {
			this.assetTags.Contractor = '2';
			this.contractor = this.con2Active;
		} else if (
			this.mode28V.includes(dataUpdateTagObj.JBTStandardObservationId) &&
			dataUpdateTagObj.Value == '1'
		) {
			this.assetTags.Mode = '28V';
			this.mode = this.mode28V;
		} else if (
			this.mode400Hz.includes(
				dataUpdateTagObj.JBTStandardObservationId
			) &&
			dataUpdateTagObj.Value == '1'
		) {
			this.assetTags.Mode = '400Hz';
			this.mode = this.mode400Hz;
		} else if (
			this.estop.includes(dataUpdateTagObj.JBTStandardObservationId)
		)
			this.assetTags.Estop = dataUpdateTagObj.Value == '1' ? 'On' : 'Off';
		else if (
			this.ampInAvg.includes(dataUpdateTagObj.JBTStandardObservationId)
		)
			this.assetTags.AmpInAvg = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
		else if (
			this.voltInA.includes(dataUpdateTagObj.JBTStandardObservationId)
		) {
			this.assetTags.VoltInA = parseFloat(dataUpdateTagObj.Value).toFixed(
				1
			);
			this.assetTags.VoltInAColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltInA,
					'VoltIn'
				);
		} else if (
			this.voltInB.includes(dataUpdateTagObj.JBTStandardObservationId)
		) {
			this.assetTags.VoltInB = parseFloat(dataUpdateTagObj.Value).toFixed(
				1
			);
			this.assetTags.VoltInBColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltInB,
					'VoltIn'
				);
		} else if (
			this.voltInC.includes(dataUpdateTagObj.JBTStandardObservationId)
		) {
			this.assetTags.VoltInC = parseFloat(dataUpdateTagObj.Value).toFixed(
				1
			);
			this.assetTags.VoltInCColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltInC,
					'VoltIn'
				);
		} else if (
			this.voltInAvg.includes(dataUpdateTagObj.JBTStandardObservationId)
		)
			this.assetTags.VoltInAvg = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
		else if (
			this.ampOutA.includes(dataUpdateTagObj.JBTStandardObservationId)
		) {
			this.assetTags.AmpOutA = parseFloat(dataUpdateTagObj.Value).toFixed(
				1
			);
			this.assetTags.AmpOutAColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.AmpOutA,
					'AmpOut'
				);
		} else if (
			this.ampOutB.includes(dataUpdateTagObj.JBTStandardObservationId)
		) {
			this.assetTags.AmpOutB = parseFloat(dataUpdateTagObj.Value).toFixed(
				1
			);
			this.assetTags.AmpOutBColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.AmpOutB,
					'AmpOut'
				);
		} else if (
			this.ampOutC.includes(dataUpdateTagObj.JBTStandardObservationId)
		) {
			this.assetTags.AmpOutC = parseFloat(dataUpdateTagObj.Value).toFixed(
				1
			);
			this.assetTags.AmpOutCColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.AmpOutC,
					'AmpOut'
				);
		} else if (
			this.frequencyOut.includes(
				dataUpdateTagObj.JBTStandardObservationId
			)
		) {
			this.assetTags.FrequencyOut = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
			this.assetTags.FrequencyOutColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.FrequencyOut,
					'FrequencyOut'
				);
		} else if (
			this.ampOutAvg.includes(dataUpdateTagObj.JBTStandardObservationId)
		)
			this.assetTags.AmpOutAvg = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
		else if (
			this.ampOutCon1.includes(dataUpdateTagObj.JBTStandardObservationId)
		)
			this.assetTags.AmpOutCon1 = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
		else if (
			this.ampOutCon2.includes(dataUpdateTagObj.JBTStandardObservationId)
		)
			this.assetTags.AmpOutCon2 = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
		else if (
			this.ampOutDC.includes(dataUpdateTagObj.JBTStandardObservationId)
		)
			this.assetTags.AmpOutDC = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
		else if (
			this.voltsOutDC.includes(dataUpdateTagObj.JBTStandardObservationId)
		)
			this.assetTags.VoltsOutDC = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
		else if (
			this.voltOutA.includes(dataUpdateTagObj.JBTStandardObservationId)
		) {
			this.assetTags.VoltOutA = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
			this.assetTags.VoltOutAColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltOutA,
					'VoltOut'
				);
		} else if (
			this.voltOutB.includes(dataUpdateTagObj.JBTStandardObservationId)
		) {
			this.assetTags.VoltOutB = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
			this.assetTags.VoltOutBColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltOutB,
					'VoltOut'
				);
		} else if (
			this.voltOutC.includes(dataUpdateTagObj.JBTStandardObservationId)
		) {
			this.assetTags.VoltOutC = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
			this.assetTags.VoltOutCColor =
				this.returnBackgroundColorBasedOffValue(
					this.assetTags.VoltOutC,
					'VoltOut'
				);
		} else if (
			this.voltOutAvg.includes(dataUpdateTagObj.JBTStandardObservationId)
		)
			this.assetTags.VoltOutAvg = parseFloat(
				dataUpdateTagObj.Value
			).toFixed(1);
	}

	openTagGraphSingle(observationIdArray): void {
		let tagObject = this.assetTags.find((t) =>
			observationIdArray.includes(parseInt(t.JBTStandardObservationId))
		);
		if (tagObject === undefined) {
			this.swalWithBootstrapButtons
				.fire({
					title: 'Error',
					text: "Tag Object Doesn't Exist",
					showCancelButton: false,
					confirmButtonText: 'Ok',
					reverseButtons: false,
				})
				.then(() => {});
		} else {
			if (this.widgetObject == undefined) {
				this.widgetObject = tagObject;
				this.widgetObject.TimeZoneId = null;
			}

			const tagGraphSingleModal = this.dialog.open(
				DialogModalParentComponent,
				{
					width: Global.isMobile ? '90%' : '60%',
					height: Global.isMobile ? '90%' : '80%',
					data: {
						TagId: tagObject.TagId,
						widgetNameDisplay: 'Tag Graph',
						WidgetName: 'tag-graph',
						isDisplayDataLive: true,
						timeZoneType:
							this.dashboardService.determineTimeZoneType(
								this.widgetObject
							),
					},

					maxWidth: '100vw',
					maxHeight: '100vh',
				}
			);
			this.tagGraphSingleModalSubscription = tagGraphSingleModal
				.afterClosed()
				.subscribe((result) => {
					Global.User.DebugMode &&
						console.log('The modal was closed');
					this.tagGraphSingleModalSubscription.unsubscribe();
				});
		}
	}

	getRuntimeData() {
		var UnitOnTag: ITag = this.dataService.cache.assetsObject[this.parentAsset.Id].Tags.firstOrDefault((tag:ITag)=> { return tag.JBTStandardObservationId == 12374 });
		if (UnitOnTag == null) {
			// Get Runtime Data
			let statement = `GSGetRuntimeForAssetsByAssetId @assetId = '${this.parentAsset.Id}'`;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				Global.User.DebugMode &&
					console.log(statement + ' data = %O', data);
				this.assetTags.RunTime =
					data != undefined
						? Math.abs(
								moment
									.utc()
									.diff(moment.utc(data[0].UnitOnDate), 'minutes')
						) + ' min'
						: '';
				this.assetTags.GPUOnDate =
					data != undefined ? data[0].UnitOnDate : '';
			});
		}
		else {
			if (UnitOnTag.Value == 1) {
				//console.log("current UTC date: " + moment(new Date()) + ", moment.utc(): " + moment.utc() + ", Unit On Tag JavascriptDate: " + UnitOnTag.JavascriptDate + ", moment.utc(UnitOn.JavascriptDate): " + moment.utc(UnitOnTag.SiteDateFull));
				// this.assetTags.RunTime = Math.abs(moment.utc().diff(moment.utc(UnitOnTag.JavascriptDate), 'minutes')) + ' min';
				this.assetTags.RunTime = Math.abs(UnitOnTag.DurationInMS() / 60000).toFixed(2) + ' min';
				this.assetTags.GPUOnDate = UnitOnTag.JavascriptDate;
			}
			else {
				this.assetTags.RunTime = "";
				this.assetTags.GPUOnDate = "";
			}
		}
	}

	updateRuntimes() {
		if (this.assetTags.UnitStatus) {
			var UnitOnTag: ITag = this.dataService.cache.assetsObject[this.parentAsset.Id].Tags.firstOrDefault((tag:ITag)=> { return tag.JBTStandardObservationId == 12374 });
			if (UnitOnTag != undefined) {
				this.assetTags.RunTime = Math.abs(UnitOnTag.DurationInMS() / 60000).toFixed(2) + ' min';
			}
		}
			// this.assetTags.RunTime =
			// 	Math.abs(
			// 		moment
			// 			.utc()
			// 			.diff(moment.utc(this.assetTags.GPUOnDate), 'minutes')
			// 	) + ' min';
	}

	getSignalRUpdates() {

		let assetObjectInCache =
			this.dataService.cache.assetsObject[this.parentAsset.Id];

		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}
	
		Global.User.DebugMode && console.log(this.componentName + ": widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: this.widgetObject?.WidgetName ?? "GPU Quick View", 
										 TagNamePrefix: [tagNamePrefixesString]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);
				
				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					var tagWeCareAbout = this.assetTags.firstOrDefault((assetTag:any) => { return assetTag.TagId == tag.Id });
					if (tagWeCareAbout != null) {
						tagWeCareAbout.Value = tag.Value;
						this.dataUpdate(tag);
					}
				});
			});
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}
		if (this.runtimeUpdateInterval) {
			clearInterval(this.runtimeUpdateInterval);
		}
		if (this.tagGraphSingleModalSubscription !== undefined) {
			this.tagGraphSingleModalSubscription.unsubscribe();
		}
	}
}
