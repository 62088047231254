import { Component, OnInit, OnDestroy, Input, ViewChild, EventEmitter, ChangeDetectorRef, ViewEncapsulation, NgZone, AfterViewInit, ElementRef } from "@angular/core";

import { GridSettings } from "../../_models/grid-settings.interface";
import { filter } from "rxjs/operators";
import * as moment from "moment";
import _ from "lodash";
import { SassHelperComponent } from "../../_helpers/sass-helper/sass-helper.component";

import { Subscription } from "rxjs";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { DashboardService } from "../../services/dashboard.service";
import { UtilityService } from "../../services/utility.service";
import { Global } from "../../_constants/global.variables";
import * as $ from 'jquery';
import { IAsset } from "../../_models/asset.model";
import { IGlobal } from '../../_models/global.model';
import { ITag } from "../../_models/tag.model";
import { IObservation } from "../../_models/observation.model";

import {Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { animate, style, transition, trigger } from "@angular/animations";

import { PerfectTurnPopupDialogComponent } from '../perfect-turn-popup-dialog/perfect-turn-popup-dialog.component';

declare var Highcharts: any;

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'lib-perfect-turn',
	templateUrl: './perfect-turn.component.html',
	styleUrls: ['./perfect-turn.component.scss'],
	animations: [
		trigger('slideInOut', [
		  transition(':enter', [
			style({transform: 'translateY(-100%)'}),
			animate('200ms ease-in', style({transform: 'translateY(0%)'}))
		  ]),
		  transition(':leave', [
			animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
		  ])
		])
	  ]
})
export class PerfectTurnComponent implements OnInit, OnDestroy {
	public global: IGlobal = Global;

	@Input() widgetObject: any;
	@Input() siteName: any;
	@Input() gateName: any;
	@Input() parentWidgetName: any;
	@Input() parentWidgetTimeZoneId: any;

	@Input() private widgetResizedEvent: EventEmitter<any>;
	@Input() private dashboardTimeScopeChanged: EventEmitter<any>;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;
	@ViewChild(SassHelperComponent)
	private sassHelper: SassHelperComponent;
	private queryStartDate: any;
	private queryEndDate: any;
	Highcharts: typeof Highcharts = Highcharts;
	signalRSubscription: Subscription;
	widgetResizedSubscription: Subscription;
	colorChangedSubscription: Subscription;
	dashboardTimeScopeChangedSubscription: Subscription;
	dashboardTimeZoneChangedSubscription: Subscription;
	isDataLoading: boolean;
	timeZoneType: any;
	hasData: boolean;
	siteUTCTimeOffset: number;
	aircraftDockedTimer: any = '';
	mainInterval: any;
	getFlightDataInterval: any;
	aircraftDockedInterval: any;
	stopWatchTimerInterval: any;
	hookupTimerInterval: any;
	disconnectTimerInterval: any;
	currentEvent: string;
	public timeToNextArrival: any;
	NextArrival: any;
	NextArrivalToGateScheduledUTCDate: any;
	timeToNextArrivalInterval: any;
	timeToNextFlightDataInterval: any;
	currentTimeZoneOffset: number;

	colors: any[] = [
		{ to: 120, color: 'LimeGreen' },
		{ from: 120, to: 240, color: 'DarkOrange' },
		{ from: 240, color: 'FireBrick' },
	];
	hookupFlightInfo = {
		AircraftType: '',
		AirlineCode: '',
		FlightNumber: '',
		TailNumber: '',
		Display: '',
	};
	hookupDuration = {
		Hours: 0,
		Minutes: 0,
		Seconds: 0,
		TotalSeconds: 0,
		Display: '',
	};
	activeTimers = {
		PBBMoveStart: false,
		PBBDocked: false,
		PCAOn: false,
		GPUOn: false,
		GPUSwitchedOver: false,
		GPUOff: false,
		PCAOff: false,
		PBBUndocked: false,
		PBBMoveAway: false,
	};
	chartRedrawn = {
		ThemeSwitch: { Current: false, Historic: false },
		Resize: { Current: false, Historic: false },
	};
	timerFontSize: any;
	detailTextFontSize: any;
	bodyTextColor: string;
	theme: string;
	hookupStatus: string;
	isHookupActive: boolean = false;
	HookupChartDefinition = { SeriesOneData: [], SeriesTwoData: [] };
	DisconnectChartDefinition = { SeriesData: [] };
	HookupChart: any;
	DisconnectChart: any;
	pastHookupEvents = [];
	statistics = {
		hookupSuccessPercent: '',
		hookupAvgTime: '',
		disconnectSuccessPercent: '',
		disconnectAvgTime: '',
	};
	pastDisconnectEvents = [];
	allPastEvents = [];
	HistoricChart: any;
	displayedHistoricChartIndex: number = 0;
	currentDateTime: any;
	hookupEventTimeline = [];
	hookupEventsMap = new Map();
	fullDataCacheSubscription: Subscription;
	signalRTagUpdateSubscription: Subscription;
	selectedMatTabIndex: number = 0;
	selectedMatTabLabel: string = 'Current';

	largeTimescale = 60;
	smallTimescale = 6;
	public dockRow: any;
	public siteAirUnitName: string;
	public state = { pbb: 'OFF', pca: 'OFF', gpu: 'OFF', turn: 'Disconnected' };
	public pbb = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
		overtime: 0,
		opportunity: 0,
		progressBackground:
			'`conic-gradient(#008fff 0%, #000fff 0%, #f2f2f4 0%)`;',
	};
	public pbbdock = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
		overtime: 0,
		opportunity: 0,
		progressBackground:
			'`conic-gradient(#008fff 0%, #000fff 0%, #f2f2f4 0%)`;',
	};
	public dock = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
		overtime: 0,
		opportunity: 0,
		progressBackground:
			'`conic-gradient(#008fff 0%, #000fff 0%, #f2f2f4 0%)`;',
	};
	public gpu = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
		overtime: 0,
		opportunity: 0,
		progressBackground: '`conic-gradient(#008fff 0%, #f2f2f4 0%)`;',
	};
	public pca = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
		overtime: 0,
		opportunity: 0,
		progressBackground: '`conic-gradient(#008fff 0%, #f2f2f4 0%)`;',
	};
	public gpuon = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
		overtime: 0,
		opportunity: 0,
		progressBackground: '`conic-gradient(#008fff 0%, #f2f2f4 0%)`;',
	};
	public gpuswitchover = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
		overtime: 0,
		opportunity: 0,
		progressBackground: '`conic-gradient(#008fff 0%, #f2f2f4 0%)`;',
	};
	public pcaon = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
		overtime: 0,
		opportunity: 0,
		progressBackground: '`conic-gradient(#008fff 0%, #f2f2f4 0%)`;',
	};
	public pbbaway = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
		overtime: 0,
		opportunity: 0,
		progressBackground:
			'`conic-gradient(#008fff 0%, #000fff 0%, #f2f2f4 0%)`;',
	};

	public gputotal = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
	};
	public pcatotal = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
	};
	public pbbtotal = {
		duration: {
			negative: '',
			totalMS: 0,
			hours: 0,
			minutes: '00',
			seconds: '00',
			absTotalMS: 0,
		},
	};

	public currentDock = {
		PBBMoveStartMS: null,
		PBBDockedMS: null,
		GPUOnMS: null,
		GPUSwitchoverMS: null,
		GPUOFFMS: null,
		PCAVentilatingAircraftMS: null,
		PCAOFFMS: null,
		PBBUndockedMS: null,
		PBBMoveAwayMS: null,
	};

	public currentTurn = {
		PBBMoveStart: null,
		PBBDocked: null,
		GPUOn: null,
		GPUSwitchover: null,
		GPUOFF: null,
		PCAVentilatingAircraft: null,
		PCAOFF: null,
		PBBUndocked: null,
		PBBMoveAway: null,
	};

	runtimeData = [];
	turnInProgress = false;

	public turn = {
		docked: null,
		isMoving: null,
		gpuUnitOn: null,
		AircraftOnGPUPower: null,
		GPUPowerOn: null,
		AmpsOutAverage: null,
		pcaUnitOn: null,
		BridgeMode: null,
		testdata: null,
	};

	public timers = {
		docked: null,
		isMoving: null,
		gpuUnitOn: null,
		AircraftOnGPUPower: null,
		GPUPowerOn: null,
		AmpsOutAverage: null,
		pcaUnitOn: null,
		BridgeMode: null,
		turn: null,
	};

	public gradients = {
		docked: null,
		gpuUnitOn: null,
		pcaUnitOn: null,
		turn: null,
	};

	public tags = {
		PBB: [],
		GPU: [],
		PCA: [],
	};

	myTagIdList = [];
	tagsObject = [];

	hookupEventsGridSettings: GridSettings = {
		gridData: this.hookupEventTimeline,
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: 'Name',
				title: 'Event',
				filterable: true,
				_width: 90,
				minResizableWidth: 150,
			},
			{
				field: 'DateLocal',
				title: 'Date',
				filter: 'date',
				filterable: true,
				_width: 75,
				minResizableWidth: 125,
			},
			{
				field: 'DateSite',
				title: 'Date',
				filter: 'date',
				filterable: true,
				_width: 75,
				minResizableWidth: 125,
			},
		],
	};

	pastEventsGridSettings: GridSettings = {
		gridData: this.pastHookupEvents,
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: 'EventType',
				title: 'Event',
				filterable: true,
				_width: 75,
				minResizableWidth: 75,
			},
			{
				field: 'FlightNumber',
				title: 'Flight',
				filterable: true,
				_width: 50,
				minResizableWidth: 50,
			},
			{
				field: 'StartDateLocal',
				title: 'Started',
				filter: 'date',
				filterable: true,
				_width: 125,
				minResizableWidth: 125,
			},
			{
				field: 'EndDateLocal',
				title: 'Ended',
				filter: 'date',
				filterable: true,
				_width: 125,
				minResizableWidth: 125,
			},
			{
				field: 'StartDateSite',
				title: 'Started',
				filter: 'date',
				filterable: true,
				_width: 125,
				minResizableWidth: 125,
			},
			{
				field: 'EndDateSite',
				title: 'Ended',
				filter: 'date',
				filterable: true,
				_width: 125,
				minResizableWidth: 125,
			},
			{
				field: 'TotalDuration',
				title: 'Duration',
				filterable: true,
				_width: 70,
				minResizableWidth: 100,
			},
			{
				field: 'TotalOvertime',
				title: 'Overtime',
				filterable: true,
				_width: 70,
				minResizableWidth: 100,
			},
		],
	};

	public PBBvisible;
	public GPUvisible;
	public PCAvisible;


	constructor(
		private dataService: DataService,
		private signalRCore: SignalRCoreService,
		public dashboardService: DashboardService,
		private ref: ChangeDetectorRef,
		private zone: NgZone,
		private utilityService: UtilityService,
		private dialog: MatDialog,
		private elem: ElementRef
	) {}

	SetCurrentTimeZoneOffset(TimeZoneId) {
		let dashboardTimeZone = Global.User.currentUser.DashboardTimeZones.find(
			(tz) => tz.Id === parseInt(TimeZoneId) // this.dashboardService.currentDashboard.TimeZoneId
		);

		//Global.User.DebugMode && console.log("dashboardTimeZone=", dashboardTimeZone);

		// change offset based on timezone selection
		if (dashboardTimeZone?.Description == 'Site Time') {
			this.currentTimeZoneOffset = this.siteUTCTimeOffset;
		} else if (dashboardTimeZone?.Description == 'User Time') {
			this.currentTimeZoneOffset =
				-this.utilityService.GetUserTimeZoneOffsetHours();
		} else {
			this.currentTimeZoneOffset = 0;
		}

		this.timeZoneType = dashboardTimeZone?.Description;
	}

	ngOnInit() {
		this.PBBvisible = false;
		this.GPUvisible = false;
		this.PCAvisible = false;

		this.isDataLoading = false;
		this.hasData = false;
		this.selectedMatTabLabel = this.dashboardService.currentDashboard.TimeScopeId ? 'Current': 'Statistics'

		if (!this.widgetObject) {

			let siteObject = this.dataService.cache.sites.find((site) => {
				return site.Name == this.siteName;
			});
			this.siteUTCTimeOffset = siteObject.UTCTimeOffset;

			let timeZoneId = this.parentWidgetTimeZoneId;
			if (this.parentWidgetTimeZoneId == '') {
				timeZoneId = this.dashboardService.currentDashboard.TimeZoneId;
			}

			this.SetCurrentTimeZoneOffset(timeZoneId);

			// minimal version
			this.initializeMinimal();

			// this.timeToNextFlightDataInterval = setInterval(() => {
			// 	this.initializeMinimal();
			// }, 1000); // this pulls from data cache so get it often

			if (this.dashboardTimeZoneChanged) {
				this.dashboardTimeZoneChangedSubscription =
					this.dashboardTimeZoneChanged.subscribe((data) => {
						if (data.length > 0) {
							let parentObject = data.find((object) => {
								return (
									object.WidgetName == this.parentWidgetName
								);
							});
							if (parentObject) {
								this.SetCurrentTimeZoneOffset(
									parentObject.TimeZoneId
								);
								this.GetBestTimeForNextArrival();
							}
						}
					});
			}
		} else if (
			this.widgetObject.WidgetGateSystemId &&
			this.widgetObject.WidgetSiteId !== 5
		) {
			this.isDataLoading = true;

			this.siteName = this.widgetObject.WidgetSiteName;
			this.gateName = this.widgetObject.WidgetGateSystemName;

			this.InitializeCurrentDock();

			// this.timeToNextFlightDataInterval = setInterval(() => {
			// 	this.initializeMinimal();
			// }, 1000); // this pulls from data cache so get it often

			if (!Global.FullDataCacheExists) {
				this.fullDataCacheSubscription =
					this.dataService.fullDataCacheExists$.subscribe(
						(data: any) => {
							if (data === true) {
								this.theme = Global.Theme;
								this.bodyTextColor =
									this.theme === 'dark'
										? 'white'
										: this.sassHelper.readProperty(
												'body-text-color-dark'
										  );
								this.initialize();
								this.fullDataCacheSubscription.unsubscribe();
							}
						}
					);
			} else {
				this.initialize();
			}

			// this.mainInterval = setInterval(() => {
			// 	// Update the values every minute
			// 	this.resetWidget();
			// 	this.initialize();
			// }, 1000 * 60);

			if (this.dashboardTimeScopeChanged) {
				this.dashboardTimeScopeChangedSubscription =
					this.dashboardTimeScopeChanged.subscribe((data) => {
						let foundWidgetWithSameWidgetId = data.find(
							(widgetThatWasChanged) => {
								return (
									widgetThatWasChanged.WidgetId ===
									this.widgetObject.WidgetId
								);
							}
						);

						if (!_.isNil(foundWidgetWithSameWidgetId)) {
							Global.User.DebugMode &&
								console.log('TimeScope changed');
							this.resetWidget();
							this.initialize();
						}
					});
			}

			let siteObject = this.dataService.cache.sites.find((site) => {
				return site.Name == this.siteName;
			});
			this.siteUTCTimeOffset = siteObject.UTCTimeOffset;

			let timeZoneId = this.parentWidgetTimeZoneId;
			if (
				this.parentWidgetTimeZoneId == undefined ||
				this.parentWidgetTimeZoneId == ''
			) {
				timeZoneId = this.dashboardService.currentDashboard.TimeZoneId;
			}

			this.SetCurrentTimeZoneOffset(timeZoneId);

			if (this.dashboardTimeZoneChanged) {
				this.dashboardTimeZoneChangedSubscription =
					this.dashboardTimeZoneChanged.subscribe((data) => {
						Global.User.DebugMode && console.log(data);
						let foundWidgetWithSameWidgetId = data.find(
							(widgetThatWasChanged) => {
								return (
									widgetThatWasChanged.WidgetId ===
									this.widgetObject.WidgetId
								);
							}
						);

						if (!_.isNil(foundWidgetWithSameWidgetId)) {
							Global.User.DebugMode &&
								console.log('Widget Time Zone Changed');
							this.timeZoneType =
								this.dashboardService.determineTimeZoneType(
									foundWidgetWithSameWidgetId
								);

							this.SetCurrentTimeZoneOffset(
								foundWidgetWithSameWidgetId.TimeZoneId
							);

							this.changeColumnsForTimeZone();
						}
					});
			}

			if (this.widgetResizedEvent) {
				this.widgetResizedSubscription =
					this.widgetResizedEvent.subscribe((data) => {
						if (
							this.widgetObject.WidgetId == data.item.WidgetId &&
							!this.isDataLoading
						) {
							this.timerFontSizeEval();
							if (this.selectedMatTabLabel == 'Current') {
								this.chartRedrawn.Resize.Current = true;
								this.chartRedrawn.Resize.Historic = false;
								this.HookupChart &&
									this.HookupChart.setSize(
										$(
											'#gsPerfectHookup-Hookup' +
												this.widgetObject.WidgetId
										).width(),
										$(
											'#gsPerfectHookup-Hookup' +
												this.widgetObject.WidgetId
										).height(),
										false
									);
								this.DisconnectChart &&
									this.DisconnectChart.setSize(
										$(
											'#gsPerfectHookup-Hookup' +
												this.widgetObject.WidgetId
										).width(),
										$(
											'#gsPerfectHookup-Hookup' +
												this.widgetObject.WidgetId
										).height(),
										false
									);
							} else if (
								this.selectedMatTabLabel == 'Statistics' &&
								this.HistoricChart
							) {
								this.chartRedrawn.Resize.Historic = true;
								this.chartRedrawn.Resize.Current = false;
								this.HistoricChart.setSize(
									$(
										'#gsPerfectHookupHistoric' +
											this.widgetObject.WidgetId
									).width(),
									$(
										'#gsPerfectHookupHistoric' +
											this.widgetObject.WidgetId
									).height(),
									false
								);
							}
						}
					});
			}

			this.colorChangedSubscription =
				this.dataService.colorChanged$.subscribe((theme: any) => {
					if (this.theme !== theme) {
						this.theme = theme;
						this.bodyTextColor =
							this.theme === 'dark'
								? 'white'
								: this.sassHelper.readProperty(
										'body-text-color-dark'
								  );
						if (this.selectedMatTabLabel == 'Current') {
							this.chartRedrawn.ThemeSwitch.Current = true;
							this.chartRedrawn.ThemeSwitch.Historic = false;
							this.HookupChart && this.createHookupChart();
							this.DisconnectChart &&
								this.createDisconnectChart();
						} else if (
							this.selectedMatTabLabel == 'Statistics' &&
							this.HistoricChart
						) {
							this.chartRedrawn.ThemeSwitch.Historic = true;
							this.chartRedrawn.ThemeSwitch.Current = false;
							this.createHistoricChart();
						}
					}
				});


			this.GetTagsFromSQLServer();

			this.GetCurrentFlightData();

			this.getFlightDataInterval = setInterval(() => {
				this.GetCurrentFlightData();
			}, 5 * 60 * 1000);

			//console.log("currentTimeZoneOffset: " + this.currentTimeZoneOffset);
		}
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		if (this.widgetObject) {
			this.widgetObject.WidgetGateSystemId &&
				this.signalRCore.leaveAdditionalGroup(
					`PerfectTurn_${this.widgetObject.WidgetGateSystemId}`
				);

			if (this.signalRTagUpdateSubscription) {
				this.signalRTagUpdateSubscription.unsubscribe();
			}
			if (this.colorChangedSubscription) {
				this.colorChangedSubscription.unsubscribe();
			}
			this.aircraftDockedInterval &&
				clearInterval(this.aircraftDockedInterval);
			this.hookupTimerInterval && clearInterval(this.hookupTimerInterval);
			this.disconnectTimerInterval &&
				clearInterval(this.disconnectTimerInterval);

			this.mainInterval && clearInterval(this.mainInterval);
			this.getFlightDataInterval && clearInterval(this.getFlightDataInterval);

		}
	}

	// SetCurrentTurnValues(turn: any) {
	// 	// init values
	// 	this.currentTurn.PBBMoveAway = turn.PBBMoveAwayMS ?
	// 			this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
	// 				turn.PBBMoveAwayMS,
	// 				this.currentTimeZoneOffset
	// 			) : undefined;
	// 	this.currentTurn.PBBDocked = turn.PBBDockedMS ?
	// 			this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
	// 				turn.PBBDockedMS,
	// 				this.currentTimeZoneOffset
	// 			) : undefined;
	// 	this.currentTurn.PBBUndocked = turn.PBBUndockedMS ?
	// 			this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
	// 				turn.PBBUndockedMS,
	// 				this.currentTimeZoneOffset
	// 			) : undefined;
	// 	this.currentTurn.GPUOn = turn.GPUOnMS ?
	// 			this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
	// 				turn.GPUOnMS,
	// 				this.currentTimeZoneOffset
	// 			) : undefined;
	// 	this.currentTurn.GPUSwitchover = turn.GPUSwitchoverMS ?
	// 			this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
	// 				turn.GPUSwitchoverMS,
	// 				this.currentTimeZoneOffset
	// 			) : undefined;
	// 	this.currentTurn.GPUOFF = turn.GPUOFFMS ?
	// 			this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
	// 				turn.GPUOFFMS,
	// 				this.currentTimeZoneOffset
	// 			) : undefined;
	// 	this.currentTurn.PCAVentilatingAircraft = turn.PCAVentilatingAircraftMS ?
	// 			this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
	// 				turn.PCAVentilatingAircraftMS,
	// 				this.currentTimeZoneOffset
	// 			) : undefined;
	// 	this.currentTurn.PCAOFF = turn.PCAOFFMS ?
	// 			this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
	// 				turn.PCAOFFMS,
	// 				this.currentTimeZoneOffset
	// 			) : undefined;

	// 	// init timers
	// 	// this.timers.docked = 0.0;
	// 	// this.timers.gpuUnitOn = 0.0;
	// 	// this.timers.pcaUnitOn = 0.0;
	// 	// this.timers.AircraftOnGPUPower = 0.0;
	// 	// this.timers.turn = 0.0;

	// 	// init the progress circles
	// 	// this.dock.progressBackground = `conic-gradient(#008fff 0%, #f2f2f4 0%)`;
	// 	// this.pbb.progressBackground = `conic-gradient(#008fff 0%, #f2f2f4 0%)`;
	// 	// this.gpu.progressBackground = `conic-gradient(#008fff 0%, #f2f2f4 0%)`;
	// 	// this.pca.progressBackground = `conic-gradient(#008fff 0%, #f2f2f4 0%)`;
	// }

	GetStatisticsFromSQLServer() {
		let retrievalDates =
			this.dashboardService.determineProperTimeScopeForWidget({
				widgetObject: this.widgetObject,
				UTCConfiguration: false,
			});

		let startDateSiteTime = moment(
			new Date(retrievalDates.queryStartDate)
		).subtract(this.siteUTCTimeOffset, 'h');

		let formattedStartDate = moment(startDateSiteTime).format(
			'YYYY/MM/DD HH:mm:ss'
		);
		let formattedEndDate = moment(new Date(retrievalDates.queryEndDate))
			.endOf('day')
			.format('YYYY/MM/DD HH:mm:ss');

		let statement = `API.GetPerfectTurnRecordsByGateSystemIdWithinTimescope @StartTime = '${formattedStartDate}', @EndTime = '${formattedEndDate}', @GateSystemId = ${this.widgetObject.WidgetGateSystemId}, @SiteId = ${this.widgetObject.WidgetSiteId}`;

		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			//console.log(data);

			if (data.length > 0) {
				data = _.orderBy(data, ['Id'], ['desc']);

				//this.initializeTurn();
				//this.SetCurrentTurnValues(data[0]);

				data.forEach((row, index) => {
					if (index != 0) {
						this.addHookupToHistory(row);
						this.addDisconnectToHistory(row);
					}
				});
			}
		});
	}


	GetTagsFromSQLServer() {
		var service = this;
		var selectedGateSystem =
			this.dataService.cache.systemsObject[
				this.widgetObject.WidgetGateSystemId
			];
		var assetIds = selectedGateSystem.Assets.select((asset: IAsset) => {
			return asset.Id;
		})
			.toArray()
			.join(',');
		var standardObservationIds =
			this.dataService.perfectTurnStandardObservationIds.PBB +
			',' +
			this.dataService.perfectTurnStandardObservationIds.PCA +
			',' +
			this.dataService.perfectTurnStandardObservationIds.GPU;
		let sqlStatement =
			"API.PerfectTurn_TagsWithHistorical @assetIds='" +
			assetIds +
			"', @jbtStandardObservationIds='" +
			standardObservationIds +
			"'";

		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			var newData =
				service.dataService.GetBrokenOutFieldsFromStringTagData(data);
			let newFormattedCacheTagObjects: Array<ITag> = [];

			var currentTagId = null;
			if (newData.length > 0) {
				newData.map((t) => {
					var tag: any = t;
					var formattedCacheTagObject =
						service.dataService.GetStandardCacheTagObjectFromDatabaseFields(
							tag
						);
					//Global.User.DebugMode && console.log(formattedCacheTagObject);
					if (
						formattedCacheTagObject &&
						!formattedCacheTagObject.TagName
					) {
						console.error(
							'Tag was not formatted correctly. Tag record = %O',
							formattedCacheTagObject
						);
					}

					var loadedTag =
						service.dataService.LoadSignalRObservationToInventory(
							formattedCacheTagObject,
							true
						);

					newFormattedCacheTagObjects.push(formattedCacheTagObject);

					// var observation =
					// 	formattedCacheTagObject.Historical?.firstOrDefault(
					// 		(observation: IObservation) => {
					// 			return (
					// 				observation.DateInMilliseconds ==
					// 					formattedCacheTagObject.PreviousObservationDateInMilliseconds &&
					// 				observation.Value ==
					// 					formattedCacheTagObject.PreviousObservationTextValue?.toString()
					// 			);
					// 		}
					// 	);
					// if (!observation) {
					// 	let observation: IObservation = {
					// 		TagId: tag.Id,
					// 		Value: tag.PreviousObservationTextValue,
					// 		DateInMilliseconds:
					// 			tag.PreviousObservationDateInMilliseconds,
					// 	};
					// 	if (formattedCacheTagObject.Historical == undefined) {
					// 		formattedCacheTagObject.Historical = [];
					// 	}
					// 	formattedCacheTagObject.Historical.push(observation);
					// }
					if (currentTagId != tag.Id) {
						//formattedCacheTagObject?.Id && console.log("perfect turn tag Id: " + formattedCacheTagObject.Id + " = %O", service.dataService.cache.tagsObject[formattedCacheTagObject.Id]);
						currentTagId = tag.Id;
					}

					this.dataService.updatedTagsList.push(
						formattedCacheTagObject
					);

					if(formattedCacheTagObject.Asset.Name == "PBB") {
						this.tags.PBB[formattedCacheTagObject.JBTStandardObservationId] = service.dataService.cache.tagsObject[formattedCacheTagObject.Id];
					}
					else if(formattedCacheTagObject.Asset.Name == "GPU") {
						this.tags.GPU[formattedCacheTagObject.JBTStandardObservationId] = service.dataService.cache.tagsObject[formattedCacheTagObject.Id];
					}
					else if(formattedCacheTagObject.Asset.Name == "AHU" || formattedCacheTagObject.Asset.Name == "PCA") {
						this.tags.PCA[formattedCacheTagObject.JBTStandardObservationId] = service.dataService.cache.tagsObject[formattedCacheTagObject.Id];
					}

					this.myTagIdList.push(formattedCacheTagObject.Id);


				});

				assetIds.split(',').forEach(function (assetId) {
					var asset: any =
						service.dataService.cache.assets.firstOrDefault(
							(a: any) => {
								return a.Id == +assetId;
							}
						);

					//Flag the asset as having all of its tags now loaded if it was not just the alarms loaded.
					if (asset) {
						asset.AllTagsLoaded = true;
					}
				});

				let sqlStatement =
						"API.GetNewValueHistoricalObservationsForTags @tagIds='" +
						this.myTagIdList.join(",") +
						"'";

				//console.log("sqlStatement=", sqlStatement);

				this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
					data.forEach(tag => {

						let observation: any = {
							TagId: tag.TagId,
							Value: tag.TextValue != null ? tag.TextValue?.toString() : null,
							Date: tag.Date,
							DateInMilliseconds: +tag.DateMS
						};

						service.dataService.cache.tagsObject[tag.TagId].Historical.push(observation);

						// check if this observation is later than the current
						if(+tag.DateMS > service.dataService.cache.tagsObject[tag.TagId].DateInMilliseconds) {
							let difference = +tag.DateMS - service.dataService.cache.tagsObject[tag.TagId].DateInMilliseconds;
							console.log("history is later than current observation=%O time difference=", service.dataService.cache.tagsObject[tag.TagId], difference);
							if(observation.Value != service.dataService.cache.tagsObject[tag.TagId].Value) {
								console.log("history is later with different values current=%O historical=", service.dataService.cache.tagsObject[tag.TagId].Value, observation.Value);
							}
						}

					});


					this.UpdateDisplay();
					this.UpdateTurnTimers();
					this.GetCurrentFlightData();

					this.isDataLoading = false;
					this.hasData = true;

					this.mainInterval = setInterval(() => {
						this.UpdateDisplay();
						this.UpdateTurnTimers();
					}, 500);
				});
			}



		});
	}



	generateHeight(name, ratio) {
		let returnedHeight = $(name).height();
		let fontSizeCalculationAsNumber = returnedHeight / ratio;
		return fontSizeCalculationAsNumber.toString() + 'px';
	}

	generateWidth(name, ratio) {
		let returnedWidth = $(name).width();
		let fontSizeCalculationAsNumber = returnedWidth / ratio;
		return fontSizeCalculationAsNumber.toString() + 'px';
	}

	timerFontSizeEval() {
		this.timerFontSize = this.generateHeight(
			'.hookup-statistics-container',
			8
		);
		return this.timerFontSize;
	}

	changeColumnsForTimeZone() {
		// HookupTimeline Grid
		const localDateCol = this.hookupEventsGridSettings.columnsConfig.find(
			(col) => col.field == 'DateLocal'
		);
		const siteDateCol = this.hookupEventsGridSettings.columnsConfig.find(
			(col) => col.field == 'DateSite'
		);

		localDateCol.hidden = this.timeZoneType !== 'User Time';
		siteDateCol.hidden =
			this.timeZoneType !== 'Site Time' &&
			this.timeZoneType !== 'UTC Time';
		localDateCol.includeInChooser = !localDateCol.hidden;
		siteDateCol.includeInChooser = !siteDateCol.hidden;

		//pastEvents Grid
		const localStartDateCol =
			this.pastEventsGridSettings.columnsConfig.find(
				(col) => col.field == 'StartDateLocal'
			);
		const localEndDateCol = this.pastEventsGridSettings.columnsConfig.find(
			(col) => col.field == 'EndDateLocal'
		);
		const siteStartDateCol = this.pastEventsGridSettings.columnsConfig.find(
			(col) => col.field == 'StartDateSite'
		);
		const siteEndDateCol = this.pastEventsGridSettings.columnsConfig.find(
			(col) => col.field == 'EndDateSite'
		);

		localStartDateCol.hidden = this.timeZoneType !== 'User Time';
		localEndDateCol.hidden = this.timeZoneType !== 'User Time';
		siteStartDateCol.hidden =
			this.timeZoneType !== 'Site Time' &&
			this.timeZoneType !== 'UTC Time';
		siteEndDateCol.hidden =
			this.timeZoneType !== 'Site Time' &&
			this.timeZoneType !== 'UTC Time';

		localStartDateCol.includeInChooser = this.timeZoneType === 'User Time';
		localEndDateCol.includeInChooser = this.timeZoneType === 'User Time';
		siteStartDateCol.includeInChooser =
			this.timeZoneType == 'Site Time' ||
			this.timeZoneType !== 'UTC Time';
		siteEndDateCol.includeInChooser =
			this.timeZoneType == 'Site Time' ||
			this.timeZoneType !== 'UTC Time';

		if (this.HookupChart?.series) {
			this.HookupChart.series.forEach((series) => {
				series.data.forEach((dataItem) => {
					dataItem.timeZoneType = this.timeZoneType;
				});
			});
		}

		if (this.DisconnectChart?.series) {
			this.DisconnectChart.series.forEach((series) => {
				series.data.forEach((dataItem) => {
					dataItem.timeZoneType = this.timeZoneType;
				});
			});
		}

		if (this.HistoricChart?.series) {
			this.HistoricChart.series.forEach((series) => {
				series.data.forEach((dataItem) => {
					dataItem.timeZoneType = this.timeZoneType;
				});
			});
		}

		if (this.allPastEvents.length > 1) {
			this.allPastEvents.forEach((pastEvent) => {
				if (pastEvent.EventType == 'Hookup') {
					pastEvent.Chart.SeriesOneData.forEach((series) => {
						series.timeZoneType = this.timeZoneType;
					});
					pastEvent.Chart.SeriesTwoData.forEach((series) => {
						series.timeZoneType = this.timeZoneType;
					});
				} else {
					pastEvent.Chart.SeriesData.forEach((series) => {
						series.timeZoneType = this.timeZoneType;
					});
				}
			});
		}
	}

	GetBestTimeForNextArrival() {
		// Get the best time for the next arrival
		if (this.NextArrival.ArrivalToGateActualUTCDate) {
			this.timeToNextArrival = moment
				.utc(this.NextArrival.ArrivalToGateActualUTCDate)
				.utcOffset(this.currentTimeZoneOffset);
		} else if (this.NextArrival.ArrivalToGateEstimatedUTCDate) {
			this.timeToNextArrival = moment
				.utc(this.NextArrival.ArrivalToGateEstimatedUTCDate)
				.utcOffset(this.currentTimeZoneOffset);
		} else if (this.NextArrival.ArrivalToGateScheduledUTCDate) {
			this.timeToNextArrival = moment
				.utc(this.NextArrival.ArrivalToGateScheduledUTCDate)
				.utcOffset(this.currentTimeZoneOffset);
		} else {
			// we have a problem
			Global.User.currentUser.Username == 'kendemerchant' &&
				console.log(
					'No arrival time for next arrival:',
					this.NextArrival
				);
		}
	}

	initializeMinimal() {

		this.timeZoneType = this.dashboardService.determineTimeZoneType(
			this.widgetObject
		);

		let formattedStartDate = moment().utc().format('YYYY/MM/DD HH:mm:ss');
		let formattedEndDate = moment()
			.utc()
			.add(1, 'days')
			.format('YYYY/MM/DD HH:mm:ss');

		// check cache for next arrival
		var gate = this.dataService.cache.systems.find((gate) => {
			return gate.Type =="Gate" && gate.Name == this.gateName && gate.ParentSystem?.Site.Name == this.siteName ;
		});

		if(gate) {
			if(this.timeZoneType == 'Site Time') {
				this.timeToNextArrival = gate.NextArrival.Site;
			}
			else if(this.timeZoneType == 'User Time') {
				this.timeToNextArrival = gate.NextArrival.Local;
			}
			else if(this.timeZoneType == 'UTC Time') {
				this.timeToNextArrival = gate.NextArrival.UTC;
			}
		}


		// let statement = `API.GetArrivalFlightDataForSiteWithinTimscope @StartTime = '${formattedStartDate}', @EndTime = '${formattedEndDate}', @ArrivalSiteName = '${this.siteName}', @ArrivalGateName = '${this.gateName}'`;

		// this.dataService.SQLActionAsPromise(statement).then((data: any) => {
		// 	if (data.length > 0) {
		// 		// the first should be the next arrival
		// 		this.NextArrival = data[0];

		// 		this.GetBestTimeForNextArrival();

		// 		// this.hasData = true;
		// 		// this.isDataLoading = false;
		// 	} else {
		// 		this.hasData = false;
		// 		this.isDataLoading = false;
		// 	}
		// });
	}

	initializeTurn() {
		// init values
		this.currentTurn.PBBMoveAway = null;
		this.currentTurn.PBBDocked = null;
		this.currentTurn.PBBUndocked = null;
		this.currentTurn.GPUOn = null;
		this.currentTurn.GPUSwitchover = null;
		this.currentTurn.GPUOFF = null;
		this.currentTurn.PCAVentilatingAircraft = null;
		this.currentTurn.PCAOFF = null;

		// init timers
		this.timers.docked = 0.0;
		this.timers.gpuUnitOn = 0.0;
		this.timers.pcaUnitOn = 0.0;
		this.timers.AircraftOnGPUPower = 0.0;
		this.timers.turn = 0.0;

		// init the progress circles
		this.dock.progressBackground = `conic-gradient(#008fff 0%, #f2f2f4 0%)`;
		this.pbb.progressBackground = `conic-gradient(#008fff 0%, #f2f2f4 0%)`;
		this.gpu.progressBackground = `conic-gradient(#008fff 0%, #f2f2f4 0%)`;
		this.pca.progressBackground = `conic-gradient(#008fff 0%, #f2f2f4 0%)`;
	}

	GetCurrentFlightData() {
		if(this.currentTurn.PBBDocked) {
			let formattedStartDate = moment(this.currentTurn.PBBDocked)
				.utc()
				.add(-1, 'hours')
				.format('YYYY/MM/DD HH:mm:ss');
			let formattedEndDate = moment(this.currentTurn.PBBDocked)
				.utc()
				.add(1, 'hours')
				.format('YYYY/MM/DD HH:mm:ss');
			let statement = `API.GetArrivalFlightDataForSiteWithinTimscope @StartTime = '${formattedStartDate}', @EndTime = '${formattedEndDate}', @ArrivalSiteName = '${this.siteName}', @ArrivalGateName = '${this.gateName}'`;

			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				if (data.length > 0) {
					// set the flight info for display
					this.hookupFlightInfo.AircraftType = data[0].AircraftType;
					this.hookupFlightInfo.AirlineCode = data[0].AirlineCode;
					this.hookupFlightInfo.FlightNumber = data[0].FlightNumber;
					this.hookupFlightInfo.TailNumber = data[0].TailNumber;
				}
			});
		}
	}

	// LogValues() {
	// 	console.log("start perfect turn PBB[12245] history tag values");

	// 	this.tags.PBB[12245].Historical.forEach((item) => {
	// 		console.log("perfect turn PBB[12245] history tag value: " + item.Value + " = %O",  this.utilityService.convertMillisecondsToDateWithTimezoneOffset(item.DateInMilliseconds, this.currentTimeZoneOffset));
	// 	});
	// 	console.log("end perfect turn PBB[12245] history tag values");
	// }

	// FirstMatchingValueSkipUnMatching - this should be a better version of FirstMatchingValue
	// 		takes into account the list may start with unmatches
	FirstMatchingValueSkipUnMatching(valueToMatch, list): any {
		if (list.length == 0) {
			return undefined;
		}

		var history = list.orderByDescending((h:any) => { return h.DateInMilliseconds })
						  .toArray();

		var foundMatchingValue = false; //
		var matchedItem = undefined;

		for (var item of history) {
			//console.log("perfect turn history tag value: " + item.Value + " = %O",  this.utilityService.convertMillisecondsToDateWithTimezoneOffset(item.DateInMilliseconds, this.currentTimeZoneOffset));

			if(item.Value == valueToMatch) {
				foundMatchingValue = true;
				matchedItem = item;
			}
			else if (item.Value != valueToMatch && foundMatchingValue == true) {
				//console.log("return matched item: " + matchedItem.Value + " = %O",  this.utilityService.convertMillisecondsToDateWithTimezoneOffset(matchedItem.DateInMilliseconds, this.currentTimeZoneOffset));

				break;
			}
			else {
				matchedItem = item;
			}
		}
		//console.log("return matched item: " + matchedItem.Value + " = %O",  this.utilityService.convertMillisecondsToDateWithTimezoneOffset(matchedItem.DateInMilliseconds, this.currentTimeZoneOffset));
		return matchedItem;
	}

	// FirstMatchingValue - finds the first item from a list to be equal to valueToMatch
	//		the list needs to start with matches
	FirstMatchingValue(valueToMatch, list): any {
		if (list.length == 0) {
			return undefined;
		}

		// list.sort(function (a, b) {
		// 	return b.DateInMilliseconds - a.DateInMilliseconds;
		// });

		var history = list.orderByDescending((h:any) => { return h.DateInMilliseconds })
						  .toArray();

		var matchedItem = undefined;

		for (var item of history) {
			//console.log("perfect turn history tag value: " + item.Value + " = %O",  this.utilityService.convertMillisecondsToDateWithTimezoneOffset(item.DateInMilliseconds, this.currentTimeZoneOffset));

			if (item.Value != valueToMatch) {
				//console.log("return matched item: " + matchedItem.Value + " = %O",  this.utilityService.convertMillisecondsToDateWithTimezoneOffset(matchedItem.DateInMilliseconds, this.currentTimeZoneOffset));

				break;
			}
			else {
				matchedItem = item;
			}
		}
		//console.log("return matched item: " + matchedItem.Value + " = %O",  this.utilityService.convertMillisecondsToDateWithTimezoneOffset(matchedItem.DateInMilliseconds, this.currentTimeZoneOffset));
		return matchedItem;
	}

	FirstMatchingOverValue(valueToMatch, list): any {
		if (list.length == 0) {
			return undefined;
		}

		var history = list.orderByDescending((h:any) => { return h.DateInMilliseconds })
						  .toArray();

		var matchedItem = undefined;
		for (var item of list) {
			//console.log("perfect turn history tag value: " + item.Value + " = %O",  this.utilityService.convertMillisecondsToDateWithTimezoneOffset(item.DateInMilliseconds, this.currentTimeZoneOffset));

			if (item.Value < valueToMatch) {
				//console.log("return matched item: " + matchedItem.Value + " = %O",  this.utilityService.convertMillisecondsToDateWithTimezoneOffset(matchedItem.DateInMilliseconds, this.currentTimeZoneOffset));
				break;
			}
			matchedItem = item;
		}
		//console.log("return matched item: " + matchedItem.Value + " = %O",  this.utilityService.convertMillisecondsToDateWithTimezoneOffset(matchedItem.DateInMilliseconds, this.currentTimeZoneOffset));
		return matchedItem;
	}

	GetValueAtTimeFromDataCache(cacheItem: any, timeToMatch: any): any {

		var historicalOrdered = cacheItem.Historical.orderByDescending((h:any) => { return h.DateInMilliseconds }).toArray();

		var item = historicalOrdered
						.where((h:any) => h.DateInMilliseconds < timeToMatch)
						.firstOrDefault();

		return item;
	}

	GetFirstTimeFromDataCache(cacheItem: any, valueToMatch: any, currentDockWindowStartMS: any): any {

		var historicalOrdered = cacheItem.Historical.orderBy((h:any) => { return h.DateInMilliseconds }).toArray();

		var firstItem = historicalOrdered
						.where((h:any) => h.Value == valueToMatch && h.DateInMilliseconds > currentDockWindowStartMS)
						.firstOrDefault();

		return firstItem ? firstItem : cacheItem;
	}

	GetFirstTimeFromDataCacheOrNull(cacheItem: any, valueToMatch: any, currentDockWindowStartMS: any): any {

		var historicalOrdered = cacheItem.Historical.orderBy((h:any) => { return h.DateInMilliseconds }).toArray();

		var firstItem = historicalOrdered
						.where((h:any) => h.Value == valueToMatch && h.DateInMilliseconds > currentDockWindowStartMS)
						.firstOrDefault();

		return firstItem;
	}


	UpdateDisplay() {
		// Determine if we need to start a new Turn
		if (
			this.turnInProgress == false &&
			this.tags.PBB[12245].Value == '1' &&
			this.tags.PBB[12245].PreviousObservationTextValue == '0'
		) {
			// Start new turn
			this.turnInProgress = true;
			this.initializeTurn();
		}

		// PBB

		var currentDockWindowStartMS;

		// Aircraft Docked
		if (this.tags.PBB[12245].Value == '1') {

			var firstItem = this.FirstMatchingValue(
				'1',
				this.tags.PBB[12245].Historical
			);
			if (firstItem) {
				this.turn.docked = firstItem;
				this.currentTurn.PBBDocked =
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						firstItem.DateInMilliseconds,
						this.currentTimeZoneOffset
					);
			} else {
				this.turn.docked = this.tags.PBB[12245];
				this.currentTurn.PBBDocked =
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						this.tags.PBB[12245].DateInMilliseconds,
						this.currentTimeZoneOffset
					);
			}
			this.currentTurn.PBBUndocked = undefined;

			currentDockWindowStartMS = this.turn.docked.DateInMilliseconds - 0 * 60 * 1000; // 0 minutes before aircraft docked

		} else if (this.tags.PBB[12245].Value == '0') {
			var firstItem = this.FirstMatchingValue(
				'0',
				this.tags.PBB[12245].Historical
			);
			if (firstItem) {
				this.turn.docked = firstItem;
				this.currentTurn.PBBUndocked =
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						firstItem.DateInMilliseconds,
						this.currentTimeZoneOffset
					);
			} else {
				this.turn.docked = this.tags.PBB[12245];
				this.currentTurn.PBBUndocked =
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						this.tags.PBB[12245].DateInMilliseconds,
						this.currentTimeZoneOffset
					);
			}

			// find the docked time
			var orderedDocks = this.tags.PBB[12245].Historical.orderByDescending((h:any) => { return h.DateInMilliseconds }).toArray();

			var firstDocked = orderedDocks.firstOrDefault((h:any) => { return h.Value == '1' });
			var firstDockedTrueItem;
			if(firstDocked != null) {
				var dockedListToMatch = this.tags.PBB[12245].Historical.where((h:any) => { return h.DateInMilliseconds <= firstDocked.DateInMilliseconds }).toArray();


				var firstDockedTrueItem = this.FirstMatchingValue(
					'1',
					dockedListToMatch
				);

				if(firstDockedTrueItem != undefined) {
					this.currentTurn.PBBDocked =
						this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
							firstDockedTrueItem.DateInMilliseconds,
							this.currentTimeZoneOffset
						);
					currentDockWindowStartMS = firstDockedTrueItem.DateInMilliseconds - 0 * 60 * 1000; // 0 minutes before aircraft docked
				}
				else {
					currentDockWindowStartMS = firstDocked.DateInMilliseconds - 0 * 60 * 1000; // 0 minutes before aircraft docked
				}
			}

			// turn ends when undocked
			this.turnInProgress = false; // TODO: turn ends when moveaway stops
		}

		if(this.currentTurn.PBBDocked > this.currentTurn.PBBUndocked) {
			console.log("problem with currentTurn=", this.currentTurn);
		}

		// PBB Moving
		if(this.currentTurn.PBBDocked && this.tags.PBB[54271]) {
			// find when PBB started to move before docking
			this.turn.isMoving = this.GetFirstTimeFromDataCache(this.tags.PBB[54271], '1', currentDockWindowStartMS - 5 * 60 * 1000);
			if(this.turn.isMoving.DateInMilliseconds < currentDockWindowStartMS) {
				this.currentTurn.PBBMoveStart = this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
					this.turn.isMoving.DateInMilliseconds,
					this.currentTimeZoneOffset
				);
			}
		}

		if(this.currentTurn.PBBUndocked && this.tags.PBB[54271]) {
			// find when PBB started to move after undocking
			this.turn.isMoving = this.GetFirstTimeFromDataCache(this.tags.PBB[54271], '1', currentDockWindowStartMS);
			this.currentTurn.PBBMoveAway = this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
												this.turn.isMoving.DateInMilliseconds,
												this.currentTimeZoneOffset
											);
		}

		if(this.tags.PBB[54271]?.Value=='1' && this.currentTurn.PBBUndocked == undefined) {
			var firstItem = this.FirstMatchingValue(
				'1',
				this.tags.PBB[54271].Historical
			);
			if (firstItem) {
				this.turn.isMoving = firstItem;
				this.currentTurn.PBBMoveStart =
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						firstItem.DateInMilliseconds,
						this.currentTimeZoneOffset
					);
			} else {
				this.turn.isMoving = this.tags.PBB[54271];
				this.currentTurn.PBBMoveStart =
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						this.tags.PBB[54271].DateInMilliseconds,
						this.currentTimeZoneOffset
					);
			}
		}
		else if(this.tags.PBB[54271]?.Value=='1' && this.currentTurn.PBBUndocked != undefined) {
			var firstItem = this.FirstMatchingValue(
				'1',
				this.tags.PBB[54271].Historical
			);
			if (firstItem) {
				this.turn.isMoving = firstItem;
				this.currentTurn.PBBMoveAway =
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						firstItem.DateInMilliseconds,
						this.currentTimeZoneOffset
					);
			} else {
				this.turn.isMoving = this.tags.PBB[54271];
				this.currentTurn.PBBMoveAway =
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						this.tags.PBB[54271].DateInMilliseconds,
						this.currentTimeZoneOffset
					);
			}
		}


		//GPU


		// UNIT ON 12374
		if (this.tags.GPU[12374].Value == '1') {
			 this.turn.gpuUnitOn = this.GetFirstTimeFromDataCache(this.tags.GPU[12374], '1', currentDockWindowStartMS);
			 this.currentTurn.GPUOn = this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
				this.turn.gpuUnitOn.DateInMilliseconds,
				this.currentTimeZoneOffset
			);

		} else if (this.tags.GPU[12374].Value == '0') {
			this.turn.gpuUnitOn = this.GetFirstTimeFromDataCache(this.tags.GPU[12374], '0', currentDockWindowStartMS);
			this.currentTurn.GPUOFF = this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
				this.turn.gpuUnitOn.DateInMilliseconds,
				this.currentTimeZoneOffset
			);

			// find the gpu ON time
			var gpuHistoricalOrdered = this.tags.GPU[12374].Historical.orderByDescending((h:any) => { return h.DateInMilliseconds }).toArray();
			var gpuHistoricalAfterDockTime = gpuHistoricalOrdered
												.where((h:any) => h.Value == '1' && h.DateInMilliseconds > currentDockWindowStartMS)
												.toArray();
			if (gpuHistoricalAfterDockTime.length > 0) {

				var firstTrueItem = this.FirstMatchingValue(
					'1',
					gpuHistoricalAfterDockTime
				);
				this.currentTurn.GPUOn =
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						firstTrueItem.DateInMilliseconds,
						this.currentTimeZoneOffset
					);
			}
		}

		// Switchover 15166 Aircraft on GPU
		if(this.tags.GPU[15166]) {
			this.turn.AircraftOnGPUPower = this.GetFirstTimeFromDataCache(this.tags.GPU[15166], '1', currentDockWindowStartMS);
			this.currentTurn.GPUSwitchover = this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
				this.turn.AircraftOnGPUPower.DateInMilliseconds,
				this.currentTimeZoneOffset
			);
		}

		// Switchover 15898 GPU Power On
		if(this.tags.GPU[15898]) {
			this.turn.GPUPowerOn = this.GetFirstTimeFromDataCache(this.tags.GPU[15898], '1', currentDockWindowStartMS);
			this.currentTurn.GPUSwitchover = this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
				this.turn.GPUPowerOn.DateInMilliseconds,
				this.currentTimeZoneOffset
			);
		}


		// Switchover 1942 Amps Out Average
		if(this.tags.GPU[1942]) {

			var gpuHistorical = this.tags.GPU[1942].Historical.orderBy((h:any) => { return h.DateInMilliseconds }).toArray();
			var firstItem = gpuHistorical
							.where((h:any) => h.Value > 6 && h.DateInMilliseconds > currentDockWindowStartMS)
							.firstOrDefault();

				// var firstItem = this.FirstMatchingOverValue(
				// 	6,
				// 	gpuHistoricalAfterDockTime
				// );
			if (firstItem) {
				this.turn.AmpsOutAverage = firstItem;
				this.currentTurn.GPUSwitchover =
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						firstItem.DateInMilliseconds,
						this.currentTimeZoneOffset
					);
			}
		}


		//PCA

		// get current values
		this.turn.pcaUnitOn = this.tags.PCA[12374];
		this.turn.BridgeMode = this.tags.PCA[15220];
		//var pcaWhenSwitchedOn = this.FirstMatchingValueAlt('1', this.tags.PCA[12374].Historical);

		// is the PCA already on
		var pcaOnAtDockTime = this.GetValueAtTimeFromDataCache(this.tags.PCA[12374], currentDockWindowStartMS);
		var BridgeModeAtDockTime = this.GetValueAtTimeFromDataCache(this.tags.PCA[15220], currentDockWindowStartMS);
		if(pcaOnAtDockTime?.Value == "1" &&  (BridgeModeAtDockTime == null || BridgeModeAtDockTime?.Value == "0") ) {
			this.currentTurn.PCAVentilatingAircraft =
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						pcaOnAtDockTime.DateInMilliseconds > BridgeModeAtDockTime.DateInMilliseconds ? pcaOnAtDockTime.DateInMilliseconds : BridgeModeAtDockTime.DateInMilliseconds,
						this.currentTimeZoneOffset
					);
		}
		else if(pcaOnAtDockTime?.Value == "1" && BridgeModeAtDockTime?.Value == "1") {
			// when does bridge mode get turned off
			this.turn.BridgeMode = this.GetFirstTimeFromDataCacheOrNull(this.tags.PCA[15220], '0', currentDockWindowStartMS);
			this.currentTurn.PCAVentilatingAircraft =this.turn.BridgeMode == null ? null :
					this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
						this.turn.BridgeMode.DateInMilliseconds,
						this.currentTimeZoneOffset
					);
		}
		else if(pcaOnAtDockTime?.Value == "0" && (BridgeModeAtDockTime == null || BridgeModeAtDockTime?.Value == "0")) {
			// when is the unit turned on
			let valueWhenTurnedOn = this.GetFirstTimeFromDataCache(this.tags.PCA[12374], '1', currentDockWindowStartMS);
			if(valueWhenTurnedOn.Value == "1") {
				this.currentTurn.PCAVentilatingAircraft =
						this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
							valueWhenTurnedOn.DateInMilliseconds,
							this.currentTimeZoneOffset
						);
			}
		}
		else if(pcaOnAtDockTime?.Value == "0" && BridgeModeAtDockTime?.Value == "1") {
			let valueWhenTurnedOn = this.GetFirstTimeFromDataCache(this.tags.PCA[12374], '1', currentDockWindowStartMS);
			let valueWhenBridgeModeTurnedOff = this.GetFirstTimeFromDataCache(this.tags.PCA[15220], '0', currentDockWindowStartMS);
			if(valueWhenTurnedOn.Value == "1" && valueWhenBridgeModeTurnedOff.Value == "0") {
				this.currentTurn.PCAVentilatingAircraft =
						this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
							valueWhenTurnedOn.DateInMilliseconds > valueWhenBridgeModeTurnedOff.DateInMilliseconds ?
								valueWhenTurnedOn.DateInMilliseconds : valueWhenBridgeModeTurnedOff.DateInMilliseconds,
							this.currentTimeZoneOffset
						);
			}
		}
		// else if(this.turn.pcaUnitOn?.Value == "1" && this.turn.BridgeMode?.Value == "0") {
		// 	this.currentTurn.PCAVentilatingAircraft =
		// 				this.utilityService.convertMillisecondsToDateWithTimezoneOffset(
		// 					this.turn.pcaUnitOn.DateInMilliseconds > this.turn.BridgeMode.DateInMilliseconds ?
		// 						this.turn.pcaUnitOn.DateInMilliseconds : this.turn.BridgeMode.DateInMilliseconds,
		// 					this.currentTimeZoneOffset
		// 				);
		// }


		// current dock in MS
		this.currentDock.PBBMoveStartMS =
			this.currentTurn.PBBMoveStart?.getTime();
		this.currentDock.PBBDockedMS = this.currentTurn.PBBDocked?.getTime();
		this.currentDock.GPUOnMS = this.currentTurn.GPUOn?.getTime();
		this.currentDock.GPUSwitchoverMS =
			this.currentTurn.GPUSwitchover?.getTime();
		this.currentDock.GPUOFFMS = this.currentTurn.GPUOFF?.getTime();
		this.currentDock.PCAVentilatingAircraftMS =
			this.currentTurn.PCAVentilatingAircraft?.getTime();
		this.currentDock.PCAOFFMS = this.currentTurn.PCAOFF?.getTime();
		this.currentDock.PBBUndockedMS =
			this.currentTurn.PBBUndocked?.getTime();
		this.currentDock.PBBMoveAwayMS =
			this.currentTurn.PBBMoveAway?.getTime();
	}

	// UpdateTimers() {
	// 	// update timers
	// 	this.state.turn = 'Disconnected';

	// 	if(this.turn.docked?.Value=='1') {

	// 		this.state.turn = 'Connected';
	// 		this.timers.turn = moment().valueOf() - this.turn.docked.DateInMilliseconds;

	// 		// if(this.turn.isMoving) {
	// 		// 	this.timers.docked = this.turn.docked.DateInMilliseconds - this.turn.isMoving.DateInMilliseconds; // time started moving to docked
	// 		// }

	// 		this.state.gpu = 'OFF';

	// 		if (this.turn.gpuUnitOn?.Value == '1') {
	// 			this.timers.gpuUnitOn = this.turn.gpuUnitOn.DateInMilliseconds - this.turn.docked.DateInMilliseconds; // dock to GPU ON
	// 			this.state.gpu = 'ON';

	// 			if (this.turn.AircraftOnGPUPower?.Value == '1') {
	// 				this.timers.AircraftOnGPUPower = this.turn.AircraftOnGPUPower .DateInMilliseconds - this.turn.gpuUnitOn.DateInMilliseconds; // GPU ON to Switchover
	// 				this.state.gpu = 'Switchover';
	// 			} else if (this.turn.GPUPowerOn?.Value == '1') {
	// 				this.timers.AircraftOnGPUPower = this.turn.GPUPowerOn.DateInMilliseconds - this.turn.gpuUnitOn.DateInMilliseconds; // GPU ON to Switchover
	// 				this.state.gpu = 'Switchover';
	// 			} else if ( Number(this.turn.AmpsOutAverage?.Value) > 6.0 ) {
	// 				this.timers.AircraftOnGPUPower = this.turn.AmpsOutAverage .DateInMilliseconds - this.turn.gpuUnitOn.DateInMilliseconds; // GPU ON to Switchover
	// 				this.state.gpu = 'Switchover';
	// 			}
	// 			else {
	// 				this.timers.AircraftOnGPUPower = moment().valueOf() - this.turn.gpuUnitOn.DateInMilliseconds; // GPU ON to Switchover
	// 			}

	// 		}
	// 		else {
	// 			this.timers.gpuUnitOn = moment().valueOf() - this.turn.docked.DateInMilliseconds; // time from dock
	// 			this.state.gpu = 'OFF';
	// 		}

	// 		if (
	// 			this.turn.pcaUnitOn?.Value == '1' &&
	// 			this.turn.BridgeMode?.Value == '0'
	// 		) {
	// 			var pcaVentAircraft =
	// 				this.turn.pcaUnitOn.DateInMilliseconds >
	// 				this.turn.BridgeMode.DateInMilliseconds
	// 					? this.turn.pcaUnitOn.DateInMilliseconds
	// 					: this.turn.BridgeMode.DateInMilliseconds;
	// 			this.timers.pcaUnitOn =
	// 				pcaVentAircraft -
	// 				this.turn.docked.DateInMilliseconds; // dock to PCA ON
	// 		} else {
	// 			this.timers.pcaUnitOn =
	// 				moment().valueOf() -
	// 				this.turn.docked.DateInMilliseconds; // time from dock
	// 		}
	// 	} else {
	// 		// this.turn.docked.Value=='0'

	// 		this.state.turn = 'Disconnected';
	// 		if(this.turn.isMoving?.Value=='1') {
	// 			this.state.turn = 'Connecting';
	// 		}
	// 		else {
	// 			// look at last history for when Value==1
	// 			// if(this.turn.isMoving?.Historical.length > 0) {
	// 			// 	var startedMoving = this.turn.isMoving.Historical.select((item) => item.Value == '1')
	// 			// 							.orderByDescending().toArray().First();
	// 			// 	if(startedMoving != undefined) {
	// 			// 		this.timers.isMoving = moment().valueOf() - startedMoving.DateInMilliseconds;
	// 			// 	}
	// 			// }
	// 		}

	// 		// look at last history for when Value==1
	// 		// if (this.turn.docked.Historical.length > 0) {
	// 		// 	// var dockStart = this.turn.docked.Historical.select(
	// 		// 	// 	(item) => item.Value == '1'
	// 		// 	// )
	// 		// 	// 	.orderByDescending()
	// 		//  //  .ToArray()
	// 		// 	// 	.First();
	// 		// 	// this.timers.docked =
	// 		// 	// 	dockStart.DateInMilliseconds -
	// 		// 	// 	this.turn.docked.DateInMilliseconds; // time from dock to undock
	// 		// } else {
	// 		// 	this.timers.docked =
	// 		// 		moment().valueOf() -
	// 		// 		this.turn.docked.DateInMilliseconds; // time from dock to undock
	// 		// }
	// 	}
	// }

	UpdateTurnTimers() {
		// update timers

		//
		// PBB
		//

		this.state.pbb = 'Undocked';

		if (this.currentDock.PBBMoveStartMS > 0) {
			//update PBB as moving
			if (
				this.currentDock.PBBDockedMS == undefined ||
				this.currentDock.PBBDockedMS == 0
			) {
				this.pbb.duration.totalMS =
					moment().valueOf() - this.currentDock.PBBMoveStartMS; // ms from move start
				this.state.pbb = 'Driving';
			} else {
				this.pbb.duration.totalMS =
					this.currentDock.PBBDockedMS -
					this.currentDock.PBBMoveStartMS; // ms from move start
				this.state.pbb = 'Docked';
			}

			this.SetDisplayDuration(this.pbb.duration);
		} else {
			this.pbb.duration.totalMS = 0;
		}

		if (this.currentDock.PBBDockedMS > 0) {
			if (this.currentDock.PBBUndockedMS == undefined) {
				this.pbbdock.duration.totalMS =
					moment().valueOf() - this.currentDock.PBBDockedMS; // ms from dock
				this.state.pbb = 'Docked';
			} else {
				this.pbbdock.duration.totalMS =
					this.currentDock.PBBUndockedMS -
					this.currentDock.PBBDockedMS; // ms from dock
			}

			this.SetDisplayDuration(this.pbbdock.duration);
		}

		if (this.currentDock.PBBUndockedMS > 0) {

			// update pbb move away
			if (
				this.currentDock.PBBMoveAwayMS == undefined ||
				this.currentDock.PBBMoveAwayMS == 0
			) {
				this.pbbaway.duration.totalMS =
					moment().valueOf() - this.currentDock.PBBUndockedMS; // ms from undock
				this.state.pbb = 'Disconnecting';
			} else {
				this.pbbaway.duration.totalMS =
					this.currentDock.PBBMoveAwayMS -
					this.currentDock.PBBUndockedMS; // ms from undock
				this.state.pbb = 'Disconnected';

			}

			this.SetDisplayDuration(this.pbbaway.duration);
		}

		// update gradiant
		let pbbTime = Math.round(
			(100 * this.pbb.duration.totalMS) / (this.largeTimescale * 60000)
		);
		this.pbb.progressBackground = `conic-gradient(#008fff ${pbbTime}%, #f2f2f4 ${pbbTime}%)`;

		// let pbbdockTime = Math.round(
		// 	(100 * this.pbbdock.duration.totalMS) / (this.largeTimescale * 60000)
		// );
		// let pbbawayTime = Math.round(
		// 	(100 * this.pbbaway.duration.totalMS) / (this.largeTimescale * 60000)
		// );
		// let pbbplusdockTime = pbbTime + pbbdockTime;
		// let pbbTotalTime = pbbTime + pbbdockTime + pbbawayTime;
		// this.pbb.progressBackground = `conic-gradient(#008fff ${pbbTime}%, #7100ff ${pbbTime}%, #7100ff ${pbbplusdockTime}%, #000fff ${pbbplusdockTime}%, #000fff ${pbbTotalTime}%, #f2f2f4 ${pbbTotalTime}%)`;

		//
		// GPU
		//

		this.state.gpu = 'OFF';
		this.gputotal.duration.totalMS = 0;

		if (this.currentDock.PBBDockedMS > 0) {
			//update GPU
			if (this.currentDock.GPUOnMS != undefined) {
				this.gpu.duration.totalMS =
					this.currentDock.GPUOnMS - this.currentDock.PBBDockedMS; // ms from dock
			} else if (this.currentDock.GPUSwitchoverMS != undefined) {
				this.gpu.duration.totalMS =
					this.currentDock.GPUSwitchoverMS -
					this.currentDock.PBBDockedMS; // ms from dock
			// } else if (this.currentDock.GPUOFFMS != undefined) {
			// 	this.gpu.duration.totalMS =
			// 		this.currentDock.GPUOFFMS - this.currentDock.PBBDockedMS; // ms from dock
			} else if (this.currentDock.PBBUndockedMS != undefined) {
				this.gpu.duration.totalMS =
					this.currentDock.PBBUndockedMS -
					this.currentDock.PBBDockedMS; // ms from dock
			} else {
				this.gpu.duration.totalMS =
					moment().valueOf() - this.currentDock.PBBDockedMS; // ms from dock
				this.state.gpu = 'Docked';
			}

			this.gputotal.duration.totalMS = this.gpu.duration.totalMS;

			this.SetDisplayDuration(this.gpu.duration);

			// update gradiant
			let gpuTime = Math.round(
				(100 * this.gpu.duration.totalMS) /
					(this.largeTimescale * 60000)
			);
			this.gpu.progressBackground = `conic-gradient(#008fff ${gpuTime}%, #f2f2f4 ${gpuTime}%)`;
		}

		if (this.currentDock.GPUOnMS > 0) {
			//update GPU
			if (this.currentDock.GPUSwitchoverMS != undefined) {
				this.gpuon.duration.totalMS =
					this.currentDock.GPUSwitchoverMS - this.currentDock.GPUOnMS; // ms from gpu on
			} else if (this.currentDock.GPUOFFMS != undefined) {
				this.gpuon.duration.totalMS =
					this.currentDock.GPUOFFMS - this.currentDock.GPUOnMS; // ms from gpu on
			} else if (this.currentDock.PBBUndockedMS != undefined) {
				this.gpuon.duration.totalMS =
					this.currentDock.PBBUndockedMS - this.currentDock.GPUOnMS; // ms from gpu on
			} else {
				this.gpuon.duration.totalMS =
					moment().valueOf() - this.currentDock.GPUOnMS; // ms from gpu on
				this.state.gpu = 'ON';
			}

			this.gputotal.duration.totalMS += this.gpuon.duration.totalMS;

			this.SetDisplayDuration(this.gpuon.duration);

			// update gradiant
			let gpuTime = Math.round(
				(100 * this.gpu.duration.totalMS) /
					(this.largeTimescale * 60000)
			);
			let gpuonTime = Math.round(
				(100 * this.gpuon.duration.totalMS) /
					(this.largeTimescale * 60000)
			);
			let gputotalTime = gpuTime + gpuonTime;
			this.gpu.progressBackground = `conic-gradient(#008fff ${gpuTime}%, #7100ff ${gpuTime}%, #7100ff ${gputotalTime}%, #f2f2f4 ${gputotalTime}%)`;
		}


		this.SetDisplayDuration(this.gputotal.duration);


		if (this.currentDock.GPUSwitchoverMS > 0) {
			//update GPU
			if (this.currentDock.GPUOFFMS != undefined) {
				this.gpuswitchover.duration.totalMS =
					this.currentDock.GPUOFFMS -
					this.currentDock.GPUSwitchoverMS; // ms from gpu switchover
			} else if (this.currentDock.PBBUndockedMS != undefined) {
				this.gpuswitchover.duration.totalMS =
					this.currentDock.PBBUndockedMS -
					this.currentDock.GPUSwitchoverMS; // ms from gpu switchover
			} else {
				this.gpuswitchover.duration.totalMS =
					moment().valueOf() - this.currentDock.GPUSwitchoverMS; // ms from gpu switchover
				this.state.gpu = 'Switchover';
			}

			this.SetDisplayDuration(this.gpuswitchover.duration);

			// // update gradiant - 2 color // dark blue  #000fff
			// let gpuTime = Math.round(
			// 	(100 * this.gpu.duration.totalMS) / (this.largeTimescale * 60000)
			// );
			// let gpuonTime = Math.round(
			// 	(100 * this.gpuon.duration.totalMS) / (this.largeTimescale * 60000)
			// );
			// let gpuswitchoverTime = Math.round(
			// 	(100 * this.gpuswitchover.duration.totalMS) / (this.largeTimescale * 60000)
			// );
			// let gpuplusonTime = gpuTime + gpuonTime;
			// let gputotalTime = gpuTime + gpuonTime + gpuswitchoverTime;
			// this.gpu.progressBackground = `conic-gradient(#008fff ${gpuTime}%, #7100ff ${gpuTime}%, #7100ff ${gpuplusonTime}%, #000fff ${gpuplusonTime}%, #000fff ${gputotalTime}%, #f2f2f4 ${gputotalTime}%)`;
		}

		//
		// PCA / AHU
		//

		this.state.pca = 'OFF';

		if (this.currentDock.PBBDockedMS > 0) {
			//update PCA
			if (this.currentDock.PCAVentilatingAircraftMS != undefined) {
				this.pca.duration.totalMS =
					this.currentDock.PCAVentilatingAircraftMS -
					this.currentDock.PBBDockedMS; // ms from dock
			// } else if (this.currentDock.PCAOFFMS != undefined) {
			// 	this.pca.duration.totalMS =
			// 		this.currentDock.PCAOFFMS - this.currentDock.PBBDockedMS; // ms from dock
			} else if (this.currentDock.PBBUndockedMS != undefined) {
				this.pca.duration.totalMS =
					this.currentDock.PBBUndockedMS -
					this.currentDock.PBBDockedMS; // ms from dock
			} else {
				this.pca.duration.totalMS =
					moment().valueOf() - this.currentDock.PBBDockedMS; // ms from dock
				this.state.pca = 'Docked';
			}


			this.SetDisplayDuration(this.pca.duration);

			// update gradiant
			let pcaTime = Math.round(
				(100 * this.pca.duration.totalMS) /
					(this.largeTimescale * 60000)
			);
			this.pca.progressBackground = `conic-gradient(#008fff ${pcaTime}%, #f2f2f4 ${pcaTime}%)`;
		}

		if (this.currentDock.PCAVentilatingAircraftMS > 0) {
			//update PCA

			if (this.currentDock.PCAOFFMS != undefined) {
				this.pcaon.duration.totalMS =
					this.currentDock.PCAOFFMS -
					this.currentDock.PCAVentilatingAircraftMS; // ms from pca on
			} else if (this.currentDock.PBBUndockedMS != undefined) {
				this.pcaon.duration.totalMS =
					this.currentDock.PBBUndockedMS -
					this.currentDock.PCAVentilatingAircraftMS; // ms from pca on
			} else {
				this.pcaon.duration.totalMS =
					moment().valueOf() -
					this.currentDock.PCAVentilatingAircraftMS; // ms from pca on
				this.state.pca = 'ON';
			}

			this.SetDisplayDuration(this.pcaon.duration);

			// // update gradiant
			// let pcaTime = Math.round(
			// 	(100 * this.pca.duration.totalMS) / (this.largeTimescale * 60000)
			// );
			// let pcaonTime = Math.round(
			// 	(100 * this.pcaon.duration.totalMS) / (this.largeTimescale * 60000)
			// );
			// let pcaTotalTime = pcaTime + pcaonTime;
			// this.pca.progressBackground = `conic-gradient(#008fff ${pcaTime}%, #000fff ${pcaTime}%, #000fff ${pcaTotalTime}%, #f2f2f4 ${pcaTotalTime}%)`;
		}

		//
		// Overall dock
		//

		if (this.currentDock.PBBMoveStartMS > 0) {
			// update dock
			if (
				this.currentDock.PBBUndockedMS == undefined ||
				this.currentDock.PBBUndockedMS == 0
			) {
				this.dock.duration.totalMS =
					moment().valueOf() - this.currentDock.PBBMoveStartMS; // ms from move start
			} else {
				this.dock.duration.totalMS =
					this.currentDock.PBBUndockedMS -
					this.currentDock.PBBMoveStartMS; // ms from move start
			}
		} else if (this.currentDock.PBBDockedMS > 0) {
			// update dock
			if (
				this.currentDock.PBBUndockedMS == undefined ||
				this.currentDock.PBBUndockedMS == 0
			) {
				this.dock.duration.totalMS =
					moment().valueOf() - this.currentDock.PBBDockedMS; // ms from docked
			} else {
				this.dock.duration.totalMS =
					this.currentDock.PBBUndockedMS -
					this.currentDock.PBBDockedMS; // ms from docked
			}
		}

		this.SetDisplayDuration(this.dock.duration);

		// set overall state
		if (this.currentDock.PBBUndockedMS > 0) {
			this.state.turn = 'Disconnected';
		} else if (this.currentDock.PBBDockedMS > 0) {
			this.state.turn = 'Connected';
		} else if (this.currentDock.PBBMoveStartMS > 0) {
			this.state.turn = 'Connecting';
		} else {
			this.state.turn = 'Disconnected';
		}

		// gradiant
		let dockTime = Math.round(
			(100 * this.dock.duration.totalMS) / (this.largeTimescale * 60000)
		); // 60 mins
		this.dock.progressBackground = `conic-gradient(#008fff ${dockTime}%, #f2f2f4 ${dockTime}%)`;
	}

	// UpdateGradients() {
	// 	// update gradients
	// 	let pbbTime = Math.round(
	// 		(100 * this.timers.docked) /
	// 			(this.largeTimescale * 60000)
	// 	);
	// 	this.gradients.docked = `conic-gradient(#008fff ${pbbTime}%, #f2f2f4 ${pbbTime}%)`;

	// 	// update GPU gradiant
	// 	let gpuTime = Math.round(
	// 		(100 * this.gpu.duration.totalMS) /
	// 			(this.largeTimescale * 60000)
	// 	);
	// 	let gpuonTime = Math.round(
	// 		(100 * this.gpuon.duration.totalMS) /
	// 			(this.largeTimescale * 60000)
	// 	);
	// 	let gputotalTime = gpuTime + gpuonTime;
	// 	this.gpu.progressBackground = `conic-gradient(#008fff ${gpuTime}%, #7100ff ${gpuTime}%, #7100ff ${gputotalTime}%, #f2f2f4 ${gputotalTime}%)`;

	// 	// update PCA gradiant
	// 	let pcaTime = Math.round(
	// 		(100 * this.pca.duration.totalMS) / (this.largeTimescale * 60000)
	// 	);
	// 	let pcaonTime = Math.round(
	// 		(100 * this.pcaon.duration.totalMS) / (this.largeTimescale * 60000)
	// 	);
	// 	let pcaTotalTime = pcaTime + pcaonTime;
	// 	this.pca.progressBackground = `conic-gradient(#008fff ${pcaTime}%, #000fff ${pcaTime}%, #000fff ${pcaTotalTime}%, #f2f2f4 ${pcaTotalTime}%)`;
	// }

	/**********
	UpdateValuesFromDataCache() {

		var perfectTurnTags = this.dataService.cache.systemsObject[this.widgetObject.WidgetGateSystemId].PerfectTurn;
		//console.log("perfect turn tags = %O", perfectTurnTags);

						//this.cache.tagsObject[entityFromDatabase.Id]
		// Values
		this.turn.docked = perfectTurnTags.PBB.find((item) => item.JBTStandardObservationId === 12245);
		this.turn.isMoving = perfectTurnTags.PBB.find((item) => item.JBTStandardObservationId === 54271);

		this.turn.gpuUnitOn = perfectTurnTags.GPU.find((item) => item.JBTStandardObservationId === 12374);
		this.turn.AircraftOnGPUPower = perfectTurnTags.GPU.find((item) => item.JBTStandardObservationId === 15166);
		this.turn.GPUPowerOn = perfectTurnTags.GPU.find((item) => item.JBTStandardObservationId === 15898);
		this.turn.AmpsOutAverage = perfectTurnTags.GPU.find((item) => item.JBTStandardObservationId === 1942);

		this.turn.pcaUnitOn = perfectTurnTags.PCA.find((item) => item.JBTStandardObservationId === 12374);
		this.turn.BridgeMode = perfectTurnTags.PCA.find((item) => item.JBTStandardObservationId === 15220);


		// update values
		if(this.turn.isMoving?.Value=='1') {
			this.currentTurn.PBBMoveStartMS = this.turn.isMoving.DateInMilliseconds
		}

		if(this.turn.docked?.Value=='1') {
			this.currentTurn.PBBDockedMS = this.turn.docked.DateInMilliseconds
		}
		else if(this.turn.docked?.Value=='0') {
			this.currentTurn.PBBUndockedMS = this.turn.docked.DateInMilliseconds
			if(this.turn.docked.Historical.length > 0) {
				this.currentTurn.PBBDockedMS = this.turn.docked.Historical[0].DateInMilliseconds;
			}
		}

		if(this.turn.gpuUnitOn?.Value=='1') {
			this.currentTurn.GPUOnMS = this.turn.gpuUnitOn.DateInMilliseconds
		}
		else if(this.turn.gpuUnitOn?.Value=='0') {
			this.currentTurn.GPUOFFMS = this.turn.gpuUnitOn.DateInMilliseconds
		}

		if(this.turn.AmpsOutAverage?.Value=='1') { // TODO: add other 2 options
			this.currentTurn.GPUSwitchoverMS = this.turn.AmpsOutAverage.DateInMilliseconds
		}


		if(this.turn.pcaUnitOn?.Value=='1') {
			this.currentTurn.PCAVentilatingAircraftMS = this.turn.pcaUnitOn.DateInMilliseconds
		}
		else if(this.turn.pcaUnitOn?.Value=='0') {
			this.currentTurn.PCAOFFMS = this.turn.pcaUnitOn.DateInMilliseconds
		}

	}
 	*/

	/*********
	initializePerfectTurnDataCache() {
		var selectedGateSystem =
			this.dataService.cache.systemsObject[
				this.widgetObject.WidgetGateSystemId
			];
		var assetIds = selectedGateSystem.Assets.select((asset: IAsset) => {
			return asset.Id;
		})
			.toArray()
			.join(',');
		var standardObservationIds =
			this.dataService.perfectTurnStandardObservationIds.PBB +
			',' +
			this.dataService.perfectTurnStandardObservationIds.PCA +
			',' +
			this.dataService.perfectTurnStandardObservationIds.GPU;

		var selectedGateSystem =  this.dataService.cache.systemsObject[this.widgetObject.WidgetGateSystemId];
		var assetIds = selectedGateSystem.Assets.select((asset:IAsset) => { return asset.Id}).toArray().join(",");
		var standardObservationIds = this.dataService.perfectTurnStandardObservationIds.PBB + "," + this.dataService.perfectTurnStandardObservationIds.PCA + "," + this.dataService.perfectTurnStandardObservationIds.GPU;

		//this.dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds();


		//let statement = "API.PerfectTurn_TagsWithHistorical @assetIds='" + assetIds + "', @jbtStandardObservationIds='" + standardObservationIds + "'";

		//this.dataService.SQLActionAsPromise(statement).then((data: any) => {
		this.dataService.GetPerfectTurnSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(assetIds, standardObservationIds).subscribe((data) => {
			if (data.length > 0) {
				// var newData = this.dataService.GetBrokenOutFieldsFromStringTagData(data);
				// newData.map((t) => {
				// 	var tag: any = t;
				// 	var formattedCacheTagObject =
				// 		this.dataService.GetStandardCacheTagObjectFromDatabaseFields(
				// 			tag
				// 		);
				// 	//Global.User.DebugMode && console.log(formattedCacheTagObject);
				// 	if (!formattedCacheTagObject.TagName) {
				// 		debugger;
				// 	}
				// });

				//var perfectTurnTags = this.dataService.cache.systemsObject[this.widgetObject.WidgetGateSystemId].PerfectTurn;
				//console.log("initial perfect turn tags = %O", perfectTurnTags);

				this.runtimeData = data;


				this.turn.testdata = data.find(
					(item) => item.JBTStandardObservationId === 2736
				);

				// Values
				this.turn.docked = data.find(
					(item) => item.JBTStandardObservationId === 12245
				);
				this.turn.isMoving = data.find(
					(item) => item.JBTStandardObservationId === 54271
				);

				this.turn.gpuUnitOn = data.find(
					(item) => item.JBTStandardObservationId === 12374 && item.Asset.Name == "GPU"
				);
				this.turn.AircraftOnGPUPower = data.find(
					(item) => item.JBTStandardObservationId === 15166 && item.Asset.Name == "GPU"
				);
				this.turn.GPUPowerOn = data.find(
					(item) => item.JBTStandardObservationId === 15898 && item.Asset.Name == "GPU"
				);
				this.turn.AmpsOutAverage = data.find(
					(item) => item.JBTStandardObservationId === 1942 && item.Asset.Name == "GPU"
				);

				this.turn.pcaUnitOn = data.find((item) => item.JBTStandardObservationId === 12374 && (item.Asset.Name == "AHU" || item.Asset.Name == "PCA"));
				this.turn.BridgeMode = data.find((item) => item.JBTStandardObservationId === 15220 && (item.Asset.Name == "AHU" || item.Asset.Name == "PCA"));

				console.log('perfect turn tags = %O', this.turn);

				// init values
				this.currentTurn.PBBMoveAwayMS = null;
				this.currentTurn.PBBDockedMS = null;
				this.currentTurn.PBBUndockedMS = null;
				this.currentTurn.GPUOnMS = null;
				this.currentTurn.GPUSwitchoverMS = null;
				this.currentTurn.GPUOFFMS = null;
				this.currentTurn.PCAVentilatingAircraftMS = null;
				this.currentTurn.PCAOFFMS = null;

				// init timers
				this.timers.docked = 0.0;
				this.timers.gpuUnitOn = 0.0;
				this.timers.pcaUnitOn = 0.0;
				this.timers.AircraftOnGPUPower = 0.0;
				this.timers.turn = 0.0;

				// Timers
				var turnInterval = setInterval(() => {


					this.UpdateValuesFromDataCache();

					// update timers
					this.state.turn = 'Disconnected';

					if(this.turn.docked?.Value=='1') {

						this.state.turn = 'Connected';
						this.timers.turn = moment().valueOf() - this.turn.docked.DateInMilliseconds;


						if (this.turn.isMoving.Value == '0') {
							this.timers.docked =
								this.turn.docked.DateInMilliseconds -
								this.turn.isMoving.DateInMilliseconds; // time stopped to dock
						}


						this.state.gpu = 'OFF';

						if (this.turn.gpuUnitOn.Value == '1') {
							this.timers.gpuUnitOn =
								this.turn.gpuUnitOn.DateInMilliseconds -
								this.turn.docked.DateInMilliseconds; // dock to GPU ON
							this.state.gpu = 'ON';

							if (this.turn.AircraftOnGPUPower?.Value == '1') {
								this.timers.AircraftOnGPUPower =
									this.turn.AircraftOnGPUPower
										.DateInMilliseconds -
									this.turn.gpuUnitOn.DateInMilliseconds; // GPU ON to Switchover
								this.state.gpu = 'Switchover';
							} else if (this.turn.GPUPowerOn?.Value == '1') {
								this.timers.AircraftOnGPUPower =
									this.turn.GPUPowerOn.DateInMilliseconds -
									this.turn.gpuUnitOn.DateInMilliseconds; // GPU ON to Switchover
								this.state.gpu = 'Switchover';
							} else if (
								Number(this.turn.AmpsOutAverage?.Value) > 6.0
							) {
								this.timers.AircraftOnGPUPower =
									this.turn.AmpsOutAverage
										.DateInMilliseconds -
									this.turn.gpuUnitOn.DateInMilliseconds; // GPU ON to Switchover
								this.state.gpu = 'Switchover';
							}
							else {
								this.timers.AircraftOnGPUPower = moment().valueOf() - this.turn.gpuUnitOn.DateInMilliseconds; // GPU ON to Switchover
							}


						}
						else {
							this.timers.gpuUnitOn = moment().valueOf() - this.turn.docked.DateInMilliseconds; // time from dock
							this.state.gpu = 'OFF';
						}

						if (
							this.turn.pcaUnitOn.Value == '1' &&
							this.turn.BridgeMode.Value == '0'
						) {
							var pcaVentAircraft =
								this.turn.pcaUnitOn.DateInMilliseconds >
								this.turn.BridgeMode.DateInMilliseconds
									? this.turn.pcaUnitOn.DateInMilliseconds
									: this.turn.BridgeMode.DateInMilliseconds;
							this.timers.pcaUnitOn =
								pcaVentAircraft -
								this.turn.docked.DateInMilliseconds; // dock to PCA ON
						} else {
							this.timers.pcaUnitOn =
								moment().valueOf() -
								this.turn.docked.DateInMilliseconds; // time from dock
						}
					} else {
						// this.turn.docked.Value=='0'

						this.state.turn = 'Disconnected';
						if(this.turn.isMoving?.Value=='1') {
							this.state.turn = 'Connecting';
						}
						else {
							// look at last history for when Value==1
							if(this.turn.isMoving?.Historical.length > 0) {
								var startedMoving = this.turn.isMoving.Historical.select((item) => item.Value == '1')
														.orderByDescending().toArray().First();
								if(startedMoving != undefined) {
									this.timers.isMoving = moment().valueOf() - startedMoving.DateInMilliseconds;
								}
							}
						}

						// look at last history for when Value==1
						// if (this.turn.docked.Historical.length > 0) {
						// 	// var dockStart = this.turn.docked.Historical.select(
						// 	// 	(item) => item.Value == '1'
						// 	// )
						// 	// 	.orderByDescending()
						//  //  .ToArray()
						// 	// 	.First();
						// 	// this.timers.docked =
						// 	// 	dockStart.DateInMilliseconds -
						// 	// 	this.turn.docked.DateInMilliseconds; // time from dock to undock
						// } else {
						// 	this.timers.docked =
						// 		moment().valueOf() -
						// 		this.turn.docked.DateInMilliseconds; // time from dock to undock
						// }
					}

					// update gradients
					let pbbTime = Math.round(
						(100 * this.timers.docked) /
							(this.largeTimescale * 60000)
					);
					this.gradients.docked = `conic-gradient(#008fff ${pbbTime}%, #f2f2f4 ${pbbTime}%)`;

					let gpuTime = Math.round(
						(100 * this.timers.gpuUnitOn) /
							(this.largeTimescale * 60000)
					);
					let gpuSwitchTime = Math.round(
						(100 * this.timers.AircraftOnGPUPower) /
							(this.largeTimescale * 60000)
					);
					let gpuTotalTime = gpuTime + gpuSwitchTime;
					this.gradients.gpuUnitOn = `conic-gradient(#008fff ${gpuTime}%, #7100ff ${gpuTime}%, #7100ff ${gpuTotalTime}%, #f2f2f4 ${gpuTotalTime}%)`;

					let pcaTime = Math.round(
						(100 * this.timers.pcaUnitOn) /
							(this.largeTimescale * 60000)
					);
					this.gradients.pcaUnitOn = `conic-gradient(#008fff ${pcaTime}%, #f2f2f4 ${pcaTime}%)`;

					let turnTime = Math.round(
						(100 * this.timers.turn) / (this.largeTimescale * 60000)
					);
					this.gradients.turn = `conic-gradient(#008fff ${turnTime}%, #f2f2f4 ${turnTime}%)`;
				}, 1000);

				var dataInterval = setInterval(() => {
					// var perfectTurnTags =
					// 	this.dataService.cache.systemsObject[
					// 		this.widgetObject.WidgetGateSystemId
					// 	].PerfectTurn;
					this.turn.testdata = this.runtimeData.find(
						(item) => item.JBTStandardObservationId === 2736
					);

					// Values
					this.turn.docked = this.runtimeData.find(
						(item) => item.JBTStandardObservationId === 12245
					);
					this.turn.isMoving = this.runtimeData.find(
						(item) => item.JBTStandardObservationId === 54271
					);

					this.turn.gpuUnitOn = this.runtimeData.find(
						(item) => item.JBTStandardObservationId === 12374 && item.Asset.Name == "GPU"
					);
					this.turn.AircraftOnGPUPower = this.runtimeData.find(
						(item) => item.JBTStandardObservationId === 15166 && item.Asset.Name == "GPU"
					);
					this.turn.GPUPowerOn = this.runtimeData.find(
						(item) => item.JBTStandardObservationId === 15898 && item.Asset.Name == "GPU"
					);
					this.turn.AmpsOutAverage = this.runtimeData.find(
						(item) => item.JBTStandardObservationId === 1942 && item.Asset.Name == "GPU"
					);

					this.turn.pcaUnitOn = this.runtimeData.find((item) => item.JBTStandardObservationId === 12374 &&
						(item.Asset.Name == "AHU" || item.Asset.Name == "PCA"));
					this.turn.BridgeMode = this.runtimeData.find((item) => item.JBTStandardObservationId === 15220 &&
						(item.Asset.Name == "AHU" || item.Asset.Name == "PCA"));


					console.log('perfect turn tags = %O', this.turn);
				}, 60000);

				this.isDataLoading = false;
				this.hasData = true;



			}
		});

	}
	*/

	initialize() {
		if (
			this.widgetObject.WidgetSiteId &&
			this.widgetObject.WidgetSiteId !== 5
		) {
			this.initializeMinimal();
			// this.getDatesFromDashboard();
			let retrievalDates =
				this.dashboardService.determineProperTimeScopeForWidget({
					widgetObject: this.widgetObject,
				});
			this.timeZoneType = this.dashboardService.determineTimeZoneType(
				this.widgetObject
			);
			//Setting offset to 0 if not site time so that it can be utc time
			this.siteUTCTimeOffset =
				this.timeZoneType === 'Site Time'
					? this.dataService.cache.sitesObject[
							this.widgetObject.WidgetSiteId
					  ].UTCTimeOffset
					: 0; // UTC offset to site time always?

			let startDateSiteTime = moment(
				new Date(retrievalDates.queryStartDate)
			).subtract(this.siteUTCTimeOffset, 'h');

			let formattedStartDate = moment(startDateSiteTime).format(
				'YYYY/MM/DD HH:mm:ss'
			);
			let formattedEndDate = moment(new Date(retrievalDates.queryEndDate))
				.endOf('day')
				.format('YYYY/MM/DD HH:mm:ss');

			this.theme = Global.Theme;
			this.bodyTextColor =
				this.theme === 'dark'
					? 'white'
					: this.sassHelper?.readProperty('body-text-color-dark');

			if (this.widgetObject.WidgetGateSystemId == undefined) {
				Global.User.DebugMode &&
					console.log(
						'WidgetGateSystemId undefined:',
						this.widgetObject
					);
			}

			let hasAHU = this.dataService.cache.sitesObject[
				this.widgetObject.WidgetSiteId
			].Assets.some((a) => a.Name == 'AHU');
			let hasPCA = this.dataService.cache.sitesObject[
				this.widgetObject.WidgetSiteId
			].Assets.some((a) => a.Name == 'PCA');

			this.siteAirUnitName = 'PCA';
			if (hasAHU) {
				this.siteAirUnitName = 'AHU';
			}

			// write SQL statement
			// let statement = `GetPerfectTurnRecordsByGateSystemIdWithinTimescope @StartTime = '${formattedStartDate}', @EndTime = '${formattedEndDate}', @GateSystemId = ${this.widgetObject.WidgetGateSystemId}, @SiteId = ${this.widgetObject.WidgetSiteId}`;

			// this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			// 	if (data.length > 0) {
			// 		this.initializePerfectTurnDataCache();

			// 		data = _.orderBy(data, ['Id'], ['desc']);
			// 		//this.getCurrentTurnState(data[0]);

			// 		data.forEach((row, index) => {
			// 			if (index != 0) {
			// 				this.addHookupToHistory(row);
			// 				this.addDisconnectToHistory(row);
			// 			}
			// 		});
			// 		// this.isDataLoading = false;
			// 		// this.hasData = true;
			// 	} else {
			// 		this.isDataLoading = false;
			// 		this.hasData = false;
			// 	}
			// });


			this.initializeTurn();
			this.GetStatisticsFromSQLServer();
		}
	}

	getCurrentTurnState(dockToUndockRow) {
		this.currentDock = dockToUndockRow;
		//this.SetHookupProgress();

		// if (!this.countUpTimerInterval) {
		// 	this.countUpTimerInterval = setInterval(
		// 		() => this.SetHookupProgress(),
		// 		1000
		// 	);
		// }

		if (dockToUndockRow.PBBMoveStartMS) {
			this.hookupEventsMap.set('PBBMoveStart', {
				Name: 'PBB Driving',
				EventCode: 'PBBMoveStart',
				DateMS: dockToUndockRow.PBBMoveStartMS,
				DateLocal: new Date(dockToUndockRow.PBBMoveStartMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.PBBMoveStartMS,
					this.siteUTCTimeOffset
				),
				Type: 'Hookup',
			});
		}
		if (dockToUndockRow.PBBDockedMS) {
			this.hookupEventsMap.set('PBBDocked', {
				Name: 'PBB Docked',
				EventCode: 'PBBDocked',
				DateMS: dockToUndockRow.PBBDockedMS,
				DateLocal: new Date(dockToUndockRow.PBBDockedMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.PBBDockedMS,
					this.siteUTCTimeOffset
				),
				Type: 'Hookup',
			});
		}
		if (dockToUndockRow.PCAVentilatingAircraftMS) {
			this.hookupEventsMap.set('PCAOn', {
				Name: 'PCA Turned On',
				EventCode: 'PCAOn',
				DateMS: dockToUndockRow.PCAVentilatingAircraftMS,
				DateLocal: new Date(dockToUndockRow.PCAVentilatingAircraftMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.PCAVentilatingAircraftMS,
					this.siteUTCTimeOffset
				),
				Type: 'Hookup',
			});
		}
		if (dockToUndockRow.GPUOnMS) {
			this.hookupEventsMap.set('GPUOn', {
				Name: 'GPU Turned On',
				EventCode: 'GPUOn',
				DateMS: dockToUndockRow.GPUOnMS,
				DateLocal: new Date(dockToUndockRow.GPUOnMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.GPUOnMS,
					this.siteUTCTimeOffset
				),
				Type: 'Hookup',
			});
		}
		if (dockToUndockRow.GPUSwitchoverMS) {
			this.hookupEventsMap.set('GPUSwitchedOver', {
				Name: 'GPU Switched Over',
				EventCode: 'GPUSwitchedOver',
				DateMS: dockToUndockRow.GPUSwitchoverMS,
				DateLocal: new Date(dockToUndockRow.GPUSwitchoverMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.GPUSwitchoverMS,
					this.siteUTCTimeOffset
				),
				Type: 'Hookup',
			});
		}
		if (dockToUndockRow.PCAOFFMS) {
			if (dockToUndockRow.PCAOnMS == 0) {
				// Global.User.DebugMode &&
				// 	console.log(
				// 		'Info: PCA Off with no PCA On',
				// 		dockToUndockRow
				// 	);
			}
			this.hookupEventsMap.set('PCAOff', {
				Name: 'PCA Turned Off',
				EventCode: 'PCAOff',
				DateMS: dockToUndockRow.PCAOFFMS,
				DateLocal: new Date(dockToUndockRow.PCAOFFMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.PCAOFFMS,
					this.siteUTCTimeOffset
				),
				Type: 'Disconnect',
			});
		}
		if (dockToUndockRow.GPUOFFMS) {
			if (dockToUndockRow.GPUOnMS == 0) {
				// Global.User.DebugMode &&
				// 	console.log(
				// 		'Info: GPU Off with no GPU On',
				// 		dockToUndockRow
				// 	);
			}
			this.hookupEventsMap.set('GPUOff', {
				Name: 'GPU Turned Off',
				EventCode: 'GPUOff',
				DateMS: dockToUndockRow.GPUOFFMS,
				DateLocal: new Date(dockToUndockRow.GPUOFFMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.GPUOFFMS,
					this.siteUTCTimeOffset
				),
				Type: 'Disconnect',
			});
		}
		if (dockToUndockRow.PBBUndockedMS) {
			this.hookupEventsMap.set('PBBUndocked', {
				Name: 'PBB Undocked',
				EventCode: 'PBBUndocked',
				DateMS: dockToUndockRow.PBBUndockedMS,
				DateLocal: new Date(dockToUndockRow.PBBUndockedMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.PBBUndockedMS,
					this.siteUTCTimeOffset
				),
				Type: 'Disconnect',
			});
		}
		if (dockToUndockRow.PBBMoveAfterUndockMS) {
			this.hookupEventsMap.set('PBBMoveAway', {
				Name: 'PBB Driving Away',
				EventCode: 'PBBMoveAway',
				DateMS: dockToUndockRow.PBBMoveAfterUndockMS,
				DateLocal: new Date(dockToUndockRow.PBBMoveAfterUndockMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.PBBMoveAfterUndockMS,
					this.siteUTCTimeOffset
				),
				Type: 'Disconnect',
			});
		}

		this.hookupFlightInfo.AircraftType = dockToUndockRow?.AircraftType
			? dockToUndockRow?.AircraftType
			: 'N/A';
		this.hookupFlightInfo.AirlineCode = dockToUndockRow?.AirlineCode
			? dockToUndockRow?.AirlineCode
			: 'N/A';
		this.hookupFlightInfo.FlightNumber = dockToUndockRow?.FlightNumber
			? dockToUndockRow?.FlightNumber
			: 'N/A';
		this.hookupFlightInfo.TailNumber = dockToUndockRow?.TailNumber
			? dockToUndockRow?.TailNumber
			: 'N/A';
		this.hookupFlightInfo.Display =
			dockToUndockRow.AirlineCode && dockToUndockRow.FlightNumber
				? `${dockToUndockRow.AirlineCode}${dockToUndockRow.FlightNumber}-${dockToUndockRow.TailNumber}-${dockToUndockRow.AircraftType}`
				: 'N/A';

		// let startingSeriesType = dockToUndockRow.StartingEvent ? dockToUndockRow.StartingEvent.substring(0,3) : null;
		// let disconnectEventOccured = dockToUndockRow.PCAOFFMS || dockToUndockRow.GPUOFFMS || dockToUndockRow.PBBUndockedMS ? true : false;

		let firstEvent, secondEvent, thirdEvent;

		if (!this.hookupEventsMap.has('PBBUndocked')) {
			// Draw chart for hookup event if an Undock has not yet occcured
			let startingPbbEvent = this.hookupEventsMap.has('PBBMoveStart')
				? this.hookupEventsMap.get('PBBMoveStart')
				: this.hookupEventsMap.get('PBBDocked');
			let eventsArray = [
				{
					DateLocal: startingPbbEvent?.DateLocal,
					DateMS: startingPbbEvent?.DateMS,
					SeriesType: 'PBB',
					SeriesName: 'PBB Docked',
					EventCode: 'PBBDocked',
					Status: dockToUndockRow.PBBInoperable,
				},
				{
					DateLocal: this.hookupEventsMap.get('PCAOn')?.DateLocal,
					DateMS: this.hookupEventsMap.get('PCAOn')?.DateMS,
					SeriesType: 'PCA',
					EventCode: 'PCAOn',
					SeriesName: 'PCA Turned On',
					Status: dockToUndockRow.PCAInoperable,
				},
				{
					DateLocal: this.hookupEventsMap.get('GPUOn')?.DateLocal,
					DateMS: this.hookupEventsMap.get('GPUOn')?.DateMS,
					SeriesType: 'GPU',
					EventCode: 'GPUOn',
					SeriesName: 'GPU Turned On',
					Status: dockToUndockRow.GPUInoperable,
				},
			];

			// if (startingSeriesType) {
			// 	firstEvent = eventsArray.find(ev => ev.SeriesType == startingSeriesType);
			// 	let filteredEventsArray = eventsArray.filter(ev => ev.SeriesType != startingSeriesType);

			// 	filteredEventsArray = _.sortBy(filteredEventsArray, (ev) => ev.DateLocal);

			// 	secondEvent = filteredEventsArray[0];
			// 	thirdEvent = filteredEventsArray[1];
			// } else {
			eventsArray = _.sortBy(eventsArray, (ev) => ev.DateLocal);

			firstEvent = eventsArray[0];
			secondEvent = eventsArray[1];
			thirdEvent = eventsArray[2];
			// }

			let terminatingEventDateMS = this.hookupEventsMap.has(
				'GPUSwitchedOver'
			)
				? this.hookupEventsMap.get('GPUSwitchedOver').DateMS
				: undefined;

			if (!terminatingEventDateMS) {
				if (dockToUndockRow.PCAOFFMS && dockToUndockRow.GPUOFFMS) {
					terminatingEventDateMS = moment(
						dockToUndockRow.PCAOFFMS
					).isBefore(moment(dockToUndockRow.GPUOFFMS))
						? dockToUndockRow.PCAOFFMS
						: dockToUndockRow.GPUOFFMS;
				} else if (
					dockToUndockRow.PCAOFFMS &&
					!dockToUndockRow.GPUOFFMS
				) {
					terminatingEventDateMS = dockToUndockRow.PCAOFFMS;
				} else if (
					!dockToUndockRow.PCAOFFMS &&
					dockToUndockRow.GPUOFFMS
				) {
					terminatingEventDateMS = dockToUndockRow.GPUOFFMS;
				} else if (dockToUndockRow.PBBUndockedMS) {
					terminatingEventDateMS = dockToUndockRow.PBBUndockedMS;
				}
			}

			if (firstEvent.SeriesType == 'PBB') {
				// Typical Hookup Sequence
				this.HookupChartDefinition.SeriesOneData.push({
					name: 'PBB',
					eventName: 'PBB Docked',
					startTimeMS: firstEvent.DateMS,
					startTimeTxt: moment(firstEvent.DateMS).format(
						'hh:mm:ss a'
					),
					endTimeMS: undefined,
					endTimeTxt: 'In Progress',
					startTimeTxtSite: moment(
						this.utilityService.convertFromUtcToLocalToSite(
							firstEvent.DateMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a'),
					endTimeTxtSite: 'In Progress',
					limit: 20,
					overtime: 0,
					duration: 0,
					low: 0,
					high: 0,
					color: 'green',
					statusIcon: this.getIconURLForAsset(firstEvent.Status),
					timeZoneType: this.timeZoneType,
				});

				this.activeTimers.PBBDocked = true;

				let pbbDockedEvent = this.hookupEventsMap.get('PBBDocked');
				if (pbbDockedEvent) {
					this.HookupChartDefinition.SeriesOneData[0].endTimeMS =
						pbbDockedEvent.DateMS;
					this.HookupChartDefinition.SeriesOneData[0].endTimeTxt =
						moment(pbbDockedEvent.DateMS).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesOneData[0].endTimeTxtSite =
						moment(
							this.utilityService.convertFromUtcToLocalToSite(
								pbbDockedEvent.DateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesOneData[0].high = moment(
						moment(pbbDockedEvent.DateMS)
					).diff(moment(firstEvent.DateMS), 'seconds');
					this.HookupChartDefinition.SeriesOneData[0].duration =
						this.HookupChartDefinition.SeriesOneData[0].high -
						this.HookupChartDefinition.SeriesOneData[0].low;
					this.HookupChartDefinition.SeriesOneData[0].overtime =
						this.HookupChartDefinition.SeriesOneData[0].duration >
						this.HookupChartDefinition.SeriesOneData[0].limit
							? Math.abs(
									this.HookupChartDefinition.SeriesOneData[0]
										.duration -
										this.HookupChartDefinition
											.SeriesOneData[0].limit
							  )
							: 0;
					this.HookupChartDefinition.SeriesOneData[0].color =
						this.HookupChartDefinition.SeriesOneData[0].overtime ==
						0
							? 'green'
							: 'red';

					this.activeTimers.PBBDocked = false;
					this.activeTimers.GPUOn = true;
					this.activeTimers.PCAOn = true;
					this.aircraftDockedInterval = setInterval(
						() => this.updateAircraftDockedDuration(),
						10000
					);
				}

				this.HookupChartDefinition.SeriesOneData.push(
					{
						name: 'PCA',
						eventName: 'PCA Turned On',
						startTimeMS: firstEvent.DateMS,
						startTimeTxt: moment(firstEvent.DateMS).format(
							'hh:mm:ss a'
						),
						endTimeMS: undefined,
						endTimeTxt: 'In Progress',
						startTimeTxtSite: moment(
							this.utilityService.convertFromUtcToLocalToSite(
								firstEvent.DateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a'),
						endTimeTxtSite: 'In Progress',
						limit: 20,
						overtime: 0,
						duration: 0,
						low: pbbDockedEvent
							? this.HookupChartDefinition.SeriesOneData[0].high
							: null,
						high: null,
						color: 'green',
						statusIcon: this.getIconURLForAsset(
							dockToUndockRow.PCAInoperable
						),
						timeZoneType: this.timeZoneType,
					},
					{
						name: 'GPU',
						eventName: 'GPU Turned On',
						startTimeMS: firstEvent.DateMS,
						startTimeTxt: moment(firstEvent.DateMS).format(
							'hh:mm:ss a'
						),
						endTimeMS: undefined,
						endTimeTxt: 'In Progress',
						startTimeTxtSite: moment(
							this.utilityService.convertFromUtcToLocalToSite(
								firstEvent.DateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a'),
						endTimeTxtSite: 'In Progress',
						limit: 20,
						overtime: 0,
						duration: 0,
						low: pbbDockedEvent
							? this.HookupChartDefinition.SeriesOneData[0].high
							: null,
						high: null,
						color: 'green',
						statusIcon: this.getIconURLForAsset(
							dockToUndockRow.GPUInoperable
						),
						timeZoneType: this.timeZoneType,
					}
				);

				let pcaOnEvent = this.hookupEventsMap.get('PCAOn');
				if (pcaOnEvent || terminatingEventDateMS) {
					let endingEventDateMS = pcaOnEvent
						? pcaOnEvent.DateMS
						: terminatingEventDateMS;

					this.HookupChartDefinition.SeriesOneData[1].endTimeMS =
						endingEventDateMS;
					this.HookupChartDefinition.SeriesOneData[1].endTimeTxt =
						moment(endingEventDateMS).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesOneData[1].endTimeTxtSite =
						moment(
							this.utilityService.convertFromUtcToLocalToSite(
								endingEventDateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesOneData[1].high =
						this.HookupChartDefinition.SeriesOneData[1].low +
						moment(moment(endingEventDateMS)).diff(
							moment(firstEvent.DateMS),
							'seconds'
						);
					this.HookupChartDefinition.SeriesOneData[1].duration =
						this.HookupChartDefinition.SeriesOneData[1].high -
						this.HookupChartDefinition.SeriesOneData[1].low;
					this.HookupChartDefinition.SeriesOneData[1].overtime =
						this.HookupChartDefinition.SeriesOneData[1].duration >
						this.HookupChartDefinition.SeriesOneData[1].limit
							? Math.abs(
									this.HookupChartDefinition.SeriesOneData[1]
										.duration -
										this.HookupChartDefinition
											.SeriesOneData[1].limit
							  )
							: 0;
					this.HookupChartDefinition.SeriesOneData[1].color =
						this.HookupChartDefinition.SeriesOneData[1].overtime ==
						0
							? 'green'
							: 'red';

					this.activeTimers.PCAOn = false;
				}

				let gpuOnEvent = this.hookupEventsMap.get('GPUOn');
				if (gpuOnEvent || terminatingEventDateMS) {
					let endingEventDateMS = gpuOnEvent
						? gpuOnEvent.DateMS
						: terminatingEventDateMS;

					this.HookupChartDefinition.SeriesOneData[2].endTimeMS =
						endingEventDateMS;
					this.HookupChartDefinition.SeriesOneData[2].endTimeTxt =
						moment(endingEventDateMS).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesOneData[2].endTimeTxtSite =
						moment(
							this.utilityService.convertFromUtcToLocalToSite(
								endingEventDateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesOneData[2].high =
						this.HookupChartDefinition.SeriesOneData[2].low +
						moment(moment(endingEventDateMS)).diff(
							moment(firstEvent.DateMS),
							'seconds'
						);
					this.HookupChartDefinition.SeriesOneData[2].duration =
						this.HookupChartDefinition.SeriesOneData[2].high -
						this.HookupChartDefinition.SeriesOneData[2].low;
					this.HookupChartDefinition.SeriesOneData[2].overtime =
						this.HookupChartDefinition.SeriesOneData[2].duration >
						this.HookupChartDefinition.SeriesOneData[2].limit
							? Math.abs(
									this.HookupChartDefinition.SeriesOneData[2]
										.duration -
										this.HookupChartDefinition
											.SeriesOneData[2].limit
							  )
							: 0;
					this.HookupChartDefinition.SeriesOneData[2].color =
						this.HookupChartDefinition.SeriesOneData[2].overtime ==
						0
							? 'green'
							: 'red';

					this.activeTimers.GPUOn = false;
				}

				if (!terminatingEventDateMS || gpuOnEvent) {
					this.activeTimers.GPUSwitchedOver = true;
					this.HookupChartDefinition.SeriesTwoData.push({
						name: 'GPU',
						eventName: 'GPU Switched Over',
						startTimeMS: gpuOnEvent?.DateMS
							? gpuOnEvent.DateMS
							: undefined,
						startTimeTxt: gpuOnEvent?.DateMS
							? moment(gpuOnEvent.DateMS).format('hh:mm:ss a')
							: undefined,
						endTimeMS: undefined,
						endTimeTxt: 'In Progress',
						startTimeTxtSite: gpuOnEvent?.DateMS
							? moment(
									this.utilityService.convertFromUtcToLocalToSite(
										gpuOnEvent.DateMS,
										this.siteUTCTimeOffset
									)
							  ).format('hh:mm:ss a')
							: undefined,
						endTimeTxtSite: 'In Progress',
						limit: 20,
						overtime: 0,
						duration: 0,
						low: gpuOnEvent?.DateMS
							? this.HookupChartDefinition.SeriesOneData[2].high
							: null,
						high: null,
						color: 'green',
						timeZoneType: this.timeZoneType,
					});

					let gpuSwitchoverEvent =
						this.hookupEventsMap.get('GPUSwitchedOver');
					if (gpuSwitchoverEvent || terminatingEventDateMS) {
						let endingEventDateMS = gpuSwitchoverEvent
							? gpuSwitchoverEvent.DateMS
							: terminatingEventDateMS;

						this.HookupChartDefinition.SeriesTwoData[0].endTimeMS =
							moment(endingEventDateMS).valueOf();
						this.HookupChartDefinition.SeriesTwoData[0].endTimeTxt =
							moment(endingEventDateMS).format('hh:mm:ss a');
						this.HookupChartDefinition.SeriesTwoData[0].endTimeTxtSite =
							moment(
								this.utilityService.convertFromUtcToLocalToSite(
									endingEventDateMS,
									this.siteUTCTimeOffset
								)
							).format('hh:mm:ss a');
						this.HookupChartDefinition.SeriesTwoData[0].high =
							this.HookupChartDefinition.SeriesTwoData[0].low +
							moment(moment(endingEventDateMS)).diff(
								moment(gpuOnEvent.DateMS),
								'seconds'
							);
						this.HookupChartDefinition.SeriesTwoData[0].duration =
							this.HookupChartDefinition.SeriesTwoData[0].high -
							this.HookupChartDefinition.SeriesTwoData[0].low;
						this.HookupChartDefinition.SeriesTwoData[0].overtime =
							this.HookupChartDefinition.SeriesTwoData[0]
								.duration >
							this.HookupChartDefinition.SeriesTwoData[0].limit
								? Math.abs(
										this.HookupChartDefinition
											.SeriesTwoData[0].duration -
											this.HookupChartDefinition
												.SeriesTwoData[0].limit
								  )
								: 0;
						this.HookupChartDefinition.SeriesTwoData[0].color =
							this.HookupChartDefinition.SeriesTwoData[0]
								.overtime == 0
								? 'green'
								: 'red';

						this.activeTimers.GPUSwitchedOver = false;
					}
				}
			} else {
				// Equipment hooked up before PBB Docked
				let artificialStartTimeMS = moment(firstEvent.DateMS)
					.subtract(10, 'seconds')
					.valueOf();
				this.HookupChartDefinition.SeriesOneData.push({
					name: firstEvent.SeriesType,
					eventName: firstEvent.SeriesName,
					startTimeMS: artificialStartTimeMS,
					startTimeTxt: moment(artificialStartTimeMS).format(
						'hh:mm:ss a'
					),
					endTimeMS: undefined,
					endTimeTxt: 'In Progress',
					startTimeTxtSite: moment(
						this.utilityService.convertFromUtcToLocalToSite(
							artificialStartTimeMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a'),
					endTimeTxtSite: 'In Progress',
					limit: 20,
					overtime: 0,
					duration: 0,
					low: 0,
					high: 0,
					color: 'green',
					statusIcon: this.getIconURLForAsset(firstEvent.Status),
					timeZoneType: this.timeZoneType,
				});

				this.HookupChartDefinition.SeriesOneData[0].endTimeMS =
					firstEvent.DateMS;
				this.HookupChartDefinition.SeriesOneData[0].endTimeTxt = moment(
					firstEvent.DateMS
				).format('hh:mm:ss a');
				(this.HookupChartDefinition.SeriesOneData[0].endTimeTxtSite =
					moment(
						this.utilityService.convertFromUtcToLocalToSite(
							firstEvent.DateMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a')),
					(this.HookupChartDefinition.SeriesOneData[0].high = moment(
						moment(firstEvent.DateMS)
					).diff(moment(artificialStartTimeMS), 'seconds'));
				this.HookupChartDefinition.SeriesOneData[0].duration =
					this.HookupChartDefinition.SeriesOneData[0].high -
					this.HookupChartDefinition.SeriesOneData[0].low;
				this.HookupChartDefinition.SeriesOneData[0].overtime =
					this.HookupChartDefinition.SeriesOneData[0].duration >
					this.HookupChartDefinition.SeriesOneData[0].limit
						? Math.abs(
								this.HookupChartDefinition.SeriesOneData[0]
									.duration -
									this.HookupChartDefinition.SeriesOneData[0]
										.limit
						  )
						: 0;
				this.HookupChartDefinition.SeriesOneData[0].color =
					this.HookupChartDefinition.SeriesOneData[0].overtime == 0
						? 'green'
						: 'red';

				this.activeTimers[secondEvent.EventCode] = true;
				this.activeTimers[thirdEvent.EventCode] = true;

				this.HookupChartDefinition.SeriesOneData.push(
					{
						name: secondEvent.SeriesType,
						eventName: secondEvent.SeriesName,
						startTimeMS: firstEvent?.DateMS
							? firstEvent.DateMS
							: undefined,
						startTimeTxt: firstEvent?.DateMS
							? moment(firstEvent.DateMS).format('hh:mm:ss a')
							: undefined,
						endTimeMS: undefined,
						endTimeTxt: 'In Progress',
						startTimeTxtSite: firstEvent?.DateMS
							? moment(
									this.utilityService.convertFromUtcToLocalToSite(
										firstEvent.DateMS,
										this.siteUTCTimeOffset
									)
							  ).format('hh:mm:ss a')
							: undefined,
						endTimeTxtSite: 'In Progress',
						limit: 20,
						overtime: 0,
						duration: 0,
						low: firstEvent?.DateMS
							? this.HookupChartDefinition.SeriesOneData[0].high
							: null,
						high: null,
						color: 'green',
						statusIcon: this.getIconURLForAsset(secondEvent.Status),
						timeZoneType: this.timeZoneType,
					},
					{
						name: thirdEvent.SeriesType,
						eventName: thirdEvent.SeriesName,
						startTimeMS: firstEvent?.DateMS
							? firstEvent.DateMS
							: undefined,
						startTimeTxt: firstEvent?.DateMS
							? moment(firstEvent.DateMS).format('hh:mm:ss a')
							: undefined,
						endTimeMS: undefined,
						endTimeTxt: 'In Progress',
						startTimeTxtSite: firstEvent?.DateMS
							? moment(
									this.utilityService.convertFromUtcToLocalToSite(
										firstEvent.DateMS,
										this.siteUTCTimeOffset
									)
							  ).format('hh:mm:ss a')
							: undefined,
						endTimeTxtSite: 'In Progress',
						limit: 20,
						overtime: 0,
						duration: 0,
						low: firstEvent?.DateMS
							? this.HookupChartDefinition.SeriesOneData[0].high
							: null,
						high: null,
						color: 'green',
						statusIcon: this.getIconURLForAsset(thirdEvent.Status),
						timeZoneType: this.timeZoneType,
					}
				);

				if (secondEvent.DateMS) {
					this.HookupChartDefinition.SeriesOneData[1].endTimeMS =
						secondEvent.DateMS;
					this.HookupChartDefinition.SeriesOneData[1].endTimeTxt =
						moment(secondEvent.DateMS).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesOneData[1].endTimeTxtSite =
						moment(
							this.utilityService.convertFromUtcToLocalToSite(
								secondEvent.DateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesOneData[1].high =
						this.HookupChartDefinition.SeriesOneData[1].low +
						moment(moment(secondEvent.DateMS)).diff(
							moment(firstEvent.DateMS),
							'seconds'
						);
					this.HookupChartDefinition.SeriesOneData[1].duration =
						this.HookupChartDefinition.SeriesOneData[1].high -
						this.HookupChartDefinition.SeriesOneData[1].low;
					this.HookupChartDefinition.SeriesOneData[1].overtime =
						this.HookupChartDefinition.SeriesOneData[1].duration >
						this.HookupChartDefinition.SeriesOneData[1].limit
							? Math.abs(
									this.HookupChartDefinition.SeriesOneData[1]
										.duration -
										this.HookupChartDefinition
											.SeriesOneData[1].limit
							  )
							: 0;
					this.HookupChartDefinition.SeriesOneData[1].color =
						this.HookupChartDefinition.SeriesOneData[1].overtime ==
						0
							? 'green'
							: 'red';

					this.activeTimers[secondEvent.EventCode] = false;
				}

				if (thirdEvent.DateMS) {
					this.HookupChartDefinition.SeriesOneData[2].endTimeMS =
						thirdEvent.DateMS;
					this.HookupChartDefinition.SeriesOneData[2].endTimeTxt =
						moment(thirdEvent.DateMS).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesOneData[2].endTimeTxtSite =
						moment(
							this.utilityService.convertFromUtcToLocalToSite(
								thirdEvent.DateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesOneData[2].high =
						this.HookupChartDefinition.SeriesOneData[2].low +
						moment(moment(thirdEvent.DateMS)).diff(
							moment(firstEvent.DateMS),
							'seconds'
						);
					this.HookupChartDefinition.SeriesOneData[2].duration =
						this.HookupChartDefinition.SeriesOneData[2].high -
						this.HookupChartDefinition.SeriesOneData[2].low;
					this.HookupChartDefinition.SeriesOneData[2].overtime =
						this.HookupChartDefinition.SeriesOneData[2].duration >
						this.HookupChartDefinition.SeriesOneData[2].limit
							? Math.abs(
									this.HookupChartDefinition.SeriesOneData[2]
										.duration -
										this.HookupChartDefinition
											.SeriesOneData[2].limit
							  )
							: 0;
					this.HookupChartDefinition.SeriesOneData[2].color =
						this.HookupChartDefinition.SeriesOneData[2].overtime ==
						0
							? 'green'
							: 'red';

					this.activeTimers[thirdEvent.EventCode] = false;
				}

				let gpuOnEvent = this.hookupEventsMap.get('GPUOn');
				this.HookupChartDefinition.SeriesTwoData.push({
					name: 'GPU',
					eventName: 'GPU Switched Over',
					startTimeMS: gpuOnEvent?.DateMS
						? gpuOnEvent.DateMS
						: undefined,
					startTimeTxt: gpuOnEvent?.DateMS
						? moment(gpuOnEvent.DateMS).format('hh:mm:ss a')
						: undefined,
					endTimeMS: undefined,
					endTimeTxt: 'In Progress',
					startTimeTxtSite: gpuOnEvent?.DateMS
						? moment(
								this.utilityService.convertFromUtcToLocalToSite(
									gpuOnEvent.DateMS,
									this.siteUTCTimeOffset
								)
						  ).format('hh:mm:ss a')
						: undefined,
					endTimeTxtSite: 'In Progress',
					limit: 20,
					overtime: 0,
					duration: 0,
					low: gpuOnEvent?.DateMS
						? this.HookupChartDefinition.SeriesOneData.find(
								(s) => s.name == 'GPU'
						  ).high
						: undefined,
					high: undefined,
					color: 'green',
					timeZoneType: this.timeZoneType,
				});

				this.activeTimers.GPUSwitchedOver = gpuOnEvent ? true : false;

				let gpuSwitchoverEvent =
					this.hookupEventsMap.get('GPUSwitchedOver');
				if (gpuSwitchoverEvent || terminatingEventDateMS) {
					let endingEventDateMS = gpuSwitchoverEvent
						? gpuSwitchoverEvent.DateMS
						: terminatingEventDateMS;

					this.HookupChartDefinition.SeriesTwoData[0].endTimeMS =
						moment(endingEventDateMS).valueOf();
					this.HookupChartDefinition.SeriesTwoData[0].endTimeTxt =
						moment(endingEventDateMS).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesTwoData[0].endTimeTxtSite =
						moment(
							this.utilityService.convertFromUtcToLocalToSite(
								endingEventDateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a');
					this.HookupChartDefinition.SeriesTwoData[0].high =
						this.HookupChartDefinition.SeriesTwoData[0].low +
						moment(moment(endingEventDateMS)).diff(
							moment(gpuOnEvent.DateMS),
							'seconds'
						);
					this.HookupChartDefinition.SeriesTwoData[0].duration =
						this.HookupChartDefinition.SeriesTwoData[0].high -
						this.HookupChartDefinition.SeriesTwoData[0].low;
					this.HookupChartDefinition.SeriesTwoData[0].overtime =
						this.HookupChartDefinition.SeriesTwoData[0].duration >
						this.HookupChartDefinition.SeriesTwoData[0].limit
							? Math.abs(
									this.HookupChartDefinition.SeriesTwoData[0]
										.duration -
										this.HookupChartDefinition
											.SeriesTwoData[0].limit
							  )
							: 0;
					this.HookupChartDefinition.SeriesTwoData[0].color =
						this.HookupChartDefinition.SeriesTwoData[0].overtime ==
						0
							? 'green'
							: 'red';

					this.activeTimers.GPUSwitchedOver = false;
				}
			}
			this.activeTimers.PBBDocked
				? (this.hookupStatus = 'Connecting')
				: (this.hookupStatus = 'Connected');
			this.createHookupChart();
		} else {
			// Draw chart for disconnect event
			this.addHookupToHistory(dockToUndockRow);

			let eventsArray = [
				{
					DateLocal: this.hookupEventsMap.get('GPUOff')?.DateLocal,
					DateMS: this.hookupEventsMap.get('GPUOff')?.DateMS,
					SeriesType: 'GPU',
					SeriesName: 'GPU Turned Off',
					EventCode: 'GPUOff',
					Status: dockToUndockRow.GPUInoperable,
				},
				{
					DateLocal: this.hookupEventsMap.get('PCAOff')?.DateLocal,
					DateMS: this.hookupEventsMap.get('PCAOff')?.DateMS,
					SeriesType: 'PCA',
					SeriesName: 'PCA Turned Off',
					EventCode: 'PCAOff',
					Status: dockToUndockRow.PCAInoperable,
				},
				{
					DateLocal:
						this.hookupEventsMap.get('PBBUndocked')?.DateLocal,
					DateMS: this.hookupEventsMap.get('PBBUndocked')?.DateMS,
					SeriesType: 'PBB',
					SeriesName: 'PBB Undocked',
					EventCode: 'PBBUndocked',
					Status: dockToUndockRow.PBBInoperable,
				},
			];

			eventsArray = _.sortBy(eventsArray, (ev) => ev.DateLocal);

			let secondEvent = eventsArray[0];
			let thirdEvent = eventsArray[1];
			let fourthEvent = eventsArray[2];
			// let firstEventDate = moment(secondEvent.DateLocal).subtract(10, "seconds").toDate();

			if (
				secondEvent.SeriesType == 'PBB' &&
				!this.hookupEventsMap.has('PCAOn') &&
				!this.hookupEventsMap.has('GPUOn')
			) {
				// Only PBB Events
				this.DisconnectChartDefinition.SeriesData.push({
					name: 'PBB',
					eventName: 'PBB Undocked',
					startTimeMS: secondEvent.DateMS,
					startTimeTxt: moment(secondEvent.DateMS).format(
						'hh:mm:ss a'
					),
					endTimeMS: undefined,
					endTimeTxt: 'In Progress',
					startTimeTxtSite: moment(
						this.utilityService.convertFromUtcToLocalToSite(
							secondEvent.DateMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a'),
					endTimeTxtSite: 'In Progress',
					limit: 20,
					overtime: 0,
					duration: 0,
					low: 0,
					high: undefined,
					color: 'green',
					statusIcon: this.getIconURLForAsset(secondEvent.Status),
					timeZoneType: this.timeZoneType,
				});

				this.activeTimers.PBBUndocked = true;

				let terminatingEventDateMS = this.hookupEventsMap.has(
					'PBBMoveAway'
				)
					? this.hookupEventsMap.get('PBBMoveAway').DateMS
					: this.hookupEventsMap.get('PBBUndocked').DateMS;
				if (terminatingEventDateMS) {
					this.DisconnectChartDefinition.SeriesData[0].endTimeMS =
						terminatingEventDateMS;
					this.DisconnectChartDefinition.SeriesData[0].endTimeTxt =
						moment(terminatingEventDateMS).format('hh:mm:ss a');
					this.DisconnectChartDefinition.SeriesData[0].endTimeTxtSite =
						moment(
							this.utilityService.convertFromUtcToLocalToSite(
								terminatingEventDateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a');
					this.DisconnectChartDefinition.SeriesData[0].high = moment(
						terminatingEventDateMS
					).diff(moment(secondEvent.DateMS), 'seconds');
					this.DisconnectChartDefinition.SeriesData[0].duration =
						this.DisconnectChartDefinition.SeriesData[0].high -
						this.DisconnectChartDefinition.SeriesData[0].low;
					this.DisconnectChartDefinition.SeriesData[0].overtime =
						this.DisconnectChartDefinition.SeriesData[0].duration >
						this.DisconnectChartDefinition.SeriesData[0].limit
							? Math.abs(
									this.DisconnectChartDefinition.SeriesData[0]
										.duration -
										this.DisconnectChartDefinition
											.SeriesData[0].limit
							  )
							: 0;
					this.DisconnectChartDefinition.SeriesData[0].color =
						this.DisconnectChartDefinition.SeriesData[0].overtime ==
						0
							? 'green'
							: 'red';

					this.activeTimers.PBBUndocked = false;
				}
			} else {
				this.DisconnectChartDefinition.SeriesData.push({
					name: secondEvent.SeriesType,
					eventName: secondEvent.SeriesName,
					startTimeMS: secondEvent.DateMS,
					startTimeTxt: moment(secondEvent.DateMS).format(
						'hh:mm:ss a'
					),
					endTimeMS: moment(secondEvent.DateLocal).valueOf(),
					endTimeTxt: moment(secondEvent.DateLocal).format(
						'hh:mm:ss a'
					),
					startTimeTxtSite: moment(
						this.utilityService.convertFromUtcToLocalToSite(
							secondEvent.DateMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a'),
					endTimeTxtSite: moment(
						this.utilityService.convertFromUtcToLocalToSite(
							secondEvent.DateMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a'),
					limit: 20,
					overtime: 0,
					duration: 0,
					low: 0,
					high: undefined,
					color: 'green',
					statusIcon: this.getIconURLForAsset(secondEvent.Status),
					timeZoneType: this.timeZoneType,
				});

				this.DisconnectChartDefinition.SeriesData[0].high = moment(
					moment(secondEvent.DateLocal)
				).diff(moment(secondEvent.DateMS), 'seconds');
				this.DisconnectChartDefinition.SeriesData[0].duration =
					this.DisconnectChartDefinition.SeriesData[0].high -
					this.DisconnectChartDefinition.SeriesData[0].low;
				this.DisconnectChartDefinition.SeriesData[0].overtime =
					this.DisconnectChartDefinition.SeriesData[0].duration >
					this.DisconnectChartDefinition.SeriesData[0].limit
						? Math.abs(
								this.DisconnectChartDefinition.SeriesData[0]
									.duration -
									this.DisconnectChartDefinition.SeriesData[0]
										.limit
						  )
						: 0;
				this.DisconnectChartDefinition.SeriesData[0].color =
					this.DisconnectChartDefinition.SeriesData[0].overtime == 0
						? 'green'
						: 'red';

				if (
					thirdEvent.SeriesType == 'GPU' ||
					thirdEvent.SeriesType == 'PCA' ||
					thirdEvent.SeriesType == 'PBB'
				) {
					this.activeTimers[thirdEvent.EventCode] = true;
					this.DisconnectChartDefinition.SeriesData.push({
						name: thirdEvent.SeriesType,
						eventName: thirdEvent.SeriesName,
						startTimeMS: secondEvent.DateMS,
						startTimeTxt: moment(secondEvent.DateMS).format(
							'hh:mm:ss a'
						),
						endTimeMS: undefined,
						endTimeTxt: 'In Progress',
						startTimeTxtSite: moment(
							this.utilityService.convertFromUtcToLocalToSite(
								secondEvent.DateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a'),
						endTimeTxtSite: 'In Progress',
						limit: 20,
						overtime: 0,
						duration: 0,
						low: this.DisconnectChartDefinition.SeriesData[0].high,
						high: undefined,
						color: 'green',
						statusIcon: this.getIconURLForAsset(thirdEvent.Status),
						timeZoneType: this.timeZoneType,
					});
				}

				if (
					fourthEvent.SeriesType == 'GPU' ||
					fourthEvent.SeriesType == 'PCA' ||
					fourthEvent.SeriesType == 'PBB'
				) {
					this.activeTimers[fourthEvent.EventCode] = true;
					this.DisconnectChartDefinition.SeriesData.push({
						name: fourthEvent.SeriesType,
						eventName: fourthEvent.SeriesName,
						startTimeMS: secondEvent.DateMS,
						startTimeTxt: moment(secondEvent.DateMS).format(
							'hh:mm:ss a'
						),
						endTimeMS: undefined,
						endTimeTxt: 'In Progress',
						startTimeTxtSite: moment(
							this.utilityService.convertFromUtcToLocalToSite(
								secondEvent.DateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a'),
						endTimeTxtSite: 'In Progress',
						limit: 20,
						overtime: 0,
						duration: 0,
						low: this.DisconnectChartDefinition.SeriesData[0].high,
						high: undefined,
						color: 'green',
						statusIcon: this.getIconURLForAsset(fourthEvent.Status),
						timeZoneType: this.timeZoneType,
					});
				}

				if (thirdEvent.DateLocal) {
					this.DisconnectChartDefinition.SeriesData[1].endTimeMS =
						thirdEvent.DateMS;
					this.DisconnectChartDefinition.SeriesData[1].endTimeTxt =
						moment(thirdEvent.DateMS).format('hh:mm:ss a');
					this.DisconnectChartDefinition.SeriesData[1].endTimeTxtSite =
						moment(
							this.utilityService.convertFromUtcToLocalToSite(
								thirdEvent.DateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a');
					this.DisconnectChartDefinition.SeriesData[1].high =
						this.DisconnectChartDefinition.SeriesData[1].low +
						moment(thirdEvent.DateLocal).diff(
							moment(secondEvent.DateLocal),
							'seconds'
						);
					this.DisconnectChartDefinition.SeriesData[1].duration =
						this.DisconnectChartDefinition.SeriesData[1].high -
						this.DisconnectChartDefinition.SeriesData[1].low;
					this.DisconnectChartDefinition.SeriesData[1].overtime =
						this.DisconnectChartDefinition.SeriesData[1].duration >
						this.DisconnectChartDefinition.SeriesData[1].limit
							? Math.abs(
									this.DisconnectChartDefinition.SeriesData[1]
										.duration -
										this.DisconnectChartDefinition
											.SeriesData[1].limit
							  )
							: 0;
					this.DisconnectChartDefinition.SeriesData[1].color =
						this.DisconnectChartDefinition.SeriesData[1].overtime ==
						0
							? 'green'
							: 'red';

					this.activeTimers[thirdEvent.EventCode] = false;
				}

				if (fourthEvent.DateLocal) {
					this.DisconnectChartDefinition.SeriesData[2].endTimeMS =
						fourthEvent.DateMS;
					this.DisconnectChartDefinition.SeriesData[2].endTimeTxt =
						moment(fourthEvent.DateMS).format('hh:mm:ss a');
					this.DisconnectChartDefinition.SeriesData[2].endTimeTxtSite =
						moment(
							this.utilityService.convertFromUtcToLocalToSite(
								fourthEvent.DateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a');
					this.DisconnectChartDefinition.SeriesData[2].high =
						this.DisconnectChartDefinition.SeriesData[2].low +
						moment(fourthEvent.DateLocal).diff(
							moment(thirdEvent.DateLocal),
							'seconds'
						);
					this.DisconnectChartDefinition.SeriesData[2].duration =
						this.DisconnectChartDefinition.SeriesData[2].high -
						this.DisconnectChartDefinition.SeriesData[2].low;
					this.DisconnectChartDefinition.SeriesData[2].overtime =
						this.DisconnectChartDefinition.SeriesData[2].duration >
						this.DisconnectChartDefinition.SeriesData[2].limit
							? Math.abs(
									this.DisconnectChartDefinition.SeriesData[2]
										.duration -
										this.DisconnectChartDefinition
											.SeriesData[2].limit
							  )
							: 0;
					this.DisconnectChartDefinition.SeriesData[2].color =
						this.DisconnectChartDefinition.SeriesData[2].overtime ==
						0
							? 'green'
							: 'red';

					this.activeTimers[fourthEvent.EventCode] = false;
				}
			}

			this.activeTimers.PBBUndocked
				? (this.hookupStatus = 'Connected')
				: (this.hookupStatus = 'Disconnected');
			if (this.hookupStatus == 'Connected') {
				//this.createHookupChart();
			} else {
				//this.createDisconnectChart();
			}
		}

		this.updateAircraftDockedDuration();
		this.hookupEventTimeline = _.orderBy(
			Array.from(this.hookupEventsMap.values()),
			['DateLocal'],
			['asc']
		);
		this.isHookupActive =
			(this.hookupEventsMap.has('PBBUndocked') &&
				this.hookupStatus != 'Disconnected') ||
			Object.values(this.activeTimers).some((timer) => Boolean(timer));
		if (this.isHookupActive) {
			this.hookupTimerInterval = setInterval(
				() => this.updateTimers(),
				1000
			);
		}

		this.updateHookupTimer();
		this.widgetObject.isDisplayDataLive = true;
		this.changeColumnsForTimeZone();
	}

	getIconURLForAsset(statusText: String) {
		switch (statusText) {
			case 'SERVICE':
				return Global.imagesUrl + 'assets/img/out-of-service.svg';

			case 'MAINTENANCE':
				return Global.imagesUrl + 'assets/img/maintenance-mode.svg';

			case 'COMMS':
				return Global.imagesUrl + 'assets/img/comms-loss.svg';

			default:
				return '';
		}
	}

	addHookupToHistory(dockToUndockRow: any) {
		let lastEvent = undefined;
		let terminatingEventDateMS = undefined;
		let innerChartTextColor =
			this.theme === 'dark'
				? 'white'
				: this.sassHelper.readProperty('body-text-color-dark');
		let outerChartTextColor =
			this.theme === 'dark'
				? this.sassHelper.readProperty('body-text-color-light')
				: this.sassHelper.readProperty('body-text-color-dark');
		let chartDef = {
			SeriesOneData: [],
			SeriesTwoData: [],
			Title: '',
			Options: {},
			Subtitle: '',
		};

		let mainEventsArray = [
			{
				DateMS: dockToUndockRow.PBBDockedMS,
				SeriesType: 'PBB',
				SeriesName: 'PBB Docked',
				EventCode: 'PBBDocked',
				Status: dockToUndockRow.PBBInoperable,
			},
			{
				DateMS: dockToUndockRow.PCAVentilatingAircraftMS,
				SeriesType: 'PCA',
				EventCode: 'PCAOn',
				SeriesName: 'PCA Turned On',
				Status: dockToUndockRow.PCAInoperable,
			},
			{
				DateMS: dockToUndockRow.GPUOnMS,
				SeriesType: 'GPU',
				EventCode: 'GPUOn',
				SeriesName: 'GPU Turned On',
				Status: dockToUndockRow.GPUInoperable,
			},
		];
		let secondaryEvents = [
			{
				DateMS: dockToUndockRow.PBBMoveStartMS,
				SeriesType: 'PBB',
				SeriesName: 'PBB Docked',
				EventCode: 'PBBMoveStart',
				Status: dockToUndockRow.PBBInoperable,
			},
			{
				DateMS: dockToUndockRow.GPUSwitchoverMS,
				SeriesType: 'GPU',
				EventCode: 'GPUSwitchedOver',
				SeriesName: 'GPU Switched Over',
				Status: dockToUndockRow.GPUInoperable,
			},
		];

		mainEventsArray = _.sortBy(mainEventsArray, (ev) => ev.DateMS);
		let firstEvent = mainEventsArray[0];
		let secondEvent = mainEventsArray[1];
		let thirdEvent = mainEventsArray[2];

		if (dockToUndockRow.GPUSwitchoverMS) {
			terminatingEventDateMS = dockToUndockRow.GPUSwitchoverMS;
		} else {
			terminatingEventDateMS = undefined;
			if (dockToUndockRow.PCAOFFMS && dockToUndockRow.GPUOFFMS) {
				terminatingEventDateMS = moment(
					dockToUndockRow.PCAOFFMS
				).isBefore(moment(dockToUndockRow.GPUOFFMS))
					? dockToUndockRow.PCAOFFMS
					: dockToUndockRow.GPUOFFMS;
			} else if (dockToUndockRow.PCAOFFMS && !dockToUndockRow.GPUOFFMS) {
				terminatingEventDateMS = dockToUndockRow.PCAOFFMS;
			} else if (!dockToUndockRow.PCAOFFMS && dockToUndockRow.GPUOFFMS) {
				terminatingEventDateMS = dockToUndockRow.GPUOFFMS;
			} else if (dockToUndockRow.PBBUndockedMS) {
				terminatingEventDateMS = dockToUndockRow.PBBUndockedMS;
			}
		}

		if (!terminatingEventDateMS) {
			return;
		}

		let startingPbbEvent = dockToUndockRow.PBBMoveStartMS
			? secondaryEvents.find((ev) => ev.EventCode == 'PBBMoveStart')
			: mainEventsArray.find((ev) => ev.EventCode == 'PBBDocked');

		if (firstEvent.SeriesType == 'PBB') {
			chartDef.SeriesOneData.push({
				name: 'PBB',
				eventName: 'PBB Docked',
				startTimeMS: startingPbbEvent.DateMS,
				startTimeTxt: moment(startingPbbEvent.DateMS).format(
					'hh:mm:ss a'
				),
				endTimeMS: undefined,
				endTimeTxt: 'In Progress',
				startTimeTxtSite: moment(
					this.utilityService.convertFromUtcToLocalToSite(
						startingPbbEvent.DateMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a'),
				endTimeTxtSite: 'In Progress',
				limit: 20,
				overtime: 0,
				duration: 0,
				low: 0,
				high: undefined,
				color: 'green',
				statusIcon: this.getIconURLForAsset(
					dockToUndockRow.PBBInoperable
				),
				timeZoneType: this.timeZoneType,
			});

			let pbbDockedEvent = mainEventsArray.find(
				(ev) => ev.EventCode == 'PBBDocked'
			);
			if (pbbDockedEvent) {
				chartDef.SeriesOneData[0].endTimeMS = pbbDockedEvent.DateMS;
				chartDef.SeriesOneData[0].endTimeTxt = moment(
					pbbDockedEvent.DateMS
				).format('hh:mm:ss a');
				chartDef.SeriesOneData[0].endTimeTxtSite = moment(
					this.utilityService.convertFromUtcToLocalToSite(
						pbbDockedEvent.DateMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a');
				chartDef.SeriesOneData[0].high = moment(
					moment(pbbDockedEvent.DateMS)
				).diff(moment(startingPbbEvent.DateMS), 'seconds');
				chartDef.SeriesOneData[0].duration =
					chartDef.SeriesOneData[0].high -
					chartDef.SeriesOneData[0].low;
				chartDef.SeriesOneData[0].overtime =
					chartDef.SeriesOneData[0].duration >
					chartDef.SeriesOneData[0].limit
						? Math.abs(
								chartDef.SeriesOneData[0].duration -
									chartDef.SeriesOneData[0].limit
						  )
						: 0;
				chartDef.SeriesOneData[0].color =
					chartDef.SeriesOneData[0].overtime == 0 ? 'green' : 'red';

				if (secondEvent.DateMS || thirdEvent.DateMS) {
					chartDef.SeriesOneData.push(
						{
							name: 'PCA',
							eventName: 'PCA Turned On',
							startTimeMS: pbbDockedEvent.DateMS,
							startTimeTxt: moment(pbbDockedEvent.DateMS).format(
								'hh:mm:ss a'
							),
							endTimeMS: undefined,
							endTimeTxt: 'In Progress',
							startTimeTxtSite: moment(
								this.utilityService.convertFromUtcToLocalToSite(
									pbbDockedEvent.DateMS,
									this.siteUTCTimeOffset
								)
							).format('hh:mm:ss a'),
							endTimeTxtSite: 'In Progress',
							limit: 25,
							overtime: 0,
							duration: 0,
							low: chartDef.SeriesOneData[0].high,
							high: undefined,
							color: 'green',
							statusIcon: this.getIconURLForAsset(
								dockToUndockRow.PCAInoperable
							),
							timeZoneType: this.timeZoneType,
						},
						{
							name: 'GPU',
							eventName: 'GPU Turned On',
							startTimeMS: pbbDockedEvent.DateMS,
							startTimeTxt: moment(pbbDockedEvent.DateMS).format(
								'hh:mm:ss a'
							),
							endTimeMS: undefined,
							endTimeTxt: 'In Progress',
							startTimeTxtSite: moment(
								this.utilityService.convertFromUtcToLocalToSite(
									pbbDockedEvent.DateMS,
									this.siteUTCTimeOffset
								)
							).format('hh:mm:ss a'),
							endTimeTxtSite: 'In Progress',
							limit: 25,
							overtime: 0,
							duration: 0,
							low: chartDef.SeriesOneData[0].high,
							high: undefined,
							color: 'green',
							statusIcon: this.getIconURLForAsset(
								dockToUndockRow.GPUInoperable
							),
							timeZoneType: this.timeZoneType,
						}
					);

					let pcaOnEvent = mainEventsArray.find(
						(ev) => ev.EventCode == 'PCAOn'
					);
					if (pcaOnEvent.DateMS || terminatingEventDateMS) {
						let endingPCAEventDateMS = pcaOnEvent.DateMS
							? pcaOnEvent.DateMS
							: terminatingEventDateMS;

						chartDef.SeriesOneData[1].endTimeMS =
							endingPCAEventDateMS;
						chartDef.SeriesOneData[1].endTimeTxt =
							moment(endingPCAEventDateMS).format('hh:mm:ss a');
						chartDef.SeriesOneData[1].endTimeTxtSite = moment(
							this.utilityService.convertFromUtcToLocalToSite(
								endingPCAEventDateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a');
						chartDef.SeriesOneData[1].high =
							chartDef.SeriesOneData[1].low +
							moment(moment(endingPCAEventDateMS)).diff(
								moment(pbbDockedEvent.DateMS),
								'seconds'
							);
						chartDef.SeriesOneData[1].duration =
							chartDef.SeriesOneData[1].high -
							chartDef.SeriesOneData[1].low;
						chartDef.SeriesOneData[1].overtime =
							chartDef.SeriesOneData[1].duration >
							chartDef.SeriesOneData[1].limit
								? Math.abs(
										chartDef.SeriesOneData[1].duration -
											chartDef.SeriesOneData[1].limit
								  )
								: 0;
						chartDef.SeriesOneData[1].color =
							chartDef.SeriesOneData[1].overtime == 0
								? 'green'
								: 'red';
					}

					let gpuOnEvent = mainEventsArray.find(
						(ev) => ev.EventCode == 'GPUOn'
					);
					if (gpuOnEvent.DateMS || terminatingEventDateMS) {
						let endingGPUEventDateMS = gpuOnEvent.DateMS
							? gpuOnEvent.DateMS
							: terminatingEventDateMS;

						chartDef.SeriesOneData[2].endTimeMS =
							endingGPUEventDateMS;
						chartDef.SeriesOneData[2].endTimeTxt =
							moment(endingGPUEventDateMS).format('hh:mm:ss a');
						chartDef.SeriesOneData[2].endTimeTxtSite = moment(
							this.utilityService.convertFromUtcToLocalToSite(
								endingGPUEventDateMS,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a');
						chartDef.SeriesOneData[2].high =
							chartDef.SeriesOneData[2].low +
							moment(moment(endingGPUEventDateMS)).diff(
								moment(pbbDockedEvent.DateMS),
								'seconds'
							);
						chartDef.SeriesOneData[2].duration =
							chartDef.SeriesOneData[2].high -
							chartDef.SeriesOneData[2].low;
						chartDef.SeriesOneData[2].overtime =
							chartDef.SeriesOneData[2].duration >
							chartDef.SeriesOneData[2].limit
								? Math.abs(
										chartDef.SeriesOneData[2].duration -
											chartDef.SeriesOneData[2].limit
								  )
								: 0;
						chartDef.SeriesOneData[2].color =
							chartDef.SeriesOneData[2].overtime == 0
								? 'green'
								: 'red';

						if (gpuOnEvent.DateMS) {
							chartDef.SeriesTwoData.push({
								name: 'GPU',
								eventName: 'GPU Switched Over',
								startTimeMS: gpuOnEvent.DateMS,
								startTimeTxt: moment(gpuOnEvent.DateMS).format(
									'hh:mm:ss a'
								),
								endTimeMS: undefined,
								endTimeTxt: 'In Progress',
								startTimeTxtSite: moment(
									this.utilityService.convertFromUtcToLocalToSite(
										gpuOnEvent.DateMS,
										this.siteUTCTimeOffset
									)
								).format('hh:mm:ss a'),
								endTimeTxtSite: 'In Progress',
								limit: 15,
								overtime: 0,
								duration: 0,
								low: chartDef.SeriesOneData[2].high,
								high: undefined,
								color: 'green',
								timeZoneType: this.timeZoneType,
							});

							let gpuSwitchedOverEvent = secondaryEvents.find(
								(ev) => ev.EventCode == 'GPUSwitchedOver'
							);
							if (
								gpuSwitchedOverEvent.DateMS ||
								terminatingEventDateMS
							) {
								let gpuSwitchedOverDateMS =
									gpuSwitchedOverEvent.DateMS
										? gpuSwitchedOverEvent.DateMS
										: terminatingEventDateMS;

								chartDef.SeriesTwoData[0].endTimeMS =
									gpuSwitchedOverDateMS;
								chartDef.SeriesTwoData[0].endTimeTxt = moment(
									gpuSwitchedOverDateMS
								).format('hh:mm:ss a');
								chartDef.SeriesTwoData[0].endTimeTxtSite =
									moment(
										this.utilityService.convertFromUtcToLocalToSite(
											gpuSwitchedOverDateMS,
											this.siteUTCTimeOffset
										)
									).format('hh:mm:ss a');
								chartDef.SeriesTwoData[0].high =
									chartDef.SeriesTwoData[0].low +
									moment(moment(gpuSwitchedOverDateMS)).diff(
										moment(gpuOnEvent.DateMS),
										'seconds'
									);
								chartDef.SeriesTwoData[0].duration =
									chartDef.SeriesTwoData[0].high -
									chartDef.SeriesTwoData[0].low;
								chartDef.SeriesTwoData[0].overtime =
									chartDef.SeriesTwoData[0].duration >
									chartDef.SeriesTwoData[0].limit
										? Math.abs(
												chartDef.SeriesTwoData[0]
													.duration -
													chartDef.SeriesTwoData[0]
														.limit
										  )
										: 0;
								chartDef.SeriesTwoData[0].color =
									chartDef.SeriesTwoData[0].overtime == 0
										? 'green'
										: 'red';

								lastEvent = moment(
									chartDef.SeriesTwoData[0].endTimeMS
								).isAfter(
									moment(chartDef.SeriesOneData[1].endTimeMS)
								)
									? chartDef.SeriesTwoData[0]
									: chartDef.SeriesOneData[1];
							}
						}
					}
					if (!lastEvent) {
						lastEvent = moment(
							chartDef.SeriesOneData[1].endTimeMS
						).isAfter(moment(chartDef.SeriesOneData[2].endTimeMS))
							? chartDef.SeriesOneData[1]
							: chartDef.SeriesOneData[2];
					}
				} else {
					lastEvent = chartDef.SeriesOneData[0];
				}
			}
		} else {
			// Equipment hooked up before PBB Docked
			let artificialStartTimeMS = moment(firstEvent.DateMS)
				.subtract(10, 'seconds')
				.valueOf();
			chartDef.SeriesOneData.push({
				name: firstEvent.SeriesType,
				eventName: firstEvent.SeriesType,
				startTimeMS: artificialStartTimeMS,
				startTimeTxt: moment(artificialStartTimeMS).format(
					'hh:mm:ss a'
				),
				endTimeMS: undefined,
				endTimeTxt: 'In Progress',
				startTimeTxtSite: moment(
					this.utilityService.convertFromUtcToLocalToSite(
						artificialStartTimeMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a'),
				endTimeTxtSite: 'In Progress',
				limit: 20,
				overtime: 0,
				duration: 0,
				low: 0,
				high: undefined,
				color: 'green',
				statusIcon: this.getIconURLForAsset(firstEvent.Status),
				timeZoneType: this.timeZoneType,
			});

			chartDef.SeriesOneData[0].endTimeMS = firstEvent.DateMS;
			chartDef.SeriesOneData[0].endTimeTxt = moment(
				firstEvent.DateMS
			).format('hh:mm:ss a');
			chartDef.SeriesOneData[0].endTimeTxtSite = moment(
				this.utilityService.convertFromUtcToLocalToSite(
					firstEvent.DateMS,
					this.siteUTCTimeOffset
				)
			).format('hh:mm:ss a');
			chartDef.SeriesOneData[0].high = moment(
				moment(firstEvent.DateMS)
			).diff(moment(artificialStartTimeMS), 'seconds');
			chartDef.SeriesOneData[0].duration =
				chartDef.SeriesOneData[0].high - chartDef.SeriesOneData[0].low;
			chartDef.SeriesOneData[0].overtime =
				chartDef.SeriesOneData[0].duration >
				chartDef.SeriesOneData[0].limit
					? Math.abs(
							chartDef.SeriesOneData[0].duration -
								chartDef.SeriesOneData[0].limit
					  )
					: 0;
			chartDef.SeriesOneData[0].color =
				chartDef.SeriesOneData[0].overtime == 0 ? 'green' : 'red';

			chartDef.SeriesOneData.push(
				{
					name: secondEvent.SeriesType,
					eventName: secondEvent.SeriesName,
					startTimeMS: firstEvent.DateMS,
					startTimeTxt: moment(firstEvent.DateMS).format(
						'hh:mm:ss a'
					),
					endTimeMS: undefined,
					endTimeTxt: 'In Progress',
					startTimeTxtSite: moment(
						this.utilityService.convertFromUtcToLocalToSite(
							firstEvent.DateMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a'),
					endTimeTxtSite: 'In Progress',
					limit: 20,
					overtime: 0,
					duration: 0,
					low: chartDef.SeriesOneData[0].high,
					high: undefined,
					color: 'green',
					statusIcon: this.getIconURLForAsset(secondEvent.Status),
					timeZoneType: this.timeZoneType,
				},
				{
					name: thirdEvent.SeriesType,
					eventName: thirdEvent.SeriesName,
					startTimeMS: firstEvent.DateMS,
					startTimeTxt: moment(firstEvent.DateMS).format(
						'hh:mm:ss a'
					),
					endTimeMS: undefined,
					endTimeTxt: 'In Progress',
					startTimeTxtSite: moment(
						this.utilityService.convertFromUtcToLocalToSite(
							firstEvent.DateMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a'),
					endTimeTxtSite: 'In Progress',
					limit: 20,
					overtime: 0,
					duration: 0,
					low: chartDef.SeriesOneData[0].high,
					high: undefined,
					color: 'green',
					statusIcon: this.getIconURLForAsset(thirdEvent.Status),
					timeZoneType: this.timeZoneType,
				}
			);

			if (secondEvent.DateMS || terminatingEventDateMS) {
				let endingEventDateMS = thirdEvent.DateMS
					? thirdEvent.DateMS
					: terminatingEventDateMS;
				chartDef.SeriesOneData[1].endTimeMS = endingEventDateMS;
				chartDef.SeriesOneData[1].endTimeTxt =
					moment(endingEventDateMS).format('hh:mm:ss a');
				chartDef.SeriesOneData[1].endTimeTxtSite = moment(
					this.utilityService.convertFromUtcToLocalToSite(
						endingEventDateMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a');
				chartDef.SeriesOneData[1].high = moment(
					moment(endingEventDateMS)
				).diff(moment(firstEvent.DateMS), 'seconds');
				chartDef.SeriesOneData[1].duration =
					chartDef.SeriesOneData[1].high -
					chartDef.SeriesOneData[1].low;
				chartDef.SeriesOneData[1].overtime =
					chartDef.SeriesOneData[1].duration >
					chartDef.SeriesOneData[1].limit
						? Math.abs(
								chartDef.SeriesOneData[1].duration -
									chartDef.SeriesOneData[1].limit
						  )
						: 0;
				chartDef.SeriesOneData[1].color =
					chartDef.SeriesOneData[1].overtime == 0 ? 'green' : 'red';
			}

			if (thirdEvent.DateMS || terminatingEventDateMS) {
				let endingEventDateMS = thirdEvent.DateMS
					? thirdEvent.DateMS
					: terminatingEventDateMS;

				chartDef.SeriesOneData[2].endTimeMS = endingEventDateMS;
				chartDef.SeriesOneData[2].endTimeTxt =
					moment(endingEventDateMS).format('hh:mm:ss a');
				chartDef.SeriesOneData[2].endTimeTxtSite = moment(
					this.utilityService.convertFromUtcToLocalToSite(
						endingEventDateMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a');
				chartDef.SeriesOneData[2].high = moment(
					moment(endingEventDateMS)
				).diff(moment(firstEvent.DateMS), 'seconds');
				chartDef.SeriesOneData[2].duration =
					chartDef.SeriesOneData[2].high -
					chartDef.SeriesOneData[2].low;
				chartDef.SeriesOneData[2].overtime =
					chartDef.SeriesOneData[2].duration >
					chartDef.SeriesOneData[2].limit
						? Math.abs(
								chartDef.SeriesOneData[2].duration -
									chartDef.SeriesOneData[2].limit
						  )
						: 0;
				chartDef.SeriesOneData[2].color =
					chartDef.SeriesOneData[2].overtime == 0 ? 'green' : 'red';
			}

			lastEvent = moment(chartDef.SeriesOneData[1].endTimeMS).isAfter(
				moment(chartDef.SeriesOneData[2].endTimeMS)
			)
				? chartDef.SeriesOneData[1]
				: chartDef.SeriesOneData[2];

			let gpuOnEvent = mainEventsArray.find(
				(ev) => ev.EventCode == 'GPUOn'
			);
			if (gpuOnEvent.DateMS) {
				chartDef.SeriesTwoData.push({
					name: 'GPU',
					eventName: 'GPU Switched Over',
					startTimeMS: gpuOnEvent.DateMS,
					startTimeTxt: moment(gpuOnEvent.DateMS).format(
						'hh:mm:ss a'
					),
					endTimeMS: undefined,
					endTimeTxt: 'In Progress',
					startTimeTxtSite: moment(
						this.utilityService.convertFromUtcToLocalToSite(
							gpuOnEvent.DateMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a'),
					endTimeTxtSite: 'In Progress',
					limit: 20,
					overtime: 0,
					duration: 0,
					low: chartDef.SeriesOneData.find((s) => s.name == 'GPU')
						.high,
					high: undefined,
					color: 'green',
					timeZoneType: this.timeZoneType,
				});

				let gpuSwitchedOverEvent = secondaryEvents.find(
					(ev) => ev.EventCode == 'GPUSwitchedOver'
				);
				if (gpuSwitchedOverEvent.DateMS || terminatingEventDateMS) {
					let endingEventDateMS = gpuSwitchedOverEvent.DateMS
						? gpuSwitchedOverEvent.DateMS
						: terminatingEventDateMS;

					chartDef.SeriesTwoData[0].endTimeMS =
						moment(endingEventDateMS).valueOf();
					chartDef.SeriesTwoData[0].endTimeTxt =
						moment(endingEventDateMS).format('hh:mm:ss a');
					chartDef.SeriesTwoData[0].endTimeTxtSite = moment(
						this.utilityService.convertFromUtcToLocalToSite(
							endingEventDateMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a');
					chartDef.SeriesTwoData[0].high =
						chartDef.SeriesTwoData[0].low +
						moment(moment(endingEventDateMS)).diff(
							moment(gpuOnEvent.DateMS),
							'seconds'
						);
					chartDef.SeriesTwoData[0].duration =
						chartDef.SeriesTwoData[0].high -
						chartDef.SeriesTwoData[0].low;
					chartDef.SeriesTwoData[0].overtime =
						chartDef.SeriesTwoData[0].duration >
						chartDef.SeriesTwoData[0].limit
							? Math.abs(
									chartDef.SeriesTwoData[0].duration -
										chartDef.SeriesTwoData[0].limit
							  )
							: 0;
					chartDef.SeriesTwoData[0].color =
						chartDef.SeriesTwoData[0].overtime == 0
							? 'green'
							: 'red';

					lastEvent = moment(
						chartDef.SeriesTwoData[0].endTimeMS
					).isAfter(moment(chartDef.SeriesOneData[1].endTimeMS))
						? chartDef.SeriesTwoData[0]
						: chartDef.SeriesOneData[1];
				}
			}
		}

		// Build the Chart Object
		chartDef.SeriesOneData.push({
			name: 'Total',
			eventName: 'Summary',
			startTimeMS: chartDef.SeriesOneData[0].startTimeMS,
			startTimeTxt: chartDef.SeriesOneData[0].startTimeTxt,
			endTimeMS: terminatingEventDateMS,
			endTimeTxt: moment(terminatingEventDateMS).format('hh:mm:ss a'),
			startTimeTxtSite: chartDef.SeriesOneData[0].startTimeTxtSite,
			endTimeTxtSite: moment(
				this.utilityService.convertFromUtcToLocalToSite(
					terminatingEventDateMS,
					this.siteUTCTimeOffset
				)
			).format('hh:mm:ss a'),
			// overtime: chartDef.SeriesTwoData[0] ? _.sumBy(chartDef.SeriesOneData, "overtime") + chartDef.SeriesTwoData[0].overtime : _.sumBy(chartDef.SeriesOneData, "overtime"),
			overtime:
				lastEvent.duration > 300
					? Math.abs(300 - (lastEvent.duration + lastEvent.low))
					: 0,
			// duration: chartDef.SeriesTwoData[0] ? _.sumBy(chartDef.SeriesOneData, "duration") + chartDef.SeriesTwoData[0].duration : _.sumBy(chartDef.SeriesOneData, "duration"),
			duration: lastEvent.duration + lastEvent.low,
			limit: 300,
			low: 0,
			high: lastEvent.high,
			color: 'green',
			timeZoneType: this.timeZoneType,
		});

		let summarySeries = chartDef.SeriesOneData.find(
			(s) => s.name == 'Total'
		);
		summarySeries.color =
			summarySeries.duration < 120
				? 'Green'
				: summarySeries.duration > 120 && summarySeries.duration < 240
				? 'DarkOrange'
				: 'Red';
		chartDef.Title = `Hookup #${this.pastHookupEvents.length + 1} for ${
			dockToUndockRow.AirlineCode ? dockToUndockRow.AirlineCode : 'N/A'
		}${dockToUndockRow.FlightNumber ? dockToUndockRow.FlightNumber : ''}`;

		chartDef.Options = {
			chart: {
				type: 'columnrange',
				backgroundColor:
					this.theme === 'dark' ? 'rgb(39,41,61)' : 'white',
				style: {
					fontFamily: 'Poppins, sans-serif',
					color: outerChartTextColor,
				},
				inverted: true,
			},
			animation: {
				duration: 100,
			},
			exporting: { enabled: false },
			credits: { enabled: false },
			title: {
				text: chartDef.Title,
				verticalAlign: 'bottom',
				style: {
					color: outerChartTextColor,
					fontSize: '1em',
				},
			},
			xAxis: {
				type: 'category',
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					align: 'right',
					reserveSpace: true,
					useHTML: true,
					formatter: function () {
						const iconURL =
							this.chart.userOptions.series[0].data.find(
								(series) => series.name == this.value
							).statusIcon;
						return iconURL
							? `<img src='/${iconURL}' width='20' height='20'> <span style="margin-right:16px"> ${this.value}</span>`
							: this.value;
					},
					style: {
						fontSize: '12px',
						wordWrap: 'break word',
						fontFamily: 'Verdana, sans-serif',
						color: outerChartTextColor,
					},
				},
			},
			yAxis: {
				title: {
					text: '',
				},
				tickPositions: [0, 60, 120, 180, 240, 300],
				tickInterval: 60,
				minorGridLineWidth: this.theme === 'dark' ? 0.5 : 1,
				labels: {
					formatter: function () {
						return `${this.value / 60} m`;
					},
					style: {
						color: outerChartTextColor,
					},
				},
			},
			tooltip: {
				formatter: function () {
					const formattedDuration = `${
						Math.floor(this.point.duration / 3600) > 0
							? Math.floor(this.point.duration / 3600) + ':'
							: ''
					}${
						Math.floor((this.point.duration % 3600) / 60) >= 10
							? Math.floor((this.point.duration % 3600) / 60)
							: '0' +
							  Math.floor((this.point.duration % 3600) / 60)
					}:${
						this.point.duration % 60
							? this.point.duration % 60 < 10
								? '0' + (this.point.duration % 60)
								: this.point.duration % 60
							: '00'
					}`;
					const formattedOvertime = `${
						Math.floor(this.point.overtime / 3600) > 0
							? Math.floor(this.point.overtime / 3600) + ':'
							: ''
					}${
						Math.floor((this.point.overtime % 3600) / 60) >= 10
							? Math.floor((this.point.overtime % 3600) / 60)
							: '0' +
							  Math.floor((this.point.overtime % 3600) / 60)
					}:${
						this.point.overtime % 60
							? this.point.overtime % 60 < 10
								? '0' + (this.point.overtime % 60)
								: this.point.overtime % 60
							: '00'
					}`;
					const formattedTarget = `${Math.floor(
						this.point.limit / 60
					)}:${
						this.point.limit % 60
							? this.point.limit % 60 < 10
								? '0' + (this.point.limit % 60)
								: this.point.limit % 60
							: '00'
					}`;

					return this.point.name !== 'Total'
						? `<b>${this.point.eventName}</b><br/>
							  Timer Start: ${
									this.point.timeZoneType === 'User Time'
										? this.point.startTimeTxt
										: this.point.startTimeTxtSite
								}<br/>
							  Timer End: ${
									this.point.timeZoneType === 'User Time'
										? this.point.startTimeTxt
										: this.point.startTimeTxtSite
								}<br/>
							  Duration: ${formattedDuration}<br/>
							  Target: ${formattedTarget}<br/>
							  Overtime: ${formattedOvertime}`
						: `<b>${this.point.eventName}</b><br/>
							  Start: ${
									this.point.timeZoneType === 'User Time'
										? this.point.startTimeTxt
										: this.point.startTimeTxtSite
								}<br/>
							  End: ${
									this.point.timeZoneType === 'User Time'
										? this.point.startTimeTxt
										: this.point.startTimeTxtSite
								}<br/>
							  Target: ${formattedTarget}<br/>
							  Duration: ${formattedDuration}<br/>
							  Overtime: ${formattedOvertime}`;
				},
			},
			plotOptions: {
				series: {
					animation: {
						duration: 500,
					},
					stacking: 'normal',
					lineWidth: 1,
					states: {
						inactive: {
							opacity: 1,
						},
					},
				},
				columnrange: {
					dataLabels: {
						enabled: true,
						inside: true,
						color: innerChartTextColor,
						align: 'center',
						formatter: function () {
							return `${
								Math.floor(this.point.duration / 3600) > 0
									? Math.floor(this.point.duration / 3600) +
									  ':'
									: ''
							}${
								Math.floor((this.point.duration % 3600) / 60) >=
								10
									? Math.floor(
											(this.point.duration % 3600) / 60
									  )
									: '0' +
									  Math.floor(
											(this.point.duration % 3600) / 60
									  )
							}:${
								this.point.duration % 60
									? this.point.duration % 60 < 10
										? '0' + (this.point.duration % 60)
										: this.point.duration % 60
									: '00'
							}`;
						},
						style: {
							fontWeight: 'bold',
							fontSize: 'small',
						},
					},
				},
			},
			legend: {
				enabled: false,
			},
			series: [
				{
					name: 'Hookup Events 1',
					data: chartDef.SeriesOneData,
				},
				{
					name: 'Hookup Events 2',
					data: chartDef.SeriesTwoData,
				},
			],
		};

		let pastHookupEvent = {
			Chart: chartDef,
			Id: dockToUndockRow.Id,
			AirlineCode: dockToUndockRow.AirlineCode
				? dockToUndockRow.AirlineCode
				: 'N/A',
			FlightNumber:
				dockToUndockRow.AirlineCode && dockToUndockRow.FlightNumber
					? dockToUndockRow.AirlineCode + dockToUndockRow.FlightNumber
					: 'N/A', //hookup?.FlightNumber ? hookup.FlightNumber : 0,
			TailNumber: dockToUndockRow.TailNumber
				? dockToUndockRow.TailNumber
				: 'N/A',
			DockNumber: this.pastHookupEvents.length + 1,
			EventType: 'Hookup',
			TotalDuration: new Date(summarySeries.duration * 1000)
				.toISOString()
				.substring(11, 19),
			TotalOvertime: new Date(summarySeries.overtime * 1000)
				.toISOString()
				.substring(11, 19),
			StartTimeMS: summarySeries.startTimeMS,
			StartTimeFormatted: summarySeries.startTimeTxt,
			EndTimeMS: summarySeries.endTimeMS,
			EndTimeFormatted: summarySeries.endTimeTxt,
			Success: summarySeries.duration <= 300 ? true : false,
			TotalDurationSec: summarySeries.duration,
		};

		pastHookupEvent['StartDateLocal'] = new Date(
			pastHookupEvent.StartTimeMS
		);
		pastHookupEvent['EndDateLocal'] = new Date(pastHookupEvent.EndTimeMS);
		pastHookupEvent['StartDateSite'] =
			this.utilityService.convertFromUtcToLocalToSite(
				pastHookupEvent.StartTimeMS,
				this.siteUTCTimeOffset
			);
		pastHookupEvent['EndDateSite'] =
			this.utilityService.convertFromUtcToLocalToSite(
				pastHookupEvent.EndTimeMS,
				this.siteUTCTimeOffset
			);

		this.pastHookupEvents.push(pastHookupEvent);
		this.allPastEvents.push(pastHookupEvent);
		this.statistics.hookupSuccessPercent = (
			_.meanBy(this.pastHookupEvents, (ev) => ev.Success == true) * 100
		).toFixed(2);
		this.statistics.hookupAvgTime = new Date(
			_.meanBy(this.pastHookupEvents, (ev) => ev.TotalDurationSec) * 1000
		)
			.toISOString()
			.substring(11, 19);
	}

	addDisconnectToHistory(dockToUndockRow: any) {
		let innerChartTextColor =
			this.theme === 'dark'
				? 'white'
				: this.sassHelper.readProperty('body-text-color-dark');
		let outerChartTextColor =
			this.theme === 'dark'
				? this.sassHelper.readProperty('body-text-color-light')
				: this.sassHelper.readProperty('body-text-color-dark');
		let chartDef = { SeriesData: [], Title: '', Options: {}, Subtitle: '' };

		const millisecondDates = {
			PCAOFF: dockToUndockRow.PCAOFFMS
				? dockToUndockRow.PCAOFFMS
				: undefined,
			GPUOFF: dockToUndockRow.GPUOFFMS
				? dockToUndockRow.GPUOFFMS
				: undefined,
			PBBMoveAfterUndock: dockToUndockRow.PBBMoveAfterUndockMS
				? dockToUndockRow.PBBMoveAfterUndockMS
				: undefined,
			PBBUndocked: dockToUndockRow.PBBUndockedMS
				? dockToUndockRow.PBBUndockedMS
				: undefined,
		};

		let gpuOffEvent = {
			DateMS: millisecondDates.GPUOFF,
			SeriesType: 'GPU',
			SeriesName: 'GPU Turned Off',
		};
		let pcaOffEvent = {
			DateMS: millisecondDates.PCAOFF,
			SeriesType: 'PCA',
			SeriesName: 'PCA Turned Off',
		};

		let firstEventDateMS, secondEvent, thirdEvent;

		if (dockToUndockRow.PCAOFFMS || dockToUndockRow.GPUOFFMS) {
			firstEventDateMS = moment(millisecondDates.GPUOFF).isBefore(
				millisecondDates.PCAOFF
			)
				? millisecondDates.GPUOFF
				: millisecondDates.PCAOFF;

			if (gpuOffEvent.DateMS && pcaOffEvent.DateMS) {
				secondEvent = moment(gpuOffEvent?.DateMS).isBefore(
					pcaOffEvent?.DateMS
				)
					? gpuOffEvent
					: pcaOffEvent;
				thirdEvent = moment(gpuOffEvent?.DateMS).isBefore(
					pcaOffEvent?.DateMS
				)
					? pcaOffEvent
					: gpuOffEvent;
			} else if (gpuOffEvent.DateMS && !pcaOffEvent.DateMS) {
				secondEvent = gpuOffEvent;
				thirdEvent = pcaOffEvent;
			} else if (!gpuOffEvent.DateMS && pcaOffEvent.DateMS) {
				secondEvent = pcaOffEvent;
				thirdEvent = gpuOffEvent;
			}

			chartDef.SeriesData.push(
				{
					name: secondEvent.SeriesType,
					eventName: secondEvent.SeriesName,
					startTimeMS: firstEventDateMS,
					startTimeTxt: moment(firstEventDateMS).format('hh:mm:ss a'),
					endTimeMS: secondEvent.DateMS,
					endTimeTxt: moment(secondEvent.DateMS).format('hh:mm:ss a'),
					startTimeTxtSite: moment(
						this.utilityService.convertFromUtcToLocalToSite(
							firstEventDateMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a'),

					endTimeTxtSite: 'In Progress',
					limit: 20,
					overtime: 0,
					duration: 0,
					low: 0,
					high: undefined,
					color: 'green',
					timeZoneType: this.timeZoneType,
				},
				{
					name: thirdEvent.SeriesType,
					eventName: thirdEvent.SeriesName,
					startTimeMS: secondEvent.DateMS,
					startTimeTxt: moment(secondEvent.DateMS).format(
						'hh:mm:ss a'
					),
					endTimeMS: undefined,
					endTimeTxt: 'In Progress',
					startTimeTxtSite: moment(
						this.utilityService.convertFromUtcToLocalToSite(
							secondEvent.DateMS,
							this.siteUTCTimeOffset
						)
					).format('hh:mm:ss a'),

					endTimeTxtSite: 'In Progress',
					limit: 20,
					overtime: 0,
					duration: 0,
					low: undefined,
					high: undefined,
					color: 'green',
					timeZoneType: this.timeZoneType,
				}
			);

			chartDef.SeriesData[0].high = moment(
				moment(secondEvent.DateMS)
			).diff(moment(firstEventDateMS), 'seconds');
			chartDef.SeriesData[0].duration =
				chartDef.SeriesData[0].high - chartDef.SeriesData[0].low;
			chartDef.SeriesData[0].overtime =
				chartDef.SeriesData[0].duration > chartDef.SeriesData[0].limit
					? Math.abs(
							chartDef.SeriesData[0].duration -
								chartDef.SeriesData[0].limit
					  )
					: 0;
			chartDef.SeriesData[0].color =
				chartDef.SeriesData[0].overtime == 0 ? 'green' : 'red';

			chartDef.SeriesData[1].low = chartDef.SeriesData[0].high;
			chartDef.SeriesData[1].endTimeMS = thirdEvent.DateMS;
			chartDef.SeriesData[1].endTimeTxt = moment(
				thirdEvent.DateMS
			).format('hh:mm:ss a');
			(chartDef.SeriesData[1].endTimeTxtSite = moment(
				this.utilityService.convertFromUtcToLocalToSite(
					thirdEvent.DateMS,
					this.siteUTCTimeOffset
				)
			).format('hh:mm:ss a')),
				(chartDef.SeriesData[1].high =
					chartDef.SeriesData[1].low +
					moment(thirdEvent.DateMS).diff(
						moment(secondEvent.DateMS),
						'seconds'
					));
			chartDef.SeriesData[1].duration =
				chartDef.SeriesData[1].high - chartDef.SeriesData[1].low;
			chartDef.SeriesData[1].overtime =
				chartDef.SeriesData[1].duration > chartDef.SeriesData[1].limit
					? Math.abs(
							chartDef.SeriesData[1].duration -
								chartDef.SeriesData[1].limit
					  )
					: 0;
			chartDef.SeriesData[1].color =
				chartDef.SeriesData[1].overtime == 0 ? 'green' : 'red';

			chartDef.SeriesData.push({
				name: 'PBB',
				eventName: 'PBB Undocked',
				startTimeMS: chartDef.SeriesData[0].endTimeMS,
				startTimeTxt: chartDef.SeriesData[0].endTimeTxt,
				endTimeMS: undefined,
				endTimeTxt: 'In Progress',
				startTimeTxtSite: moment(
					this.utilityService.convertFromUtcToLocalToSite(
						chartDef.SeriesData[0].endTimeMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a'),

				endTimeTxtSite: 'In Progress',
				limit: 20,
				overtime: 0,
				duration: 0,
				low: chartDef.SeriesData[0].high,
				high: undefined,
				color: 'green',
				timeZoneType: this.timeZoneType,
			});

			chartDef.SeriesData[2].endTimeMS = millisecondDates.PBBUndocked;
			chartDef.SeriesData[2].endTimeTxt = moment(
				millisecondDates.PBBUndocked
			).format('hh:mm:ss a');
			(chartDef.SeriesData[2].endTimeTxtSite = moment(
				this.utilityService.convertFromUtcToLocalToSite(
					millisecondDates.PBBUndocked,
					this.siteUTCTimeOffset
				)
			).format('hh:mm:ss a')),
				(chartDef.SeriesData[2].high =
					chartDef.SeriesData[2].low +
					moment(millisecondDates.PBBUndocked).diff(
						moment(secondEvent.DateMS),
						'seconds'
					));
			chartDef.SeriesData[2].duration =
				chartDef.SeriesData[2].high - chartDef.SeriesData[2].low;
			chartDef.SeriesData[2].overtime =
				chartDef.SeriesData[2].duration > chartDef.SeriesData[2].limit
					? Math.abs(
							chartDef.SeriesData[2].duration -
								chartDef.SeriesData[2].limit
					  )
					: 0;
			chartDef.SeriesData[2].color =
				chartDef.SeriesData[2].overtime == 0 ? 'green' : 'red';
		} else {
			chartDef.SeriesData.push({
				name: 'PBB',
				eventName: 'PBB Undocked',
				startTimeMS: moment(millisecondDates.PBBUndocked).valueOf(),
				startTimeTxt: moment(millisecondDates.PBBUndocked).format(
					'hh:mm:ss a'
				),
				endTimeMS: undefined,
				endTimeTxt: 'In Progress',
				startTimeTxtSite: moment(
					this.utilityService.convertFromUtcToLocalToSite(
						millisecondDates.PBBUndocked,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a'),

				endTimeTxtSite: 'In Progress',
				limit: 20,
				overtime: 0,
				duration: 0,
				low: 0,
				high: undefined,
				color: 'green',
				timeZoneType: this.timeZoneType,
			});

			chartDef.SeriesData[0].endTimeMS = moment(
				millisecondDates.PBBMoveAfterUndock
			).valueOf();
			chartDef.SeriesData[0].endTimeTxt = moment(
				millisecondDates.PBBMoveAfterUndock
			).format('hh:mm:ss a');
			(chartDef.SeriesData[0].endTimeTxtSite = moment(
				this.utilityService.convertFromUtcToLocalToSite(
					millisecondDates.PBBMoveAfterUndock,
					this.siteUTCTimeOffset
				)
			).format('hh:mm:ss a')),
				(chartDef.SeriesData[0].high = moment(
					millisecondDates.PBBMoveAfterUndock
				).diff(moment(millisecondDates.PBBUndocked), 'seconds'));
			chartDef.SeriesData[0].duration =
				chartDef.SeriesData[0].high - chartDef.SeriesData[0].low;
			chartDef.SeriesData[0].overtime =
				chartDef.SeriesData[0].duration > chartDef.SeriesData[0].limit
					? Math.abs(
							chartDef.SeriesData[0].duration -
								chartDef.SeriesData[0].limit
					  )
					: 0;
			chartDef.SeriesData[0].color =
				chartDef.SeriesData[0].overtime == 0 ? 'green' : 'red';
		}
		let lastEvent = moment(chartDef.SeriesData[1]?.endTimeMS).isAfter(
			moment(chartDef.SeriesData[2]?.endTimeMS)
		)
			? chartDef.SeriesData[1]
			: chartDef.SeriesData[2];
		if (!lastEvent) {
			lastEvent = chartDef.SeriesData[0];
		}

		chartDef.SeriesData.push({
			name: 'Total',
			eventName: 'Summary',
			startTimeMS: chartDef.SeriesData[0].startTimeMS,
			startTimeTxt: chartDef.SeriesData[0].startTimeTxt,
			endTimeMS: moment(lastEvent.endTimeMS).valueOf(),
			endTimeTxt: moment(lastEvent.endTimeMS).format('hh:mm:ss a'),
			startTimeTxtSite: chartDef.SeriesData[0].startTimeTxtSite,

			endTimeTxtSite: moment(
				this.utilityService.convertFromUtcToLocalToSite(
					lastEvent.endTimeMS,
					this.siteUTCTimeOffset
				)
			).format('hh:mm:ss a'),
			// overtime: _.sumBy(chartDef.SeriesData, "overtime") + chartDef.SeriesData[0].overtime,
			overtime:
				lastEvent.duration > 300
					? Math.abs(300 - (lastEvent.duration + lastEvent.low))
					: 0,
			// duration: _.sumBy(chartDef.SeriesData, "duration") + chartDef.SeriesData[0].duration,
			duration: lastEvent.duration + lastEvent.low,
			limit: 300,
			low: 0,
			high: lastEvent.high,
			color: 'green',
			timeZoneType: this.timeZoneType,
		});

		let totalData =
			millisecondDates.PCAOFF || millisecondDates.GPUOFF
				? chartDef.SeriesData[3]
				: chartDef.SeriesData[1];
		totalData.color =
			totalData.duration < 120
				? 'Green'
				: totalData.duration > 120 && totalData.duration < 240
				? 'DarkOrange'
				: 'Red';

		chartDef.Title = `Disconnect #${
			this.pastDisconnectEvents.length + 1
		} for ${
			dockToUndockRow.AirlineCode ? dockToUndockRow.AirlineCode : 'N/A'
		}${dockToUndockRow.FlightNumber ? dockToUndockRow.FlightNumber : ''}`;

		chartDef.Options = {
			chart: {
				type: 'columnrange',
				backgroundColor:
					this.theme === 'dark' ? 'rgb(39,41,61)' : 'white',
				style: {
					fontFamily: 'Poppins, sans-serif',
					color: outerChartTextColor,
				},
				inverted: true,
			},
			animation: {
				duration: 100,
			},
			exporting: { enabled: false },
			credits: { enabled: false },
			title: {
				text: chartDef.Title,
				verticalAlign: 'bottom',
				style: {
					color: outerChartTextColor,
					fontSize: '1em',
				},
			},
			xAxis: {
				title: {
					text: '',
				},
				type: 'category',
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					style: {
						fontSize: '10px',
						wordWrap: 'break word',
						fontFamily: 'Verdana, sans-serif',
						color: outerChartTextColor,
					},
				},
			},
			yAxis: {
				title: {
					text: '',
				},
				tickPositions: [0, 60, 120, 180, 240, 300],
				tickInterval: 60,
				minorGridLineWidth: this.theme === 'dark' ? 0.5 : 1,
				labels: {
					formatter: function () {
						return `${this.value / 60} m`;
					},
					style: {
						color: outerChartTextColor,
					},
				},
			},
			tooltip: {
				formatter: function () {
					const formattedDuration = `${
						Math.floor(this.point.duration / 3600) > 0
							? Math.floor(this.point.duration / 3600) + ':'
							: ''
					}${
						Math.floor((this.point.duration % 3600) / 60) >= 10
							? Math.floor((this.point.duration % 3600) / 60)
							: '0' +
							  Math.floor((this.point.duration % 3600) / 60)
					}:${
						this.point.duration % 60
							? this.point.duration % 60 < 10
								? '0' + (this.point.duration % 60)
								: this.point.duration % 60
							: '00'
					}`;
					const formattedOvertime = `${
						Math.floor(this.point.overtime / 3600) > 0
							? Math.floor(this.point.overtime / 3600) + ':'
							: ''
					}${
						Math.floor((this.point.overtime % 3600) / 60) >= 10
							? Math.floor((this.point.overtime % 3600) / 60)
							: '0' +
							  Math.floor((this.point.overtime % 3600) / 60)
					}:${
						this.point.overtime % 60
							? this.point.overtime % 60 < 10
								? '0' + (this.point.overtime % 60)
								: this.point.overtime % 60
							: '00'
					}`;
					const formattedTarget = `${Math.floor(
						this.point.limit / 60
					)}:${
						this.point.limit % 60
							? this.point.limit % 60 < 10
								? '0' + (this.point.limit % 60)
								: this.point.limit % 60
							: '00'
					}`;
					return this.point.name !== 'Total'
						? `<b>${this.point.eventName}</b><br/>
							  Timer Start: ${
									this.point.timeZoneType === 'User Time'
										? this.point.startTimeTxt
										: this.point.startTimeTxtSite
								}<br/>
							  Timer End: ${
									this.point.timeZoneType === 'User Time'
										? this.point.startTimeTxt
										: this.point.startTimeTxtSite
								}<br/>
							  Duration: ${formattedDuration}<br/>
							  Target: ${formattedTarget}<br/>
							  Overtime: ${formattedOvertime}`
						: `<b>${this.point.eventName}</b><br/>
							  Start: ${
									this.point.timeZoneType === 'User Time'
										? this.point.startTimeTxt
										: this.point.startTimeTxtSite
								}<br/>
							  End: ${
									this.point.timeZoneType === 'User Time'
										? this.point.startTimeTxt
										: this.point.startTimeTxtSite
								}<br/>
							  Target: ${formattedTarget}<br/>
							  Duration: ${formattedDuration}<br/>
							  Overtime: ${formattedOvertime}`;
				},
			},

			plotOptions: {
				series: {
					animation: {
						duration: 500,
					},
					stacking: 'normal',
					lineWidth: 1,
					states: {
						inactive: {
							opacity: 1,
						},
					},
				},
				columnrange: {
					dataLabels: {
						enabled: true,
						inside: true,
						color: innerChartTextColor,
						align: 'center',
						formatter: function () {
							return `${
								Math.floor(this.point.duration / 3600) > 0
									? Math.floor(this.point.duration / 3600) +
									  ':'
									: ''
							}${
								Math.floor((this.point.duration % 3600) / 60) >=
								10
									? Math.floor(
											(this.point.duration % 3600) / 60
									  )
									: '0' +
									  Math.floor(
											(this.point.duration % 3600) / 60
									  )
							}:${
								this.point.duration % 60
									? this.point.duration % 60 < 10
										? '0' + (this.point.duration % 60)
										: this.point.duration % 60
									: '00'
							}`;
						},
						style: {
							fontWeight: 'bold',
							fontSize: 'small',
						},
					},
				},
			},

			legend: {
				enabled: false,
			},

			series: [
				{
					name: 'Disconect Events',
					data: chartDef.SeriesData,
				},
			],
		};

		const pastDisconnectEvent = {
			Chart: chartDef,
			Id: dockToUndockRow.Id,
			AirlineCode: dockToUndockRow.AirlineCode
				? dockToUndockRow.AirlineCode
				: 'N/A',
			FlightNumber:
				dockToUndockRow.AirlineCode && dockToUndockRow.FlightNumber
					? dockToUndockRow.AirlineCode + dockToUndockRow.FlightNumber
					: 'N/A', //hookup?.FlightNumber ? hookup.FlightNumber : 0,
			TailNumber: dockToUndockRow.TailNumber
				? dockToUndockRow.TailNumber
				: 'N/A',
			DockNumber: this.pastDisconnectEvents.length + 1,
			EventType: 'Disconnect',
			TotalDuration: new Date(totalData.duration * 1000)
				.toISOString()
				.substring(11, 19),
			TotalOvertime: new Date(totalData.overtime * 1000)
				.toISOString()
				.substring(11, 19),
			StartTimeMS: totalData.startTimeMS,
			StartTimeFormatted: totalData.startTimeTxt,
			EndTimeMS: totalData.endTimeMS,
			EndTimeFormatted: totalData.endTimeTxt,
			Success: totalData.duration <= 300 ? true : false,
			TotalDurationSec: totalData.duration,
		};

		pastDisconnectEvent['StartDateLocal'] = new Date(
			pastDisconnectEvent.StartTimeMS
		);
		pastDisconnectEvent['EndDateLocal'] = new Date(
			pastDisconnectEvent.EndTimeMS
		);
		pastDisconnectEvent['StartDateSite'] =
			this.utilityService.convertFromUtcToLocalToSite(
				pastDisconnectEvent.StartTimeMS,
				this.siteUTCTimeOffset
			);
		pastDisconnectEvent['EndDateSite'] =
			this.utilityService.convertFromUtcToLocalToSite(
				pastDisconnectEvent.EndTimeMS,
				this.siteUTCTimeOffset
			);

		this.pastDisconnectEvents.push(pastDisconnectEvent);
		this.allPastEvents.push(pastDisconnectEvent);

		this.statistics.disconnectSuccessPercent = (
			_.meanBy(this.pastDisconnectEvents, (ev) => ev.Success == true) *
			100
		).toFixed(2);
		this.statistics.disconnectAvgTime = new Date(
			_.meanBy(this.pastDisconnectEvents, (ev) => ev.TotalDurationSec) *
				1000
		)
			.toISOString()
			.substring(11, 19);
	}

	createHistoricChart(event?: any) {
		if (this.allPastEvents.length > 0) {
			let innerChartTextColor =
				this.theme === 'dark'
					? 'white'
					: this.sassHelper.readProperty('body-text-color-dark');
			let outerChartTextColor =
				this.theme === 'dark'
					? this.sassHelper.readProperty('body-text-color-light')
					: this.sassHelper.readProperty('body-text-color-dark');

			if (event) {
				Global.User.DebugMode && console.log(event);
				this.displayedHistoricChartIndex = event.rowIndex;
				this.zone.runOutsideAngular(() => {
					this.HistoricChart = Highcharts.chart(
						`gsPerfectHookupHistoric${this.widgetObject.WidgetId}`,
						event.dataItem.Chart.Options
					);
				});
			} else {
				this.zone.runOutsideAngular(() => {
					this.HistoricChart = Highcharts.chart(
						`gsPerfectHookupHistoric${this.widgetObject.WidgetId}`,
						this.allPastEvents[this.displayedHistoricChartIndex]
							.Chart.Options
					);
				});
			}

			this.HistoricChart.update({
				chart: {
					backgroundColor:
						this.theme === 'dark' ? 'rgb(39,41,61)' : 'white',
					style: {
						color: outerChartTextColor,
					},
				},
				title: {
					style: {
						color: outerChartTextColor,
					},
				},
				xAxis: {
					labels: {
						style: {
							color: outerChartTextColor,
						},
					},
				},
				yAxis: {
					labels: {
						style: {
							color: outerChartTextColor,
						},
					},
				},
				plotOptions: {
					columnrange: {
						dataLabels: {
							color: innerChartTextColor,
						},
					},
				},
			});
		}
	}

	updateTimers() {
		if (this.isHookupActive) {
			this.updateHookupTimer();

			if (this.activeTimers.PBBDocked) {
				this.updatePBBDockedEvent();
			}

			if (this.activeTimers.PCAOn) {
				this.updatePCAOnEvent();
			}

			if (this.activeTimers.GPUOn) {
				this.updateGPUOnEvent();
			}

			if (this.activeTimers.GPUSwitchedOver) {
				this.updateGPUSwitchoverEvent();
			}

			if (this.activeTimers.PCAOff) {
				this.DisconnectChart?.series && this.updatePCAOffEvent();
			}

			if (this.activeTimers.GPUOff) {
				this.DisconnectChart?.series && this.updateGPUOffEvent();
			}

			if (this.activeTimers.PBBUndocked) {
				this.DisconnectChart?.series && this.updatePBBUndockedEvent();
			}
		} else {
			clearInterval(this.hookupTimerInterval);
		}
	}

	public countUpTimerInterval;
	public threshholdMS = 180000;
	public thresholdcost = 0.00111667; // per ms

	InitializeCurrentDock() {
		this.dock.duration.totalMS = 0;
		this.pbb.duration.totalMS = 0;
		this.pbbdock.duration.totalMS = 0;
		this.pbbaway.duration.totalMS = 0;
		this.gpu.duration.totalMS = 0;
		this.gpuon.duration.totalMS = 0;
		this.gpuswitchover.duration.totalMS = 0;
		this.gputotal.duration.totalMS = 0;
		this.pca.duration.totalMS = 0;
		this.pcaon.duration.totalMS = 0;
	}

	// StartTimerTemp() {
	// 	this.currentDock.PBBMoveStartMS = 0; // =null
	// 	this.currentDock.PBBDockedMS = 0;
	// 	this.currentDock.GPUOnMS = 0;
	// 	this.currentDock.GPUSwitchoverMS = 0;
	// 	this.currentDock.GPUOFFMS = 0;
	// 	this.currentDock.PCAVentilatingAircraftMS = 0;
	// 	this.currentDock.PCAOFFMS = 0;
	// 	this.currentDock.PBBUndockedMS = 0;
	// 	this.currentDock.PBBMoveAwayMS = 0;
	// 	this.countUpTimerInterval = setInterval(() => this.updateCountUpTimer(), 100);
	// }

	// StopPBBTimerTemp() {
	// 	this.currentDock.PBBDockedMS = moment().valueOf();
	// }
	// StopGPUTimerTemp() {
	// 	this.currentDock.GPUOnMS = moment().valueOf();
	// }
	// StopGPUOnTimerTemp() {
	// 	this.currentDock.GPUSwitchoverMS = moment().valueOf();
	// }
	// StopGPUSwitchoverTimerTemp() {
	// 	this.currentDock.GPUOFFMS = moment().valueOf();
	// }

	// StopPCATimerTemp() {
	// 	this.currentDock.PCAVentilatingAircraftMS = moment().valueOf();
	// }
	// StopPCAOnTimerTemp() {
	// 	this.currentDock.PCAOFFMS = moment().valueOf();
	// }
	// StopHookupTimerTemp() {
	// 	this.currentDock.PBBUndockedMS = moment().valueOf();
	// }
	// StopPBBMoveAwayTimerTemp() {
	// 	this.currentDock.PBBMoveAwayMS = moment().valueOf();
	// }

	SetDisplayDuration(duration) {
		duration.negative = '';

		duration.absTotalMS = Math.abs(duration.totalMS);

		if (duration.totalMS < 0) {
			duration.negative = '-';
		}

		duration.hours = Math.floor(duration.absTotalMS / 3600000);
		// if (duration.hours > 24) {
		// 	Global.User.currentUser.Username == 'kendemerchant' &&
		// 		console.log('duration.hours too hi: ', duration.hours);
		// }
		var durationDate = new Date(duration.absTotalMS);

		duration.minutes = ('0' + durationDate.getMinutes()).slice(-2);
		duration.seconds = ('0' + durationDate.getSeconds()).slice(-2);
	}

	// dockColor = "#008fff"; 		// Blue
	// unitOnColor = "#7100ff"; 	// Violet
	// unitOnAltColor = "#000fff";	// Dark Blue
	// backColor = "#f2f2f4";		// Light Grey

	// SetHookupProgress() {
	// 	//
	// 	// PBB
	// 	//

	// 	this.state.pbb = 'Undocked';

	// 	if (this.currentDock.PBBMoveStartMS > 0) {
	// 		//update PBB as moving
	// 		if (
	// 			this.currentDock.PBBDockedMS == undefined ||
	// 			this.currentDock.PBBDockedMS == 0
	// 		) {
	// 			this.pbb.duration.totalMS =
	// 				moment().valueOf() - this.currentDock.PBBMoveStartMS; // ms from move start
	// 			this.state.pbb = 'Driving';
	// 		} else {
	// 			this.pbb.duration.totalMS =
	// 				this.currentDock.PBBDockedMS -
	// 				this.currentDock.PBBMoveStartMS; // ms from move start
	// 			this.state.pbb = 'Docked';
	// 		}

	// 		this.SetDisplayDuration(this.pbb.duration);
	// 	} else {
	// 		this.pbb.duration.totalMS = 0;
	// 	}

	// 	if (this.currentDock.PBBDockedMS > 0) {
	// 		if (this.currentDock.PBBUndockedMS == undefined) {
	// 			this.pbbdock.duration.totalMS =
	// 				moment().valueOf() - this.currentDock.PBBDockedMS; // ms from dock
	// 			this.state.pbb = 'Docked';
	// 		} else {
	// 			this.pbbdock.duration.totalMS =
	// 				this.currentDock.PBBUndockedMS -
	// 				this.currentDock.PBBDockedMS; // ms from dock
	// 		}

	// 		this.SetDisplayDuration(this.pbbdock.duration);
	// 	}

	// 	if (this.currentDock.PBBUndockedMS > 0) {
	// 		// update pbb move away
	// 		if (
	// 			this.currentDock.PBBMoveAwayMS == undefined ||
	// 			this.currentDock.PBBMoveAwayMS == 0
	// 		) {
	// 			this.pbbaway.duration.totalMS =
	// 				moment().valueOf() - this.currentDock.PBBUndockedMS; // ms from undock
	// 		} else {
	// 			this.pbbaway.duration.totalMS =
	// 				this.currentDock.PBBMoveAwayMS -
	// 				this.currentDock.PBBUndockedMS; // ms from undock
	// 		}

	// 		this.SetDisplayDuration(this.pbbaway.duration);
	// 	}

	// 	// update gradiant
	// 	let pbbTime = Math.round(
	// 		(100 * this.pbb.duration.totalMS) / (this.largeTimescale * 60000)
	// 	);
	// 	this.pbb.progressBackground = `conic-gradient(#008fff ${pbbTime}%, #f2f2f4 ${pbbTime}%)`;

	// 	// let pbbdockTime = Math.round(
	// 	// 	(100 * this.pbbdock.duration.totalMS) / (this.largeTimescale * 60000)
	// 	// );
	// 	// let pbbawayTime = Math.round(
	// 	// 	(100 * this.pbbaway.duration.totalMS) / (this.largeTimescale * 60000)
	// 	// );
	// 	//let pbbplusdockTime = pbbTime + pbbdockTime;
	// 	//let pbbTotalTime = pbbTime + pbbdockTime + pbbawayTime;
	// 	//this.pbb.progressBackground = `conic-gradient(#008fff ${pbbTime}%, #7100ff ${pbbTime}%, #7100ff ${pbbplusdockTime}%, #000fff ${pbbplusdockTime}%, #000fff ${pbbTotalTime}%, #f2f2f4 ${pbbTotalTime}%)`;

	// 	//
	// 	// GPU
	// 	//

	// 	this.state.gpu = 'OFF';

	// 	if (this.currentDock.PBBDockedMS > 0) {
	// 		//update GPU
	// 		if (this.currentDock.GPUOnMS != undefined) {
	// 			this.gpu.duration.totalMS =
	// 				this.currentDock.GPUOnMS - this.currentDock.PBBDockedMS; // ms from dock
	// 		} else if (this.currentDock.GPUSwitchoverMS != undefined) {
	// 			this.gpu.duration.totalMS =
	// 				this.currentDock.GPUSwitchoverMS -
	// 				this.currentDock.PBBDockedMS; // ms from dock
	// 		} else if (this.currentDock.GPUOFFMS != undefined) {
	// 			this.gpu.duration.totalMS =
	// 				this.currentDock.GPUOFFMS - this.currentDock.PBBDockedMS; // ms from dock
	// 		} else if (this.currentDock.PBBUndockedMS != undefined) {
	// 			this.gpu.duration.totalMS =
	// 				this.currentDock.PBBUndockedMS -
	// 				this.currentDock.PBBDockedMS; // ms from dock
	// 		} else {
	// 			this.gpu.duration.totalMS =
	// 				moment().valueOf() - this.currentDock.PBBDockedMS; // ms from dock
	// 			this.state.gpu = 'Docked';
	// 		}

	// 		this.SetDisplayDuration(this.gpu.duration);

	// 		// update gradiant
	// 		let gpuTime = Math.round(
	// 			(100 * this.gpu.duration.totalMS) /
	// 				(this.largeTimescale * 60000)
	// 		);
	// 		this.gpu.progressBackground = `conic-gradient(#008fff ${gpuTime}%, #f2f2f4 ${gpuTime}%)`;
	// 	}

	// 	if (this.currentDock.GPUOnMS > 0) {
	// 		//update GPU
	// 		if (this.currentDock.GPUSwitchoverMS != undefined) {
	// 			this.gpuon.duration.totalMS =
	// 				this.currentDock.GPUSwitchoverMS - this.currentDock.GPUOnMS; // ms from gpu on
	// 		} else if (this.currentDock.GPUOFFMS != undefined) {
	// 			this.gpuon.duration.totalMS =
	// 				this.currentDock.GPUOFFMS - this.currentDock.GPUOnMS; // ms from gpu on
	// 		} else if (this.currentDock.PBBUndockedMS != undefined) {
	// 			this.gpuon.duration.totalMS =
	// 				this.currentDock.PBBUndockedMS - this.currentDock.GPUOnMS; // ms from gpu on
	// 		} else {
	// 			this.gpuon.duration.totalMS =
	// 				moment().valueOf() - this.currentDock.GPUOnMS; // ms from gpu on
	// 			this.state.gpu = 'ON';
	// 		}

	// 		this.SetDisplayDuration(this.gpuon.duration);

	// 		// update gradiant
	// 		let gpuTime = Math.round(
	// 			(100 * this.gpu.duration.totalMS) /
	// 				(this.largeTimescale * 60000)
	// 		);
	// 		let gpuonTime = Math.round(
	// 			(100 * this.gpuon.duration.totalMS) /
	// 				(this.largeTimescale * 60000)
	// 		);
	// 		let gputotalTime = gpuTime + gpuonTime;
	// 		this.gpu.progressBackground = `conic-gradient(#008fff ${gpuTime}%, #7100ff ${gpuTime}%, #7100ff ${gputotalTime}%, #f2f2f4 ${gputotalTime}%)`;
	// 	}

	// 	if (this.currentDock.GPUSwitchoverMS > 0) {
	// 		//update GPU
	// 		if (this.currentDock.GPUOFFMS != undefined) {
	// 			this.gpuswitchover.duration.totalMS =
	// 				this.currentDock.GPUOFFMS -
	// 				this.currentDock.GPUSwitchoverMS; // ms from gpu switchover
	// 		} else if (this.currentDock.PBBUndockedMS != undefined) {
	// 			this.gpuswitchover.duration.totalMS =
	// 				this.currentDock.PBBUndockedMS -
	// 				this.currentDock.GPUSwitchoverMS; // ms from gpu switchover
	// 		} else {
	// 			this.gpuswitchover.duration.totalMS =
	// 				moment().valueOf() - this.currentDock.GPUSwitchoverMS; // ms from gpu switchover
	// 			this.state.gpu = 'Switchover';
	// 		}

	// 		this.SetDisplayDuration(this.gpuswitchover.duration);

	// 		// update gradiant - 2 color // dark blue  #000fff
	// 		// let gpuTime = Math.round(
	// 		// 	(100 * this.gpu.duration.totalMS) / (this.largeTimescale * 60000)
	// 		// );
	// 		// let gpuonTime = Math.round(
	// 		// 	(100 * this.gpuon.duration.totalMS) / (this.largeTimescale * 60000)
	// 		// );
	// 		// let gpuswitchoverTime = Math.round(
	// 		// 	(100 * this.gpuswitchover.duration.totalMS) / (this.largeTimescale * 60000)
	// 		// );
	// 		// let gpuplusonTime = gpuTime + gpuonTime;
	// 		// let gputotalTime = gpuTime + gpuonTime + gpuswitchoverTime;
	// 		// this.gpu.progressBackground = `conic-gradient(#008fff ${gpuTime}%, #7100ff ${gpuTime}%, #7100ff ${gpuplusonTime}%, #000fff ${gpuplusonTime}%, #000fff ${gputotalTime}%, #f2f2f4 ${gputotalTime}%)`;
	// 	}

	// 	//
	// 	// PCA / AHU
	// 	//

	// 	this.state.pca = 'OFF';

	// 	if (this.currentDock.PBBDockedMS > 0) {
	// 		//update PCA
	// 		if (this.currentDock.PCAVentilatingAircraftMS != undefined) {
	// 			this.pca.duration.totalMS =
	// 				this.currentDock.PCAVentilatingAircraftMS -
	// 				this.currentDock.PBBDockedMS; // ms from dock
	// 		} else if (this.currentDock.PCAOFFMS != undefined) {
	// 			this.pca.duration.totalMS =
	// 				this.currentDock.PCAOFFMS - this.currentDock.PBBDockedMS; // ms from dock
	// 		} else if (this.currentDock.PBBUndockedMS != undefined) {
	// 			this.pca.duration.totalMS =
	// 				this.currentDock.PBBUndockedMS -
	// 				this.currentDock.PBBDockedMS; // ms from dock
	// 		} else {
	// 			this.pca.duration.totalMS =
	// 				moment().valueOf() - this.currentDock.PBBDockedMS; // ms from dock
	// 			this.state.pca = 'Docked';
	// 		}

	// 		this.SetDisplayDuration(this.pca.duration);

	// 		// update gradiant
	// 		let pcaTime = Math.round(
	// 			(100 * this.pca.duration.totalMS) /
	// 				(this.largeTimescale * 60000)
	// 		);
	// 		this.pca.progressBackground = `conic-gradient(#008fff ${pcaTime}%, #f2f2f4 ${pcaTime}%)`;
	// 	}

	// 	if (this.currentDock.PCAVentilatingAircraftMS > 0) {
	// 		//update PCA

	// 		if (this.currentDock.PCAOFFMS != undefined) {
	// 			this.pcaon.duration.totalMS =
	// 				this.currentDock.PCAOFFMS -
	// 				this.currentDock.PCAVentilatingAircraftMS; // ms from pca on
	// 		} else if (this.currentDock.PBBUndockedMS != undefined) {
	// 			this.pcaon.duration.totalMS =
	// 				this.currentDock.PBBUndockedMS -
	// 				this.currentDock.PCAVentilatingAircraftMS; // ms from pca on
	// 		} else {
	// 			this.pcaon.duration.totalMS =
	// 				moment().valueOf() -
	// 				this.currentDock.PCAVentilatingAircraftMS; // ms from pca on
	// 			this.state.pca = 'ON';
	// 		}

	// 		this.SetDisplayDuration(this.pcaon.duration);

	// 		// update gradiant
	// 		// let pcaTime = Math.round(
	// 		// 	(100 * this.pca.duration.totalMS) / (this.largeTimescale * 60000)
	// 		// );
	// 		// let pcaonTime = Math.round(
	// 		// 	(100 * this.pcaon.duration.totalMS) / (this.largeTimescale * 60000)
	// 		// );
	// 		// let pcaTotalTime = pcaTime + pcaonTime;
	// 		// this.pca.progressBackground = `conic-gradient(#008fff ${pcaTime}%, #000fff ${pcaTime}%, #000fff ${pcaTotalTime}%, #f2f2f4 ${pcaTotalTime}%)`;
	// 	}

	// 	//
	// 	// Overall dock
	// 	//

	// 	if (this.currentDock.PBBMoveStartMS > 0) {
	// 		// update dock
	// 		if (
	// 			this.currentDock.PBBUndockedMS == undefined ||
	// 			this.currentDock.PBBUndockedMS == 0
	// 		) {
	// 			this.dock.duration.totalMS =
	// 				moment().valueOf() - this.currentDock.PBBMoveStartMS; // ms from move start
	// 		} else {
	// 			this.dock.duration.totalMS =
	// 				this.currentDock.PBBUndockedMS -
	// 				this.currentDock.PBBMoveStartMS; // ms from move start
	// 		}
	// 	} else if (this.currentDock.PBBDockedMS > 0) {
	// 		// update dock
	// 		if (
	// 			this.currentDock.PBBUndockedMS == undefined ||
	// 			this.currentDock.PBBUndockedMS == 0
	// 		) {
	// 			this.dock.duration.totalMS =
	// 				moment().valueOf() - this.currentDock.PBBDockedMS; // ms from docked
	// 		} else {
	// 			this.dock.duration.totalMS =
	// 				this.currentDock.PBBUndockedMS -
	// 				this.currentDock.PBBDockedMS; // ms from docked
	// 		}
	// 	}

	// 	this.SetDisplayDuration(this.dock.duration);

	// 	// gradiant
	// 	let dockTime = Math.round(
	// 		(100 * this.dock.duration.totalMS) / (this.largeTimescale * 60000)
	// 	); // 60 mins
	// 	this.dock.progressBackground = `conic-gradient(#008fff ${dockTime}%, #f2f2f4 ${dockTime}%)`;

	// 	// total durations
	// 	this.pbbtotal.duration.totalMS = this.pbb.duration.totalMS; // + this.pbbdock.duration.totalMS + this.pbbaway.duration.totalMS;
	// 	this.SetDisplayDuration(this.pbbtotal.duration);
	// 	this.gputotal.duration.totalMS =
	// 		this.gpu.duration.totalMS + this.gpuon.duration.totalMS; // + this.gpuswitchover.duration.totalMS;
	// 	this.SetDisplayDuration(this.gputotal.duration);
	// 	this.pcatotal.duration.totalMS = this.pca.duration.totalMS; // + this.pcaon.duration.totalMS;
	// 	this.SetDisplayDuration(this.pcatotal.duration);
	// }

	// updateCountUpTimer() {

	// 	if(this.currentDock.PBBDockedMS == 0) {
	// 		//update PBB
	// 		this.pbb.duration = moment().valueOf() - this.currentDock.PBBMoveStartMS; // ms from move start
	// 		// update gradiant
	// 		let pbbTime = Math.round(100*this.pbb.duration/360000);
	// 		this.pbb.progressBackground = `conic-gradient(#008fff ${pbbTime}%, #f2f2f4 ${pbbTime}%)`;

	// 	}

	// 	if(this.currentDock.PBBDockedMS > 0 && this.currentDock.GPUOnMS == 0) {
	// 		//update GPU
	// 		this.gpu.duration = moment().valueOf() - this.currentDock.PBBDockedMS; // ms from dock
	// 		// update gradiant
	// 		let gpuTime = Math.round(100*this.gpu.duration/360000);
	// 		this.gpu.progressBackground = `conic-gradient(#008fff ${gpuTime}%, #f2f2f4 ${gpuTime}%)`;

	// 		// update overtime values
	// 		if(this.gpu.duration > this.threshholdMS) {
	// 			this.gpu.overtime = this.gpu.duration - this.threshholdMS;
	// 			this.gpu.opportunity = this.gpu.overtime * this.thresholdcost;
	// 		}
	// 		else {
	// 			this.gpu.overtime = 0
	// 			this.gpu.opportunity = 0
	// 		}
	// 	}

	// 	if(this.currentDock.GPUOnMS > 0 && this.currentDock.GPUSwitchoverMS == 0) {
	// 		//update GPU
	// 		this.gpuon.duration = moment().valueOf() - this.currentDock.GPUOnMS; // ms from gpu on
	// 		// update gradiant
	// 		let gpuTime = Math.round(100*this.gpu.duration/360000);
	// 		let gpuonTime = Math.round(100*this.gpuon.duration/360000);
	// 		let gputotalTime = gpuTime + gpuonTime;
	// 		this.gpu.progressBackground = `conic-gradient(#008fff ${gpuTime}%, #7100ff ${gpuTime}%, #7100ff ${gputotalTime}%, #f2f2f4 ${gputotalTime}%)`;
	// 	}

	// 	if(this.currentDock.GPUSwitchoverMS > 0 && this.currentDock.GPUOFFMS == 0) {

	// 		//update GPU
	// 		this.gpuswitchover.duration = moment().valueOf() - this.currentDock.GPUSwitchoverMS; // ms from gpu switchover
	// 		// update gradiant - 2 color // dark blue  #000fff
	// 		let gpuTime = Math.round(100*this.gpu.duration/360000);
	// 		let gpuonTime = Math.round(100*this.gpuon.duration/360000);
	// 		let gpuswitchoverTime = Math.round(100*this.gpuswitchover.duration/360000);
	// 		let gpuplusonTime = gpuTime + gpuonTime;
	// 		let gputotalTime = gpuTime + gpuonTime + gpuswitchoverTime;
	// 		this.gpu.progressBackground = `conic-gradient(#008fff ${gpuTime}%, #7100ff ${gpuTime}%, #7100ff ${gpuplusonTime}%, #000fff ${gpuplusonTime}%, #000fff ${gputotalTime}%, #f2f2f4 ${gputotalTime}%)`;
	// 	}

	// 	if(this.currentDock.PBBDockedMS > 0 && this.currentDock.PCAVentilatingAircraftMS == 0) {
	// 		//update PCA
	// 		this.pca.duration = moment().valueOf() - this.currentDock.PBBDockedMS; // ms from dock
	// 		// update gradiant
	// 		let pcaTime = Math.round(100*this.pca.duration/360000);
	// 		this.pca.progressBackground = `conic-gradient(#008fff ${pcaTime}%, #f2f2f4 ${pcaTime}%)`;

	// 		// update overtime values
	// 		if(this.pca.duration > this.threshholdMS) {
	// 			this.pca.overtime = this.pca.duration - this.threshholdMS;
	// 			this.pca.opportunity = this.pca.overtime * this.thresholdcost;
	// 		}
	// 		else {
	// 			this.pca.overtime = 0
	// 			this.pca.opportunity = 0
	// 		}
	// 	}

	// 	if(this.currentDock.PCAVentilatingAircraftMS > 0 && this.currentDock.PCAOFFMS == 0) {
	// 		//update PCA
	// 		this.pcaon.duration = moment().valueOf() - this.currentDock.PCAVentilatingAircraftMS; // ms from pca on
	// 		// update gradiant
	// 		let pcaTime = Math.round(100*this.pca.duration/360000);
	// 		let pcaonTime = Math.round(100*this.pcaon.duration/360000);
	// 		let pcaTotalTime = pcaTime + pcaonTime
	// 		this.pca.progressBackground = `conic-gradient(#008fff ${pcaTime}%, #000fff ${pcaTime}%, #000fff ${pcaTotalTime}%, #f2f2f4 ${pcaTotalTime}%)`;
	// 	}

	// 	if(this.currentDock.PBBMoveStartMS > 0 && this.currentDock.PBBUndockedMS == 0) {
	// 		//update GPU
	// 		this.dock.duration = moment().valueOf() - this.currentDock.PBBMoveStartMS; // ms from move start
	// 		// update gradiant
	// 		let dockTime = Math.round(100*this.dock.duration/3600000); // 60 mins
	// 		this.dock.progressBackground = `conic-gradient(#008fff ${dockTime}%, #f2f2f4 ${dockTime}%)`;
	// 	}

	// 	if(this.currentDock.PBBUndockedMS > 0 && this.currentDock.PBBMoveAwayMS == 0) {
	// 		//update GPU
	// 		this.pbbaway.duration = moment().valueOf() - this.currentDock.PBBUndockedMS; // ms from undock
	// 		// update gradiant
	// 		let pbbTime = Math.round(100*this.pbb.duration/360000);
	// 		let pbbawayTime = Math.round(100*this.pbbaway.duration/360000);
	// 		let pbbTotalTime = pbbTime + pbbawayTime;
	// 		this.pbb.progressBackground = `conic-gradient(#008fff ${pbbTime}%, #000fff ${pbbTime}%, #000fff ${pbbTotalTime}%, #f2f2f4 ${pbbTotalTime}%)`;
	// 	}

	// 	if(this.currentDock.PBBMoveAwayMS > 0) {
	// 		clearInterval(this.countUpTimerInterval)
	// 	}
	// }

	updateHookupTimer() {
		let seconds, firstEventDate, lastEventDate, filteredEvents;
		if (this.HookupChart) {
			filteredEvents = this.hookupEventTimeline.filter(
				(ev) => ev.Type == 'Hookup'
			);
			firstEventDate = filteredEvents[0].DateLocal;
			lastEventDate = filteredEvents[filteredEvents.length - 1].DateLocal;

			if (this.isHookupActive) {
				seconds = moment(moment()).diff(
					moment(firstEventDate),
					'seconds'
				);
			} else {
				seconds = moment(moment(lastEventDate)).diff(
					moment(firstEventDate),
					'seconds'
				);
			}
		} else {
			filteredEvents = this.hookupEventTimeline.filter(
				(ev) => ev.Type == 'Disconnect'
			);
			firstEventDate = filteredEvents[0].DateLocal;
			lastEventDate = filteredEvents[filteredEvents.length - 1].DateLocal;

			if (this.isHookupActive) {
				seconds = moment(moment()).diff(
					moment(firstEventDate),
					'seconds'
				);
			} else {
				seconds = moment(moment(lastEventDate)).diff(
					moment(firstEventDate),
					'seconds'
				);
			}
		}

		this.hookupDuration.Hours = Math.floor(seconds / 3600);
		this.hookupDuration.Minutes = Math.floor((seconds % 3600) / 60);
		this.hookupDuration.Seconds = Math.floor(seconds % 60);
		this.hookupDuration.TotalSeconds = seconds;
		this.hookupDuration.Display = `${
			this.hookupDuration.Hours > 0 ? this.hookupDuration.Hours + ':' : ''
		}${
			this.hookupDuration.Minutes < 10
				? '0' + this.hookupDuration.Minutes
				: this.hookupDuration.Minutes
		}:${
			this.hookupDuration.Seconds < 10
				? '0' + this.hookupDuration.Seconds
				: this.hookupDuration.Seconds
		}`;
	}

	updateAircraftDockedDuration() {
		let minutes, hours, days;
		let pbbDockedDate = this.hookupEventsMap.get('PBBDocked')?.DateLocal;
		let pbbUndockedDate =
			this.hookupEventsMap.get('PBBUndocked')?.DateLocal;

		if (moment(pbbUndockedDate).isAfter(moment(pbbDockedDate))) {
			minutes = moment(moment(pbbUndockedDate)).diff(
				moment(pbbDockedDate),
				'minutes'
			);
			hours = moment(moment(pbbUndockedDate)).diff(
				moment(pbbDockedDate),
				'hours'
			);
			days = moment(moment(pbbUndockedDate)).diff(
				moment(pbbDockedDate),
				'days'
			);
		} else {
			minutes = moment(moment()).diff(moment(pbbDockedDate), 'minutes');
			hours = moment(moment()).diff(moment(pbbDockedDate), 'hours');
			days = moment(moment()).diff(moment(pbbDockedDate), 'days');
		}

		this.aircraftDockedTimer = days ? `${days}d, ` : '';
		this.aircraftDockedTimer += hours
			? `${days ? hours - days * 24 : hours}h, `
			: '';
		this.aircraftDockedTimer += minutes
			? `${hours ? minutes - hours * 60 : minutes}m`
			: '0m';
	}

	updatePBBDockedEvent() {
		let pbbIndex = this.HookupChartDefinition.SeriesOneData.findIndex(
			(d) => d.name == 'PBB'
		);
		let pbbDrivingData = this.HookupChartDefinition.SeriesOneData[pbbIndex];
		let pbbDrivingChartData = this.HookupChart.series[0].data[pbbIndex];
		let pbbDrivingEvent = this.hookupEventsMap.get('PBBMoveStart');
		let pbbDockedEvent = this.hookupEventsMap.get('PBBDocked');

		if (!pbbDrivingChartData.startTimeMS) {
			// Add the Start Time of the Event
			pbbDrivingChartData.startTimeMS = moment(
				pbbDrivingEvent?.DateLocal
			).valueOf();
			pbbDrivingChartData.startTimeTxt = moment(
				pbbDrivingEvent?.DateLocal
			).format('hh:mm:ss a');
		}

		if (!pbbDockedEvent?.DateLocal) {
			// Update Values and Chart if Event has not yet occured
			pbbDrivingData.high = moment(moment()).diff(
				moment(pbbDrivingEvent?.DateLocal),
				'seconds'
			);
			pbbDrivingData.low = 0;
			pbbDrivingData.duration = pbbDrivingData.high - pbbDrivingData.low;
			pbbDrivingData.overtime =
				pbbDrivingData.duration > pbbDrivingData.limit
					? Math.abs(pbbDrivingData.duration - pbbDrivingData.limit)
					: 0;

			pbbDrivingChartData.update({
				high: pbbDrivingData.high,
				low: pbbDrivingData.low,
				duration: pbbDrivingData.duration,
				overtime: pbbDrivingData.overtime,
			});

			if (
				pbbDrivingData.duration > pbbDrivingData.limit &&
				pbbDrivingData.color == 'green'
			) {
				pbbDrivingChartData.update({ color: 'red' });
			} // Update Color only if limit is passed
		} else {
			// Add event time once it has occured and stop updating timer
			this.activeTimers.GPUOn = !this.hookupEventsMap.has('GPUOn');
			this.activeTimers.PCAOn = !this.hookupEventsMap.has('PCAOn');
			this.activeTimers.PBBDocked = false;
			this.aircraftDockedInterval = setInterval(
				() => this.updateAircraftDockedDuration(),
				10000
			);

			pbbDrivingChartData.update({
				endTimeMS: pbbDockedEvent.DateMS,
				endTimeTXT: moment(pbbDockedEvent.DateMS).format('hh:mm:ss a'),
				endTimeTxtSite: moment(
					this.utilityService.convertFromUtcToLocalToSite(
						pbbDockedEvent.DateMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a'),
			});
		}
	}

	updatePCAOnEvent() {
		let pcaIndex = this.HookupChartDefinition.SeriesOneData.findIndex(
			(d) => d.name == 'PCA'
		);
		let pcaOnData = this.HookupChartDefinition.SeriesOneData[pcaIndex];
		let pcaOnChartData = this.HookupChart.series[0].data[pcaIndex];
		let pbbDockedEvent = this.hookupEventsMap.get('PBBDocked');
		let pcaOnEvent = this.hookupEventsMap.get('PCAOn');
		let disconnectEvent = this.hookupEventsMap.has('GPUSwitchedOver')
			? this.hookupEventsMap.get('GPUSwitchedOver')
			: this.hookupEventsMap.get('DisconnectStart');

		if (!pcaOnChartData.startTimeMS) {
			// Add the Start Time of the Event
			pcaOnChartData.startTimeMS = moment(
				pbbDockedEvent?.DateLocal
			).valueOf();
			pcaOnChartData.startTimeTxt = moment(
				pbbDockedEvent?.DateLocal
			).format('hh:mm:ss a');
		}

		if (!pcaOnEvent?.DateLocal && !disconnectEvent) {
			// Update Values and Chart if Event has not yet occured
			if (!pcaOnData.low) {
				pcaOnData.low =
					this.HookupChartDefinition.SeriesOneData[0].high;
				pcaOnChartData.low = pcaOnData.low;
			} // Set the initial low value

			pcaOnData.high =
				pcaOnData.low +
				moment(moment()).diff(
					moment(pbbDockedEvent?.DateLocal),
					'seconds'
				);
			pcaOnData.duration = pcaOnData.high - pcaOnData.low;
			pcaOnData.overtime =
				pcaOnData.duration > pcaOnData.limit
					? Math.abs(pcaOnData.duration - pcaOnData.limit)
					: 0;

			pcaOnChartData.update({
				high: pcaOnData.high,
				duration: pcaOnData.duration,
				overtime: pcaOnData.overtime,
			});

			if (pcaOnData.overtime > 0 && pcaOnData.color == 'green') {
				pcaOnChartData.update({ color: 'red' });
			} // Update Color only if limit is passed
		} else {
			// Add event time once it has occured and stop updating timer
			this.activeTimers.PCAOn = false;
			let terminatingEvent = disconnectEvent
				? disconnectEvent
				: pcaOnEvent;

			pcaOnChartData.update({
				endTimeMS: terminatingEvent.DateMS,
				endTimeTXT: moment(terminatingEvent.DateMS).format(
					'hh:mm:ss a'
				),
				endTimeTxtSite: moment(
					this.utilityService.convertFromUtcToLocalToSite(
						terminatingEvent.DateMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a'),
			});
		}
	}

	updateGPUOnEvent() {
		let gpuIndex = this.HookupChartDefinition.SeriesOneData.findIndex(
			(d) => d.name == 'GPU'
		);
		let gpuOnData = this.HookupChartDefinition.SeriesOneData[gpuIndex];
		let gpuOnChartData = this.HookupChart.series[0].data[gpuIndex];
		let pbbDockedEvent = this.hookupEventsMap.get('PBBDocked');
		let gpuOnEvent = this.hookupEventsMap.get('GPUOn');
		let disconnectEvent = this.hookupEventsMap.get('DisconnectStart');

		if (!gpuOnChartData.startTimeMS) {
			// Add the Start Time of the Event
			gpuOnChartData.startTimeMS = moment(
				pbbDockedEvent?.DateLocal
			).valueOf();
			gpuOnChartData.startTimeTxt = moment(
				pbbDockedEvent?.DateLocal
			).format('hh:mm:ss a');
		}

		if (!gpuOnEvent?.DateLocal && !disconnectEvent) {
			// Update Values and Chart if Event has not yet occured
			if (!gpuOnData.low) {
				gpuOnData.low =
					this.HookupChartDefinition.SeriesOneData[0].high;
				gpuOnChartData.low = gpuOnData.low;
			} // Set the initial low value

			gpuOnData.high =
				gpuOnData.low +
				moment(moment()).diff(
					moment(pbbDockedEvent?.DateLocal),
					'seconds'
				);
			gpuOnData.duration = gpuOnData.high - gpuOnData.low;
			gpuOnData.overtime =
				gpuOnData.duration > gpuOnData.limit
					? Math.abs(gpuOnData.duration - gpuOnData.limit)
					: 0;

			gpuOnChartData.update({
				high: gpuOnData.high,
				duration: gpuOnData.duration,
				overtime: gpuOnData.overtime,
			});

			if (
				gpuOnData.duration > gpuOnData.limit &&
				gpuOnData.color == 'green'
			) {
				gpuOnChartData.update({ color: 'red' });
			} // Update Color only if limit is passed
		} else {
			// Add event time once it has occured and stop updating timer
			this.activeTimers.GPUOn = false;
			this.activeTimers.GPUSwitchedOver = true;
			let terminatingEvent = disconnectEvent
				? disconnectEvent
				: gpuOnEvent;

			gpuOnChartData.update({
				endTimeMS: terminatingEvent.DateMS,
				endTimeTXT: moment(terminatingEvent.DateMS).format(
					'hh:mm:ss a'
				),
				endTimeTxtSite: moment(
					this.utilityService.convertFromUtcToLocalToSite(
						terminatingEvent.DateMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a'),
			});
		}
	}

	updateGPUSwitchoverEvent() {
		let gpuSwitchoverData = this.HookupChartDefinition.SeriesTwoData[0];
		let gpuSwitchoverChartData = this.HookupChart.series[1].data[0];
		let gpuOnEvent = this.hookupEventsMap.get('GPUOn');
		let gpuSwitchedOverEvent = this.hookupEventsMap.get('GPUSwitchedOver');
		let disconnectEvent = this.hookupEventsMap.get('DisconnectStart');

		if (!gpuSwitchoverChartData.startTimeMS) {
			// Add the Start Time of the Event
			gpuSwitchoverChartData.startTimeMS = moment(
				gpuOnEvent?.DateLocal
			).valueOf();
			gpuSwitchoverChartData.startTimeTxt = moment(
				gpuOnEvent?.DateLocal
			).format('hh:mm:ss a');
		}

		if (!gpuSwitchedOverEvent?.DateLocal && !disconnectEvent) {
			if (!gpuSwitchoverData.low) {
				gpuSwitchoverData.low =
					this.HookupChartDefinition.SeriesOneData[1].high;
				gpuSwitchoverChartData.low = gpuSwitchoverData.low;
			} // Set the initial low value

			gpuSwitchoverData.high =
				gpuSwitchoverData.low +
				moment(moment()).diff(moment(gpuOnEvent?.DateLocal), 'seconds');
			gpuSwitchoverData.duration =
				gpuSwitchoverData.high - gpuSwitchoverData.low;
			gpuSwitchoverData.overtime =
				gpuSwitchoverData.duration > gpuSwitchoverData.limit
					? Math.abs(
							gpuSwitchoverData.duration - gpuSwitchoverData.limit
					  )
					: 0;

			gpuSwitchoverChartData.update({
				high: gpuSwitchoverData.high,
				duration: gpuSwitchoverData.duration,
				overtime: gpuSwitchoverData.overtime,
			});

			if (
				gpuSwitchoverData.duration > gpuSwitchoverData.limit &&
				gpuSwitchoverData.color == 'green'
			) {
				gpuSwitchoverChartData.update({ color: 'red' });
			} // Update Color only if limit is passed
		} else {
			// Add event time once it has occured and stop updating timer
			this.activeTimers.GPUSwitchedOver = false;
			let terminatingEvent = disconnectEvent
				? disconnectEvent
				: gpuSwitchedOverEvent;

			gpuSwitchoverChartData.update({
				endTimeMS: terminatingEvent.DateMS,
				endTimeTXT: moment(terminatingEvent.DateMS).format(
					'hh:mm:ss a'
				),
				endTimeTxtSite: moment(
					this.utilityService.convertFromUtcToLocalToSite(
						terminatingEvent.DateMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a'),
			});
		}
	}

	updatePCAOffEvent() {
		let pcaIndex = this.DisconnectChartDefinition.SeriesData.findIndex(
			(d) => d.name == 'PCA'
		);
		let pcaOffData = this.DisconnectChartDefinition.SeriesData[pcaIndex];
		let pcaOffChartData = this.DisconnectChart.series[0].data[pcaIndex];
		let previousEventDate =
			this.DisconnectChartDefinition.SeriesData[0].endTimeMS;
		let pcaOffEvent = this.hookupEventsMap.get('PCAOff');

		if (!pcaOffChartData.startTimeMS) {
			// Add the Start Time of the Event
			pcaOffChartData.startTimeMS = moment(
				pcaOffEvent?.DateLocal
			).valueOf();
			pcaOffChartData.startTimeTxt = moment(
				pcaOffEvent?.DateLocal
			).format('hh:mm:ss a');
		}

		if (!pcaOffEvent?.DateLocal) {
			// Update Values and Chart if Event has not yet occured
			if (!pcaOffData.low) {
				pcaOffData.low =
					this.DisconnectChartDefinition.SeriesData[0].high;
				pcaOffChartData.low = pcaOffData.low;
			} // Set the initial low value

			pcaOffData.high =
				pcaOffData.low +
				moment(moment()).diff(moment(previousEventDate), 'seconds');
			pcaOffData.duration = pcaOffData.high - pcaOffData.low;
			pcaOffData.overtime =
				pcaOffData.duration > pcaOffData.limit
					? Math.abs(pcaOffData.duration - pcaOffData.limit)
					: 0;

			pcaOffChartData.update({
				high: pcaOffData.high,
				duration: pcaOffData.duration,
				overtime: pcaOffData.overtime,
			});

			if (
				pcaOffData.duration > pcaOffData.limit &&
				pcaOffData.color == 'green'
			) {
				pcaOffChartData.update({ color: 'red' });
			} // Update Color only if limit is passed
		} else {
			// Add event time once it has occured and stop updating timer
			this.activeTimers.PCAOff = false;

			pcaOffChartData.update({
				endTimeMS: pcaOffEvent.DateMS,
				endTimeTXT: moment(pcaOffEvent.DateMS).format('hh:mm:ss a'),
				endTimeTxtSite: moment(
					this.utilityService.convertFromUtcToLocalToSite(
						pcaOffEvent.DateMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a'),
			});
		}
	}

	updateGPUOffEvent() {
		let gpuIndex = this.DisconnectChartDefinition.SeriesData.findIndex(
			(d) => d.name == 'GPU'
		);
		let gpuOffData = this.DisconnectChartDefinition.SeriesData[gpuIndex];
		let gpuOffChartData = this.DisconnectChart.series[0].data[gpuIndex];
		let previousEventDate =
			this.DisconnectChartDefinition.SeriesData[0].endTimeMS;
		let gpuOffEvent = this.hookupEventsMap.get('GPUOff');

		if (!gpuOffChartData.startTimeMS) {
			// Add the Start Time of the Event
			gpuOffChartData.startTimeMS = gpuOffData.startTimeMS;
			gpuOffChartData.startTimeTxt = gpuOffData.startTimeTxt;
		}

		if (!gpuOffEvent?.DateLocal) {
			// Update Values and Chart if Event has not yet occured
			if (!gpuOffData.low) {
				gpuOffData.low =
					this.DisconnectChartDefinition.SeriesData[0].high;
				gpuOffChartData.low = gpuOffData.low;
			} // Set the initial low value

			gpuOffData.high =
				gpuOffData.low +
				moment(moment()).diff(moment(previousEventDate), 'seconds');
			gpuOffData.duration = gpuOffData.high - gpuOffData.low;
			gpuOffData.overtime =
				gpuOffData.duration > gpuOffData.limit
					? Math.abs(gpuOffData.duration - gpuOffData.limit)
					: 0;

			gpuOffChartData.update({
				high: gpuOffData.high,
				duration: gpuOffData.duration,
				overtime: gpuOffData.overtime,
			});

			if (
				gpuOffData.duration > gpuOffData.limit &&
				gpuOffData.color == 'green'
			) {
				gpuOffChartData.update({ color: 'red' });
			} // Update Color only if limit is passed
		} else {
			// Add event time once it has occured and stop updating timer
			this.activeTimers.GPUOff = false;

			gpuOffChartData.update({
				endTimeMS: gpuOffEvent.DateMS,
				endTimeTXT: moment(gpuOffEvent.DateMS).format('hh:mm:ss a'),
				endTimeTxtSite: moment(
					this.utilityService.convertFromUtcToLocalToSite(
						gpuOffEvent.DateMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a'),
			});
		}
	}

	updatePBBUndockedEvent() {
		let pbbIndex = this.DisconnectChartDefinition.SeriesData.findIndex(
			(d) => d.name == 'PBB'
		);
		let pbbUndockedData =
			this.DisconnectChartDefinition.SeriesData[pbbIndex];
		let pbbUndockedChartData =
			this.DisconnectChart.series[0].data[pbbIndex];
		let previousEventDate = this.DisconnectChartDefinition.SeriesData[0]
			.endTimeMS
			? this.DisconnectChartDefinition.SeriesData[0].endTimeMS
			: this.DisconnectChartDefinition.SeriesData[0].startTimeMS;
		let stoppingEvent = this.hookupEventsMap.has('PBBMoveStart')
			? this.hookupEventsMap.get('PBBMoveAway')
			: this.hookupEventsMap.get('PBBUndocked');

		if (!pbbUndockedChartData.startTimeMS) {
			// Add the Start Time of the Event
			pbbUndockedChartData.startTimeMS = pbbUndockedData.startTimeMS;
			pbbUndockedChartData.startTimeTxt = pbbUndockedData.startTimeTxt;
		}

		if (!stoppingEvent?.DateLocal) {
			// Update Values and Chart if Event has not yet occured
			if (pbbUndockedData.low == undefined) {
				pbbUndockedData.low = this.DisconnectChartDefinition
					.SeriesData[0].high
					? this.DisconnectChartDefinition.SeriesData[0].high
					: 0;
				pbbUndockedChartData.low = pbbUndockedData.low;
			} // Set the initial low value

			pbbUndockedData.high =
				pbbUndockedData.low +
				moment(moment()).diff(moment(previousEventDate), 'seconds');
			pbbUndockedData.duration =
				pbbUndockedData.high - pbbUndockedData.low;
			pbbUndockedData.overtime =
				pbbUndockedData.duration > pbbUndockedData.limit
					? Math.abs(pbbUndockedData.duration - pbbUndockedData.limit)
					: 0;

			pbbUndockedChartData.update({
				high: pbbUndockedData.high,
				duration: pbbUndockedData.duration,
				overtime: pbbUndockedData.overtime,
			});

			if (
				pbbUndockedData.duration > pbbUndockedData.limit &&
				pbbUndockedData.color == 'green'
			) {
				pbbUndockedChartData.update({ color: 'red' });
			} // Update Color only if limit is passed
		} else {
			// Add event time once it has occured and stop updating timer
			this.activeTimers.PBBUndocked = false;
			this.activeTimers.PBBMoveAway = false;
			this.isHookupActive = false;
			clearInterval(this.aircraftDockedInterval);
			clearInterval(this.hookupTimerInterval);

			pbbUndockedChartData.update({
				endTimeMS: stoppingEvent.DateMS,
				endTimeTXT: moment(stoppingEvent.DateMS).format('hh:mm:ss a'),
				endTimeTxtSite: moment(
					this.utilityService.convertFromUtcToLocalToSite(
						stoppingEvent.DateMS,
						this.siteUTCTimeOffset
					)
				).format('hh:mm:ss a'),
			});
		}
	}

	createHookupChart() {
		let innerChartTextColor =
			this.theme === 'dark'
				? 'white'
				: this.sassHelper.readProperty('body-text-color-dark');
		let outerChartTextColor =
			this.theme === 'dark'
				? this.sassHelper.readProperty('body-text-color-light')
				: this.sassHelper.readProperty('body-text-color-dark');

		var chartOptions = {
			chart: {
				type: 'columnrange',
				backgroundColor:
					this.theme === 'dark' ? 'rgb(39,41,61)' : 'white',
				style: {
					fontFamily: 'Poppins, sans-serif',
					color: outerChartTextColor,
				},
				inverted: true,
			},
			animation: false,
			exporting: { enabled: false },
			credits: { enabled: false },
			title: {
				text: '',
				style: {
					color: outerChartTextColor,
				},
			},
			xAxis: {
				type: 'category',
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					align: 'right',
					reserveSpace: true,
					useHTML: true,
					formatter: function () {
						const iconURL =
							this.chart.userOptions.series[0].data.find(
								(series) => series.name == this.value
							).statusIcon;
						return iconURL
							? `<img src='/${iconURL}' width='20' height='20'> <span style="margin-right:16px"> ${this.value}</span>`
							: this.value;
					},
					style: {
						fontSize: '12px',
						wordWrap: 'break word',
						fontFamily: 'Verdana, sans-serif',
						color: outerChartTextColor,
					},
				},
			},
			yAxis: {
				title: {
					text: '',
				},
				tickPositions: [0, 60, 120, 180, 240, 300],
				tickInterval: 60,
				minorGridLineWidth: this.theme === 'dark' ? 0.5 : 1,
				labels: {
					formatter: function () {
						return `${this.value / 60} m`;
					},
					style: {
						color: outerChartTextColor,
					},
				},
			},
			tooltip: {
				formatter: function () {
					const formattedDuration = `${
						Math.floor(this.point.duration / 3600) > 0
							? Math.floor(this.point.duration / 3600) + ':'
							: ''
					}${
						Math.floor((this.point.duration % 3600) / 60) >= 10
							? Math.floor((this.point.duration % 3600) / 60)
							: '0' +
							  Math.floor((this.point.duration % 3600) / 60)
					}:${
						this.point.duration % 60
							? this.point.duration % 60 < 10
								? '0' + (this.point.duration % 60)
								: this.point.duration % 60
							: '00'
					}`;
					const formattedOvertime = `${
						Math.floor(this.point.overtime / 3600) > 0
							? Math.floor(this.point.overtime / 3600) + ':'
							: ''
					}${
						Math.floor((this.point.overtime % 3600) / 60) >= 10
							? Math.floor((this.point.overtime % 3600) / 60)
							: '0' +
							  Math.floor((this.point.overtime % 3600) / 60)
					}:${
						this.point.overtime % 60
							? this.point.overtime % 60 < 10
								? '0' + (this.point.overtime % 60)
								: this.point.overtime % 60
							: '00'
					}`;
					const formattedTarget = `${Math.floor(
						this.point.limit / 60
					)}:${
						this.point.limit % 60
							? this.point.limit % 60 < 10
								? '0' + (this.point.limit % 60)
								: this.point.limit % 60
							: '00'
					}`;

					return `<b>${this.point.eventName}</b> <br/>
					  Timer Start: ${
							this.point.timeZoneType === 'User Time'
								? this.point.startTimeTxt
								: this.point.startTimeTxtSite
						} <br/>
					  Timer End: ${
							this.point.timeZoneType === 'User Time'
								? this.point.endTimeTxt
								: this.point.endTimeTxtSite
						} <br/>
					  Duration: ${formattedDuration}<br/>
					  Target: ${formattedTarget}<br/>
					  Overtime: ${formattedOvertime}`;
				},
			},
			plotOptions: {
				series: {
					animation: {
						duration: 500,
					},
					stacking: 'normal',
					lineWidth: 1,
					states: {
						inactive: {
							opacity: 1,
						},
					},
				},
				columnrange: {
					dataLabels: {
						enabled: true,
						inside: true,
						color: innerChartTextColor,
						align: 'center',
						formatter: function () {
							return `${
								Math.floor(this.point.duration / 3600) > 0
									? Math.floor(this.point.duration / 3600) +
									  ':'
									: ''
							}${
								Math.floor((this.point.duration % 3600) / 60) >=
								10
									? Math.floor(
											(this.point.duration % 3600) / 60
									  )
									: '0' +
									  Math.floor(
											(this.point.duration % 3600) / 60
									  )
							}:${
								this.point.duration % 60
									? this.point.duration % 60 < 10
										? '0' + (this.point.duration % 60)
										: this.point.duration % 60
									: '00'
							}`;
						},
						style: {
							fontWeight: 'bold',
							fontSize: 'small',
						},
					},
				},
			},
			legend: {
				enabled: false,
			},
			series: [
				{
					name: 'Hookup Events 1',
					data: this.HookupChartDefinition.SeriesOneData,
				},
				{
					name: 'Hookup Events 2',
					data: this.HookupChartDefinition.SeriesTwoData,
				},
			],
		};

		this.zone.runOutsideAngular(() => {
			this.HookupChart = Highcharts.chart(
				'gsPerfectHookup-Hookup' + this.widgetObject.WidgetId,
				chartOptions
			);
		});
	}

	createDisconnectChart() {
		let innerChartTextColor =
			this.theme === 'dark'
				? 'white'
				: this.sassHelper.readProperty('body-text-color-dark');
		let outerChartTextColor =
			this.theme === 'dark'
				? this.sassHelper.readProperty('body-text-color-light')
				: this.sassHelper.readProperty('body-text-color-dark');

		var chartOptions = {
			chart: {
				type: 'columnrange',
				backgroundColor:
					this.theme === 'dark' ? 'rgb(39,41,61)' : 'white',
				style: {
					fontFamily: 'Poppins, sans-serif',
					color: outerChartTextColor,
				},
				inverted: true,
			},
			animation: false,
			exporting: { enabled: false },
			credits: { enabled: false },
			title: {
				text: '',
				style: {
					color: outerChartTextColor,
				},
			},
			xAxis: {
				type: 'category',
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					align: 'right',
					reserveSpace: true,
					useHTML: true,
					formatter: function () {
						const iconURL =
							this.chart.userOptions.series[0].data.find(
								(series) => series.name == this.value
							).statusIcon;
						return iconURL
							? `<img src='/${iconURL}' width='20' height='20'> <span style="margin-right:16px"> ${this.value}</span>`
							: this.value;
					},
					style: {
						fontSize: '12px',
						wordWrap: 'break word',
						fontFamily: 'Verdana, sans-serif',
						color: outerChartTextColor,
					},
				},
			},
			yAxis: {
				title: {
					text: '',
				},
				tickPositions: [0, 60, 120, 180, 240, 300],
				tickInterval: 60,
				minorGridLineWidth: this.theme === 'dark' ? 0.5 : 1,
				labels: {
					formatter: function () {
						return `${this.value / 60} m`;
					},
					style: {
						color: outerChartTextColor,
					},
				},
			},
			tooltip: {
				formatter: function () {
					const formattedDuration = `${
						Math.floor(this.point.duration / 3600) > 0
							? Math.floor(this.point.duration / 3600) + ':'
							: ''
					}${
						Math.floor((this.point.duration % 3600) / 60) >= 10
							? Math.floor((this.point.duration % 3600) / 60)
							: '0' +
							  Math.floor((this.point.duration % 3600) / 60)
					}:${
						this.point.duration % 60
							? this.point.duration % 60 < 10
								? '0' + (this.point.duration % 60)
								: this.point.duration % 60
							: '00'
					}`;
					const formattedOvertime = `${
						Math.floor(this.point.overtime / 3600) > 0
							? Math.floor(this.point.overtime / 3600) + ':'
							: ''
					}${
						Math.floor((this.point.overtime % 3600) / 60) >= 10
							? Math.floor((this.point.overtime % 3600) / 60)
							: '0' +
							  Math.floor((this.point.overtime % 3600) / 60)
					}:${
						this.point.overtime % 60
							? this.point.overtime % 60 < 10
								? '0' + (this.point.overtime % 60)
								: this.point.overtime % 60
							: '00'
					}`;
					const formattedTarget = `${Math.floor(
						this.point.limit / 60
					)}:${
						this.point.limit % 60
							? this.point.limit % 60 < 10
								? '0' + (this.point.limit % 60)
								: this.point.limit % 60
							: '00'
					}`;

					return `<b>${this.point.eventName}</b> <br/>
					  Timer Start: ${
							this.point.timeZoneType === 'User Time'
								? this.point.startTimeTxt
								: this.point.startTimeTxtSite
						} <br/>
					  Timer End: ${
							this.point.timeZoneType === 'User Time'
								? this.point.endTimeTxt
								: this.point.endTimeTxtSite
						} <br/>
					  Duration: ${formattedDuration}<br/>
					  Target: ${formattedTarget}<br/>
					  Overtime: ${formattedOvertime}`;
				},
			},
			plotOptions: {
				series: {
					stacking: 'normal',
					lineWidth: 1,
					states: {
						inactive: {
							opacity: 1,
						},
					},
				},
				columnrange: {
					dataLabels: {
						enabled: true,
						inside: true,
						color: innerChartTextColor,
						align: 'center',
						formatter: function () {
							return `${
								Math.floor(this.point.duration / 3600) > 0
									? Math.floor(this.point.duration / 3600) +
									  ':'
									: ''
							}${
								Math.floor((this.point.duration % 3600) / 60) >=
								10
									? Math.floor(
											(this.point.duration % 3600) / 60
									  )
									: '0' +
									  Math.floor(
											(this.point.duration % 3600) / 60
									  )
							}:${
								this.point.duration % 60
									? this.point.duration % 60 < 10
										? '0' + (this.point.duration % 60)
										: this.point.duration % 60
									: '00'
							}`;
						},
						style: {
							fontWeight: 'bold',
							fontSize: 'small',
						},
					},
				},
			},
			legend: {
				enabled: false,
			},
			series: [
				{
					name: 'Disconect Events',
					data: this.DisconnectChartDefinition.SeriesData,
				},
			],
		};

		this.zone.runOutsideAngular(() => {
			this.DisconnectChart = Highcharts.chart(
				'gsPerfectHookup-Hookup' + this.widgetObject.WidgetId,
				chartOptions
			);
		});
	}

	matTabHasChanged(e) {
		this.selectedMatTabLabel = e.tab.textLabel;
		switch (this.selectedMatTabLabel) {
			case 'Current':
				if (!this.chartRedrawn.ThemeSwitch.Current && this.hasData) {
					this.chartRedrawn.ThemeSwitch.Current = true;
					this.HookupChart && this.createHookupChart();
					this.DisconnectChart && this.createDisconnectChart();
				}
				if (!this.chartRedrawn.Resize.Current) {
					this.chartRedrawn.Resize.Current = true;
					this.HookupChart &&
						this.HookupChart.setSize(
							$(
								'#gsPerfectHookup-Hookup' +
									this.widgetObject.WidgetId
							).width(),
							$(
								'#gsPerfectHookup-Hookup' +
									this.widgetObject.WidgetId
							).height(),
							false
						);
					this.DisconnectChart &&
						this.DisconnectChart.setSize(
							$(
								'#gsPerfectHookup-Hookup' +
									this.widgetObject.WidgetId
							).width(),
							$(
								'#gsPerfectHookup-Hookup' +
									this.widgetObject.WidgetId
							).height(),
							false
						);
				}
				break;

			case 'Statistics':
				if (!this.chartRedrawn.ThemeSwitch.Historic && this.hasData) {
					this.chartRedrawn.ThemeSwitch.Historic = true;
					this.createHistoricChart();
				}
				if (!this.chartRedrawn.Resize.Historic && this.HistoricChart) {
					this.chartRedrawn.Resize.Historic = true;
					this.HistoricChart.setSize(
						$(
							'#gsPerfectHookupHistoric' +
								this.widgetObject.WidgetId
						).width(),
						$(
							'#gsPerfectHookupHistoric' +
								this.widgetObject.WidgetId
						).height(),
						false
					);
				}
				break;
		}
	}

	resetWidget() {
		this.selectedMatTabIndex = 0;
		this.selectedMatTabLabel = this.dashboardService.currentDashboard.timeScopeId !== null ? 'Current': 'Historic';
		this.activeTimers = {
			PBBMoveStart: false,
			PBBDocked: false,
			PCAOn: false,
			GPUOn: false,
			GPUSwitchedOver: false,
			GPUOff: false,
			PCAOff: false,
			PBBUndocked: false,
			PBBMoveAway: false,
		};
		this.isHookupActive = false;
		this.hookupEventsMap.clear();
		this.hookupEventTimeline.splice(0, this.hookupEventTimeline.length);
		this.HookupChartDefinition.SeriesOneData = [];
		this.HookupChartDefinition.SeriesTwoData = [];
		this.DisconnectChartDefinition.SeriesData = [];
		this.pastDisconnectEvents = [];
		this.allPastEvents = [];
		this.pastHookupEvents = [];
		this.aircraftDockedInterval &&
			clearInterval(this.aircraftDockedInterval);
		this.hookupTimerInterval && clearInterval(this.hookupTimerInterval);
	}

	// DatesEqualWithinThreshold - check if dates are equal +/- the threshold amount
	//	date1 - date in ms
	//  date2 - date in ms
	//  threshold (default=1000ms)

	DatesEqualWithinThreshold(date1, date2, thresholdInMS = 1000): boolean {
		if (date1 >= date2 - thresholdInMS && date1 <= date2 + thresholdInMS) {
			return true;
		}
		return false;
	}

	processEvent(dockToUndockRow) {
		this.dockRow = dockToUndockRow;

		// check if this matches existing hookup in progress, only proceed if we have a value close, otherwise don't process
		if (
			this.hookupEventsMap.has('PBBMoveStart') &&
			dockToUndockRow.PBBMoveStart
		) {
			if (
				!this.DatesEqualWithinThreshold(
					this.hookupEventsMap.get('PBBMoveStart').DateMS,
					dockToUndockRow.PBBMoveStartMS
				)
			) {
				return;
			}
		} else if (
			this.hookupEventsMap.has('PBBDocked') &&
			dockToUndockRow.PBBDocked
		) {
			if (
				!this.DatesEqualWithinThreshold(
					this.hookupEventsMap.get('PBBDocked').DateMS,
					dockToUndockRow.PBBDockedMS
				)
			) {
				return;
			}
		}

		if (
			!this.hookupEventsMap.has('PBBMoveStart') &&
			dockToUndockRow.PBBMoveStart
		) {
			if (this.isHookupActive) {
				this.hookupEventsMap.set('PBBMoveStart', {
					Name: 'PBB Driving',
					EventCode: 'PBBMoveStart',
					DateMS: dockToUndockRow.PBBMoveStartMS,
					DateLocal: new Date(dockToUndockRow.PBBMoveStartMS),
					DateSite: this.utilityService.convertFromUtcToLocalToSite(
						dockToUndockRow.PBBMoveStartMS,
						this.siteUTCTimeOffset
					),
					Type: 'Hookup',
				});
				this.hookupEventTimeline.push(
					this.hookupEventsMap.get('PBBMoveStart')
				);
				this.activeTimers.PBBDocked = true;
				this.hookupStatus = 'Connecting';
			} else {
				this.startNewHookup(dockToUndockRow);
			}
		}

		if (
			!this.hookupEventsMap.has('PBBDocked') &&
			dockToUndockRow.PBBDocked
		) {
			this.hookupEventsMap.set('PBBDocked', {
				Name: 'PBB Docked',
				EventCode: 'PBBDocked',
				DateMS: dockToUndockRow.PBBDockedMS,
				DateLocal: new Date(dockToUndockRow.PBBDockedMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.PBBDockedMS,
					this.siteUTCTimeOffset
				),
				Type: 'Hookup',
			});
			this.hookupEventTimeline.push(
				this.hookupEventsMap.get('PBBDocked')
			);
			this.hookupStatus = 'Connected';

			if (
				this.hookupEventsMap.has('PCAOn') &&
				this.hookupEventsMap.has('GPUSwitchedOver')
			) {
				this.isHookupActive = false;
				clearInterval(this.hookupTimerInterval);
			}
		}

		if (!this.hookupEventsMap.has('GPUOn') && dockToUndockRow.GPUOn) {
			if (this.isHookupActive) {
				this.hookupEventsMap.set('GPUOn', {
					Name: 'GPU Turned On',
					EventCode: 'GPUOn',
					DateMS: dockToUndockRow.GPUOnMS,
					DateLocal: new Date(dockToUndockRow.GPUOnMS),
					DateSite: this.utilityService.convertFromUtcToLocalToSite(
						dockToUndockRow.GPUOnMS,
						this.siteUTCTimeOffset
					),
					Type: 'Hookup',
				});
				this.hookupEventTimeline.push(
					this.hookupEventsMap.get('GPUOn')
				);
			} else {
				this.startNewHookup(dockToUndockRow);
			}
		}

		if (
			!this.hookupEventsMap.has('PCAOn') &&
			dockToUndockRow.PCAVentilatingAircraft
		) {
			if (!this.isHookupActive) {
				this.startNewHookup(dockToUndockRow);
			} else {
				this.hookupEventsMap.set('PCAOn', {
					Name: 'PCA Turned On',
					EventCode: 'PCAOn',
					DateMS: dockToUndockRow.PCAVentilatingAircraftMS,
					DateLocal: new Date(
						dockToUndockRow.PCAVentilatingAircraftMS
					),
					DateSite: this.utilityService.convertFromUtcToLocalToSite(
						dockToUndockRow.PCAVentilatingAircraftMS,
						this.siteUTCTimeOffset
					),
					Type: 'Hookup',
				});
				this.hookupEventTimeline.push(
					this.hookupEventsMap.get('PCAOn')
				);

				if (
					this.hookupEventsMap.has('GPUSwitchedOver') &&
					this.hookupEventsMap.has('PBBDocked')
				) {
					this.isHookupActive = false;
					clearInterval(this.hookupTimerInterval);
				}
			}
		}

		if (
			!this.hookupEventsMap.has('GPUSwitchedOver') &&
			dockToUndockRow.GPUSwitchover
		) {
			this.hookupEventsMap.set('GPUSwitchedOver', {
				Name: 'GPU Switched Over',
				EventCode: 'GPUSwitchedOver',
				DateMS: dockToUndockRow.GPUSwitchoverMS,
				DateLocal: new Date(dockToUndockRow?.GPUSwitchoverMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.GPUSwitchoverMS,
					this.siteUTCTimeOffset
				),
				Type: 'Hookup',
			});
			this.hookupEventTimeline.push(
				this.hookupEventsMap.get('GPUSwitchedOver')
			);

			if (
				this.hookupEventsMap.has('PCAOn') &&
				this.hookupEventsMap.has('PBBDocked')
			) {
				this.isHookupActive = false;
				clearInterval(this.hookupTimerInterval);
			}
		}

		if (!this.hookupEventsMap.has('PCAOff') && dockToUndockRow.PCAOFF) {
			if (
				!this.hookupEventsMap.has('GPUOff') &&
				this.hookupEventsMap.has('PBBUndocked')
			) {
				this.startNewDisconnect(dockToUndockRow);
			} else {
				this.hookupEventsMap.set('PCAOff', {
					Name: 'PCA Turned Off',
					EventCode: 'PCAOff',
					DateMS: dockToUndockRow.PCAOFFMS,
					DateLocal: new Date(dockToUndockRow.PCAOFFMS),
					DateSite: this.utilityService.convertFromUtcToLocalToSite(
						dockToUndockRow.PCAOFFMS,
						this.siteUTCTimeOffset
					),
					Type: 'Disconnect',
				});
				this.hookupEventTimeline.push(
					this.hookupEventsMap.get('PCAOff')
				);

				if (
					this.hookupEventsMap.has('GPUOff') &&
					this.hookupEventsMap.has('PBBUndocked')
				) {
					this.isHookupActive = false;
					clearInterval(this.hookupTimerInterval);
				}
			}
		}

		if (!this.hookupEventsMap.has('GPUOff') && dockToUndockRow.GPUOFF) {
			if (
				!this.hookupEventsMap.has('PCAOff') &&
				this.hookupEventsMap.has('PBBUndocked')
			) {
				this.startNewDisconnect(dockToUndockRow);
			} else {
				this.hookupEventsMap.set('GPUOff', {
					Name: 'GPU Turned Off',
					EventCode: 'GPUOff',
					DateMS: dockToUndockRow.GPUOFFMS,
					DateLocal: dockToUndockRow.GPUOFFMS,
					DateSite: this.utilityService.convertFromUtcToLocalToSite(
						dockToUndockRow.GPUOFFMS,
						this.siteUTCTimeOffset
					),
					Type: 'Disconnect',
				});
				this.hookupEventTimeline.push(
					this.hookupEventsMap.get('GPUOff')
				);

				if (
					this.hookupEventsMap.has('PCAOff') &&
					this.hookupEventsMap.has('PBBUndocked')
				) {
					this.isHookupActive = false;
					clearInterval(this.hookupTimerInterval);
				}
			}
		}

		if (
			!this.hookupEventsMap.has('PBBUndocked') &&
			dockToUndockRow.PBBUndocked
		) {
			// if (!this.hookupEventsMap.has("GPUOff") && !this.hookupEventsMap.has("PCAOff")) {
			this.startNewDisconnect(dockToUndockRow);
			// } else {
			this.hookupEventsMap.set('PBBUndocked', {
				Name: 'PBB Undocked',
				EventCode: 'PBBUndocked',
				DateMS: dockToUndockRow.PBBUndockedMS,
				DateLocal: new Date(dockToUndockRow.PBBUndockedMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.PBBUndockedMS,
					this.siteUTCTimeOffset
				),
				Type: 'Disconnect',
			});
			this.hookupEventTimeline.push(
				this.hookupEventsMap.get('PBBUndocked')
			);

			if (
				this.hookupEventsMap.has('PCAOff') &&
				this.hookupEventsMap.has('GPUOff')
			) {
				this.isHookupActive = false;
				this.hookupTimerInterval &&
					clearInterval(this.hookupTimerInterval);
			}
			// }
		}

		if (
			!this.hookupEventsMap.has('PBBMoveAway') &&
			dockToUndockRow.PBBMoveAfterUndock
		) {
			this.hookupEventsMap.set('PBBMoveAway', {
				Name: 'PBB Driving Away',
				EventCode: 'PBBMoveAway',
				DateMS: dockToUndockRow.PBBMoveAfterUndockMS,
				DateLocal: new Date(dockToUndockRow.PBBMoveAfterUndockMS),
				DateSite: this.utilityService.convertFromUtcToLocalToSite(
					dockToUndockRow.PBBMoveAfterUndockMS,
					this.siteUTCTimeOffset
				),
				Type: 'Disconnect',
			});
			this.hookupEventTimeline.push(
				this.hookupEventsMap.get('PBBMoveAway')
			);
			this.isHookupActive = false;
			this.hookupTimerInterval && clearInterval(this.hookupTimerInterval);
		}
	}

	startNewHookup(dockToUndockRow) {
		//this.InitializeCurrentDock();

		this.aircraftDockedInterval &&
			clearInterval(this.aircraftDockedInterval);
		this.hookupTimerInterval && clearInterval(this.hookupTimerInterval);
		this.activeTimers = {
			PBBMoveStart: false,
			PBBDocked: false,
			PCAOn: false,
			GPUOn: false,
			GPUSwitchedOver: false,
			GPUOff: false,
			PCAOff: false,
			PBBUndocked: false,
			PBBMoveAway: false,
		};
		this.hookupEventsMap.clear();
		this.hookupEventTimeline.splice(0, this.hookupEventTimeline.length);
		this.HookupChartDefinition.SeriesOneData = [];
		this.HookupChartDefinition.SeriesTwoData = [];
		this.getCurrentTurnState(dockToUndockRow);
	}

	startNewDisconnect(dockToUndockRow) {
		this.activeTimers = {
			PBBMoveStart: false,
			PBBDocked: false,
			PCAOn: false,
			GPUOn: false,
			GPUSwitchedOver: false,
			GPUOff: false,
			PCAOff: false,
			PBBUndocked: false,
			PBBMoveAway: false,
		};
		this.DisconnectChartDefinition.SeriesData = [];
		this.hookupTimerInterval && clearInterval(this.hookupTimerInterval);
		this.getCurrentTurnState(dockToUndockRow);
	}

	getDatesFromDashboard() {
		let startDateQuery = new Date();
		let endDateQuery = new Date();

		let timeScopeObject: any = _.head(
			this.dashboardService.dashboardTimeScopes.filter((v) => {
				return (
					v.Id === this.dashboardService.currentDashboard.TimeScopeId
				);
			})
		);

		if (_.isNil(timeScopeObject)) {
			startDateQuery.setDate(startDateQuery.getDate() - 7);
			this.queryStartDate = moment(startDateQuery).format(
				'YYYY/MM/DD HH:mm:ss'
			);
			endDateQuery.setDate(endDateQuery.getDate());
			this.queryEndDate = moment(endDateQuery)
				.add(8, 'hour')
				.format('YYYY/MM/DD HH:mm:ss');
		} else {
			startDateQuery.setDate(
				startDateQuery.getDate() - timeScopeObject.Days
			);
			this.queryStartDate = moment(startDateQuery).format(
				'YYYY/MM/DD HH:mm:ss'
			);
			endDateQuery.setDate(endDateQuery.getDate());
			this.queryEndDate = moment(endDateQuery)
				.add(8, 'hour')
				.format('YYYY/MM/DD HH:mm:ss');
		}
	}

	openPBBslideout() {
		if(this.PBBvisible == false) {
			this.GPUvisible = false;
			this.PCAvisible = false;
			this.PBBvisible = true;
		}
		else {
			this.PBBvisible = false;
		}
	}

	openGPUslideout() {
		if(this.GPUvisible == false) {
			this.PCAvisible = false;
			this.PBBvisible = false;
			this.GPUvisible = true;
		}
		else {
			this.GPUvisible = false;
		}
	}

	openPCAslideout() {
		if(this.PCAvisible == false) {
			this.PCAvisible = true;
			this.PBBvisible = false;
			this.GPUvisible = false;
		}
		else {
			this.PCAvisible = false;
		}
	}

	openPopup(assetType: any) {

		var bounds = this.elem.nativeElement.getBoundingClientRect();

		let dlgHeight;
		let assetData;
		if (assetType == 'PBB') {
			dlgHeight = '280px';
			assetData = {
				name: 'PBB',					// name of the asset
				turn: this.turn,				// raw turn data from cache
				pbb: this.pbb,					// pbb times for display
				currentTurn: this.currentTurn,	// turn datetimes for tz
				timers: this.timers,			// turn timers
				dock: this.dock,				// overall dock times for display
				state: this.state,
				theme: this.theme,				// theme (dark or light)
				assetState: this.state.pbb,
				tags: this.tags.PBB,
				widgetObject: this.widgetObject,
			}
		}
		else if (assetType == 'PCA') {
			dlgHeight = '220px';
			assetData = {
				name: this.siteAirUnitName,
				turn: this.turn,
				pca: this.pca,
				currentTurn: this.currentTurn,
				timers: this.timers,
				dock: this.dock,
				state: this.state,
				siteAirUnitName: this.siteAirUnitName,
				theme: this.theme,
				headerLabel: 'Disch Temp',
				headerValue: this.tags.PCA[2736]?.Value,
				assetState: this.state.pca,
					tags: this.tags.PCA,
					widgetObject: this.widgetObject,
			}
		}
		else if (assetType == 'GPU') {
			dlgHeight = '240px';
			assetData = {
				name: 'GPU',
				turn: this.turn,
				gpu: this.gpu,
				gpuon: this.gpuon,
				currentTurn: this.currentTurn,
				timers: this.timers,
				dock: this.dock,
				state: this.state,
				theme: this.theme,
				headerLabel: 'Avg Amps Out',
				headerValue: this.tags.GPU[1942]?.Value,
				assetState: this.state.gpu,
					tags: this.tags.GPU,
					widgetObject: this.widgetObject,
			}
		}
		const dlg = this.dialog.open(PerfectTurnPopupDialogComponent, {
			width: '450px',
			height: dlgHeight,
				data: assetData,
				maxWidth: '100vw',
				maxHeight: '100vh',
				position: { left: bounds.left+'px', top: bounds.bottom+'px' }
			});

	}
}
