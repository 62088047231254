<sass-helper></sass-helper>
<div style="height: 100%">
	<div
		[ngClass]="
			global.isMobile
				? 'content ninjaContentMobile'
				: 'content ninjaContent'
		"
		(resized)="onResized($event)"
	>
		<lib-loading-spinner
			*ngIf="isLoading"
			[loadingMessage]="'Connecting to Ninja Processor...'"
			[vehicle]="'truck'"
		></lib-loading-spinner>
		<mat-tab-group
			(selectedTabChange)="tabHasChanged($event)"
			animationDuration="0ms"
		>
			<mat-tab [label]="'Line Chart'">
				<!-- <div style="height: 25px ">   <form [formGroup]="grid.keepLast50Group" ngNativeValidate style="display: inline">
					<mat-slide-toggle formControlName="keepLast50">Only Keep Last 50 Records (Trend Below) <i class="tim-icons icon-alert-circle-exc" tooltip="This is left on by default. Turning this off will allow records in trend to accumulate instead of taking the last 50 each update, which could cause performance issues over a long period of time. "></i></mat-slide-toggle>
				</form></div> -->
				<div class="ninjaCalcHeight">
					<div style="height: 100%">
						<div
							id="ninjaStatisticsChart"
							class="lone-chart-template"
							style="width: 100%; height: 100%"
						></div>
					</div>
				</div>

				<!-- <kendo-stockchart [style.height.%]="100">
					<kendo-chart-tooltip [shared]="true"></kendo-chart-tooltip>
					<kendo-chart-legend position="bottom" orientation="horizontal">
					</kendo-chart-legend>
					<kendo-chart-area [background]="chartBackgroundColor"> </kendo-chart-area>
					<kendo-chart-series>
						<kendo-chart-series-item type="line" [style]="style" [data]="AverageObservationsPerSecondChartData" [name]="'Average Observations Per Second'"> </kendo-chart-series-item>
						<kendo-chart-series-item type="line" [style]="style" [data]="DeletionQueueLengthChartData" [name]="'Deletion Queue Length'"> </kendo-chart-series-item>
						<kendo-chart-series-item type="line" [style]="style" [data]="ObservationInsertQueueLengthChartData" [name]="'Observation Insert Queue Length'"> </kendo-chart-series-item>
						<kendo-chart-series-item type="line" [style]="style" [data]="PausedChartData" [name]="'Paused'"> </kendo-chart-series-item>
						<kendo-chart-series-item type="line" [style]="style" [data]="TagStructureQueueLengthChartData" [name]="'Tag Structure Queue Length'"> </kendo-chart-series-item>
					</kendo-chart-series> -->

				<!-- </kendo-stockchart> -->
			</mat-tab>
			<mat-tab [label]="'Table'">
				<div [style.height.%]="100">
					<div [style.height.%]="100" *ngIf="!isLoading">

						<lib-kendo-grid-parent
							[gridData]="tableData"
							[gridSettings]="grid.gridSettings"
							[groupable]="false"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							*ngIf="tableData !== undefined"

							#tagDataGrid

						>
						</lib-kendo-grid-parent>

					</div>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
