<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Wheelchair Lift Summary Info...'"
	[vehicle]="'plane'"
></lib-loading-spinner>
<div
	*ngIf="
		!isDataLoading &&
		(!fromDashboard || widgetObject?.WidgetGateSystemId) &&
		this.assetObj != undefined
	"
	class="card card-tasks"
>
	<mat-tab-group
		[(selectedIndex)]="selectedMatTabIndex"
		animationDuration="0ms"
		(selectedTabChange)="matTabHasChanged($event)"
	>
		<mat-tab label="Data">
			<div
				(resized)="onResized($event)"
				[style.height.%]="100"
				class="subTabContainer"
			>
				<div *ngIf="!isDataLoading">
					<div *ngIf="!isDataLoading" class="parent-table-container">

						<lib-kendo-grid-parent
							[gridData]="dataService.cache.assetsObject[
							assetObj.Id
						].Tags"
							[gridSettings]="tagGridSettings"
							[widgetObject]="widgetObject"
							(callSave)="saveGridSettings()"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							*ngIf="assetObj?.Id && dataService.cache.assetsObject[assetObj.Id].Tags"

							#tagDataGrid

						>
						</lib-kendo-grid-parent>
					</div>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
<div
	*ngIf="!isDataLoading && fromDashboard && !widgetObject?.WidgetGateSystemId"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select a Gate System from the widget settings."
	></lib-centered-message>
</div>
<div
	*ngIf="this.assetObj == undefined"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Gate doesn't have WCL Unit"
	></lib-centered-message>
</div>
