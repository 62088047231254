

<div class="content" style="padding: 0 0 0 0 !important; height: calc(100%);">



	<!-- <lib-loading-spinner *ngIf="isLoading" [loadingMessage]="'Loading Issues...'" [vehicle]="'plane'"></lib-loading-spinner> -->
	<div class="content" style="height: 100%">

		<div class="card" [style.height]="global.isMobile ? 'calc(100%)' : 'calc(100vh - 95px)'" style="width: calc(100%)">
			<div class="card-header" style="height: 80px">
				<h3 class="card-title" style="display: inline; vertical-align: bottom;">Logbook</h3>
			</div>
			<div class="card-body" style="height: 75%">
				<div class="table-responsive" style="height: calc(100% - 3px)">

					<lib-kendo-grid-parent
						[gridPurpose]="'logbook'"
						[gridData]="logsArray"
						[gridSettings]="gridSettings"
						[style.height.%]="100"
						filterable="menu"
						[sortable]="true"
						[reorderable]="true"
						[resizable]="true"
						[groupable]="true"
						[exportable]="true"
						[columnMenu]="true"
						*ngIf="logsArray !== undefined"

						#logBookPanelGrid>
					</lib-kendo-grid-parent>

				</div>
			</div>
		</div>

	</div>

</div>
<!-- <router-outlet></router-outlet> -->



