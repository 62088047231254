<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Peak Report Data Info...'"
	[vehicle]="'plane'"
></lib-loading-spinner>
<div
	*ngIf="
		!isDataLoading &&
		(!fromDashboard || widgetObject?.WidgetAssetId) &&
		assetObj?.Tags != null
	"
	class="card card-tasks"
>
	<mat-tab-group
		[(selectedIndex)]="selectedMatTabIndex"
		animationDuration="0ms"
		(selectedTabChange)="matTabHasChanged($event)"
	>
		<mat-tab label="Data">
			<div
				(resized)="onResized($event)"
				[style.height.%]="100"
				[style.width.%]="100"
				class="subTabContainer"
			>
				<div *ngIf="!isDataLoading" 		[style.height.%]="100"
				[style.width.%]="100">
					<div *ngIf="!isDataLoading && assetObj?.Id && dataService.cache.assetsObject[assetObj.Id].GSEPeakReportTags" class="parent-table-container">
						<lib-kendo-grid-parent
						[gridData]="dataService.cache.assetsObject[assetObj.Id].GSEPeakReportTags"
						[gridSettings]="tagGridSettings"
						[widgetObject]="widgetObject"
						(callSave)="saveGridSettings()"
						[style.height.%]="100"
						filterable="menu"
						[sortable]="true"
						[reorderable]="true"
						[resizable]="true"
						*ngIf="tagGridSettings !== undefined"
						#tagDataGrid

					>
					</lib-kendo-grid-parent>
					</div>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
<div
	*ngIf="!isDataLoading && fromDashboard && !widgetObject?.WidgetAssetId"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select an Asset from the widget settings."
	></lib-centered-message>
</div>
<div
	*ngIf="!isDataLoading && fromDashboard && assetObj?.Tags == null"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="No Data exists for this Asset."
	></lib-centered-message>
</div>
