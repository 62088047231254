<ng-template #popTitle><h4 style="color: black"><b>{{tooltipInformationObject.title !== '' ? tooltipInformationObject.title: 'No Tag Found'}}</b></h4>
	<p style="color: black" *ngIf="tooltipInformationObject.title !== ''">Right Click To Copy To Clipboard</p>
	</ng-template>
<ng-template #popContent>
	<div *ngIf="tooltipInformationObject.title !== ''">
		<p style="color: black"><b>TagName:</b> </p>
		<p style="color: black">{{tooltipInformationObject.TagName}}</p>
		<p style="color: black"><b>TagId:</b> </p>
		<p style="color: black">{{tooltipInformationObject.TagId}}</p>
		<p style="color: black"><b>ListOfStdIdsUsed:</b> </p>
		<p style="color: black">{{tooltipInformationObject.ListOfStdIdsUsed}}</p>
		<p style="color: black"><b>StdId:</b> </p>
		<p style="color: black">{{tooltipInformationObject.StdId}}</p>
		<p style="color: black"><b>Datetime:</b> </p>
		<p style="color: black">{{tooltipInformationObject.Datetime | date :'MM/dd/yyyy&nbsp;HH:mm:ss.SSS' }}</p>
		<p style="color: black"><b>Value:</b> </p>
		<p style="color: black">{{tooltipInformationObject.Value}}</p>
		<p style="color: black"><b>ValueWhenActive:</b> </p>
		<p style="color: black">{{tooltipInformationObject.ValueWhenActive}}</p>
	</div>
	<div *ngIf="tooltipInformationObject.title === ''">
		<p style="color: black"><b>ListOfStdIdsUsed:</b> </p>
		<p style="color: black">{{tooltipInformationObject.ListOfStdIdsUsed}}</p>
	</div>

</ng-template>

<div *ngIf="!isDataLoading" class="box">
  <div class="d-flex flex-row" style="flex-wrap: wrap;">
  	<div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'unitStatus')" container="body" (mouseenter)="tooltipInformation('unitStatus')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(unitStatus)" class="col box-stat-content" *ngIf="assetTags.UnitStatus != undefined" [ngClass]="{ 'unit-on': assetTags.UnitStatus, 'unit-off': !assetTags.UnitStatus}" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
          {{assetTags.Unit}}<br>{{assetTags.RunTime}}
    </div>
  	<div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'mode')" container="body" (mouseenter)="tooltipInformation('mode')" [ngbPopover]="popContent" 
  			[popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(mode)" 
			class="col box-stat-content"
			[ngClass]="{ 
				'tag-undefined': assetTags.Mode == '-'
			}" 
			*ngIf="global.User.DebugMode || assetTags.Mode != '-'" 
			[ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
        Mode<br>
        <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.Mode}}</span>
    </div>
    <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'contractor')" container="body" (mouseenter)="tooltipInformation('contractor')" [ngbPopover]="popContent" [popoverTitle]="popTitle" 
	  		[openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(contractor)" 
			class="col box-stat-content" 
			[ngClass]="{ 
				'tag-undefined': assetTags.Contractor == '-'
			}" 
			*ngIf="global.User.DebugMode || assetTags.Contractor != '-'" 
			[ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
		 Contactor<br>
          <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.Contractor}}</span>
      </div>
      <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'estop')" container="body" (mouseenter)="tooltipInformation('estop')" 
	  		[ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(estop)" 
			class="col box-stat-content"  
			[ngClass]="{ 'tag-undefined': assetTags.Estop == '-'}" 
			*ngIf="global.User.DebugMode || assetTags.Estop != '-'" 
			[ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
          E-Stop<br>
          <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.Estop}}</span>
      </div>
  </div>
  <div class="d-flex flex-row" style="justify-content: normal;">
      <div class="flex-column">
          <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'ampInAvg')" container="body" (mouseenter)="tooltipInformation('ampInAvg')" 
		  		[ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(ampInAvg)" 
				class="col box-stat-bottom" 
				[ngClass]="{ 
					'tag-undefined': assetTags.AmpInAvg == '-'
				}"
				*ngIf="global.User.DebugMode || (assetTags.AmpInAvg != '-' && !hideCertainJBTStandardObservationIds)"  
				[ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
              AmpsIn Avg <br>
              <kendo-progressbar class= "progress-bar-styles-horizontal" [label]="label" [progressCssStyle]= "progressStyles" [value]="assetTags.AmpInAvg"></kendo-progressbar><br>
              <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.AmpInAvg}}</span>
          </div>
          <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'frequencyOut')" container="body" (mouseenter)="tooltipInformation('frequencyOut')" 
		  		[ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(frequencyOut)" 
				class="col box-stat-bottom"
				[ngClass]="{ 
					'tag-undefined': assetTags.FrequencyOut == '-'
				}" 
				*ngIf="global.User.DebugMode || assetTags.FrequencyOut != '-'" 
				[ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
              Frequency Out <br>
              <kendo-progressbar class= "progress-bar-styles-horizontal" [label]="label" [progressCssStyle]= "assetTags.FrequencyOutColor" [value]="assetTags.FrequencyOut"></kendo-progressbar><br>
              <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.FrequencyOut}}</span>
          </div>
          <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'ampOutCon1')" container="body" (mouseenter)="tooltipInformation('ampOutCon1')" 
		  		[ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(ampOutCon1)" 
				class="col box-stat-bottom" 
				[ngClass]="{ 
					'tag-undefined': assetTags.AmpOutCon1 == '-'
				}" 
				*ngIf="global.User.DebugMode || assetTags.AmpOutCon1 != '-'"  
				[ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
              AmpsOut Con1 <br>
              <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.AmpOutCon1}}</span>
          </div>
          <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'ampOutCon2')" container="body" (mouseenter)="tooltipInformation('ampOutCon2')" 
		  		[ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(ampOutCon2)" 
				class="col box-stat-bottom" 
				[ngClass]="{ 
					'tag-undefined': assetTags.AmpOutCon2 == '-'
				}" 
				*ngIf="global.User.DebugMode || (assetTags.AmpOutCon2 != '-' && !hideCertainJBTStandardObservationIds)"  
				[ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
              AmpsOut Con2 <br>
              <span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.AmpOutCon2}}</span>
          </div>
		  <div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'ampOutDC')" container="body" (mouseenter)="tooltipInformation('ampOutDC')" 
		  		[ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(ampOutDC)" 
				class="col box-stat-bottom" 
				[ngClass]="{ 
					'tag-undefined': assetTags.AmpOutDC == '-'
				}" 
				*ngIf="global.User.DebugMode || (assetTags.AmpOutDC != '-' && !hideCertainJBTStandardObservationIds)" 
				[ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
			AmpsOut DC <br>
			<span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.AmpOutDC}}</span>
		</div>
		<div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'voltsOutDC')" container="body" (mouseenter)="tooltipInformation('voltsOutDC')" 
				[ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(voltsOutDC)" 
				class="col box-stat-bottom" 
				[ngClass]="{ 
					'tag-undefined': assetTags.VoltsOutDC == '-'
				}" 
				*ngIf="global.User.DebugMode || (assetTags.VoltsOutDC != '-' && !hideCertainJBTStandardObservationIds)" 
				[ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
			VoltsOut DC <br>
			<span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.VoltsOutDC}}</span>
		</div>
      </div>
     <div class="d-flex flex-row basis" style="flex-wrap: wrap; align-items: start; justify-content: normal !important">
      	<div class="d-flex flex-column" style="margin-bottom: 20px;">
			<div class="col box-stat" *ngIf="assetTags.VoltOutA != undefined || assetTags.VoltOutB != undefined || assetTags.VoltOutC != undefined">
				Volts Out Phase
			</div>
			<div class="linear-gauge-section">
				<div *ngIf="assetTags.VoltOutA != undefined" [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'voltOutA')" container="body" (mouseenter)="tooltipInformation('voltOutA')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(voltOutA)" class="linear-gauge-container">
					<kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.VoltOutAColor"  [value]="assetTags.VoltOutA"></kendo-progressbar>
					<h5>A</h5>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.VoltOutA}}</span></h5>
				</div>
				<div *ngIf="assetTags.VoltOutB != undefined" [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'voltOutB')" container="body" (mouseenter)="tooltipInformation('voltOutB')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(voltOutB)" class="linear-gauge-container">
					<kendo-progressbar [max]="700" orientation="vertical" [label]="label"  class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.VoltOutBColor" [value]="assetTags.VoltOutB"></kendo-progressbar>
					<h5>B</h5>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.VoltOutB}}</span></h5>
				</div>
				<div *ngIf="assetTags.VoltOutC != undefined" [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'voltOutC')" container="body" (mouseenter)="tooltipInformation('voltOutC')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(voltOutC)" class="linear-gauge-container">
					<kendo-progressbar [max]="700" orientation="vertical" [label]="label"  class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.VoltOutCColor" [value]="assetTags.VoltOutC"></kendo-progressbar>
					<h5>C</h5>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.VoltOutC}}</span></h5>
				</div>
			</div>
			<div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'voltOutAvg')" container="body" (mouseenter)="tooltipInformation('voltOutAvg')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(voltOutAvg)" class="col box-stat" *ngIf="assetTags.VoltOutAvg != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
				Volts Out Avg. <br>
				<span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.VoltOutAvg}}</span>
			</div>
		</div>
		<div class="d-flex flex-column" style="margin-bottom: 20px;">
			<div class="col box-stat" *ngIf="(assetTags.AmpOutA != undefined || assetTags.AmpOutB != undefined || assetTags.AmpOutC != undefined)">
				Amps Out Phase
			</div>
			<div class="linear-gauge-section" >
				<div *ngIf="assetTags.AmpOutA != undefined" [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'ampOutA')" container="body" (mouseenter)="tooltipInformation('ampOutA')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(ampOutA)" class="linear-gauge-container">
					<kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.AmpOutAColor"  [value]="assetTags.AmpOutA"></kendo-progressbar>
					<h5>A</h5>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.AmpOutA}}</span></h5>
				</div>
				<div *ngIf="assetTags.AmpOutB != undefined" [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'ampOutB')" container="body" (mouseenter)="tooltipInformation('ampOutB')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(ampOutB)" class="linear-gauge-container">
					<kendo-progressbar [max]="700" orientation="vertical" [label]="label"  class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.AmpOutBColor" [value]="assetTags.AmpOutB"></kendo-progressbar>
					<h5>B</h5>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.AmpOutB}}</span></h5>
				</div>
				<div *ngIf="assetTags.AmpOutC != undefined" [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'ampOutC')" container="body" (mouseenter)="tooltipInformation('ampOutC')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(ampOutC)" class="linear-gauge-container">
					<kendo-progressbar [max]="700" orientation="vertical" [label]="label"  class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.AmpOutCColor" [value]="assetTags.AmpOutC"></kendo-progressbar>
					<h5>C</h5>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.AmpOutC}}</span></h5>
				</div>
			</div>
			<div [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'ampOutAvg')" container="body" (mouseenter)="tooltipInformation('ampOutAvg')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(ampOutAvg)" class="col box-stat" *ngIf="assetTags.AmpOutAvg != undefined" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
				Amps Out Avg. <br>
				<span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.AmpOutAvg}}</span>
			</div>
		</div>	 
		<div class="d-flex flex-column" style="margin-bottom: 20px;">
			<div class="col box-stat" *ngIf="(assetTags.VoltInA != undefined || assetTags.VoltInB != undefined || assetTags.VoltInC != undefined) && !hideCertainJBTStandardObservationIds">
				Volts In Phase
			</div>
			<div class="linear-gauge-section"  *ngIf="!hideCertainJBTStandardObservationIds">
				<div *ngIf="assetTags.VoltInA != undefined" [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'voltInA')" container="body" (mouseenter)="tooltipInformation('voltInA')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(voltInA)" class="linear-gauge-container">
					<kendo-progressbar [max]="700" orientation="vertical" [label]="label" class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.VoltInAColor"  [value]="assetTags.VoltInA"></kendo-progressbar>
					<h5>A</h5>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.VoltInA}}</span></h5>
				</div>
				<div *ngIf="assetTags.VoltInB != undefined" [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'voltInB')" container="body" (mouseenter)="tooltipInformation('voltInB')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(voltInB)" class="linear-gauge-container">
					<kendo-progressbar [max]="700" orientation="vertical" [label]="label"  class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.VoltInBColor" [value]="assetTags.VoltInB"></kendo-progressbar>
					<h5>B</h5>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.VoltInB}}</span></h5>
				</div>
				<div *ngIf="assetTags.VoltInC != undefined" [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'voltInC')" container="body" (mouseenter)="tooltipInformation('voltInC')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(voltInC)" class="linear-gauge-container">
					<kendo-progressbar [max]="700" orientation="vertical" [label]="label"  class= "progress-bar-styles-vertical" [progressCssStyle]= "assetTags.VoltInCColor" [value]="assetTags.VoltInC"></kendo-progressbar>
					<h5>C</h5>
					<h5><span [ngClass]="{ 'hmi-on': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{ assetTags.VoltInC}}</span></h5>
				</div>
			</div>
			<div *ngIf="assetTags.VoltInAvg != undefined && !hideCertainJBTStandardObservationIds" [disablePopover]="!global.User.DebugMode" (contextmenu)="onRightClick($event, 'voltInAvg')" container="body" (mouseenter)="tooltipInformation('voltInAvg')" [ngbPopover]="popContent" [popoverTitle]="popTitle" [openDelay]="300" triggers="mouseenter:mouseleave" (click)="openTagGraphSingle(voltInAvg)" class="col box-stat" [ngStyle]="{color: theme === 'light' ? '#27304c' : 'white'}">
				Volts In Avg. <br>
				<span [ngClass]="{ 'hmi-blue': assetTags.UnitStatus, 'hmi-off': !assetTags.UnitStatus}">{{assetTags.VoltInAvg}}</span>
			</div>
		</div> 
      </div>
  </div>
</div>
