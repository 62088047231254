<sass-helper></sass-helper>
<lib-loading-spinner [loadingMessage]="'Loading Active Comms Loss Information'" *ngIf="isDataLoading"></lib-loading-spinner>
<div class="container-fluid" style="height: 100%" >
	<div class="row" style="height: 35%" *ngIf="isDataLoading === false">
		<div class="alert-count">
			<div class="major-alert-container">
				<span
					[style.color]="bodyTextColor"
					[style.font-size]="majorFontSize"
					class="alert-count-number"
					>{{ alertData.length }}</span
				>
			</div>
		</div>
		<div class="alert-chart">
			<div
				id="siteCommsLoss{{ widgetObject.WidgetId }}"
				style="width: 100%"
			></div>
		</div>
	</div>
	<div class="row" style="height: 65%">
		<div class="alert-list">
			<div
				(resized)="onResized($event)"
				[style.height.%]="100"
				class="card card-tasks card-body"
			>
				<div class="parent-table-container">

					<lib-kendo-grid-parent
							[gridData]="alertData"
							[gridSettings]="alertGridSettings"
							[widgetObject]="widgetObject"
							(callSave)="saveGridSettings()"
							[groupable]="false"
							[style.height.%]="100"
							filterable="menu"
							[sortable]="true"
							[reorderable]="true"
							[resizable]="true"
							*ngIf="isDataLoading === false && alertData !== undefined"
							#tagDataGrid

						>
						</lib-kendo-grid-parent>

				</div>
			</div>
		</div>
	</div>
</div>
