<lib-loading-spinner
	*ngIf="isDataLoading"
	[loadingMessage]="'Loading Summary Info...'"
	[vehicle]="'truck'"
></lib-loading-spinner>
<div
	*ngIf="!isDataLoading && (!fromDashboard || widgetObject?.WidgetAssetId)"
	class="card card-tasks"
	style="height: 100%"
>
	<div
		(resized)="onResized($event)"
		style="display: inline-block"
		style="height: 100%"
	>
		<mat-tab-group
			(animationDone)="animationRedraw()"
			(selectedTabChange)="tabHasChanged($event)"
			animationDuration="0ms"
			[selectedIndex]="materialTabSelected"
		>
			<mat-tab *ngFor="let tab of widgetTabs" [label]="tab.textLabel">
				<ng-template mat-tab-label>
					<span *ngIf="tab.textLabel != 'Alerts' && tab.textLabel != 'Faults'">
						{{ tab.textLabel }}
					</span>
					<span *ngIf="tab.textLabel === 'Faults'"
						[ngClass]="{
							criticalAlarmBadge: alertStatus === 3,
							alarmBadge: alertStatus === 1,
							warningBadge: alertStatus === 2
						}"
						[matBadge]="countOfAlarms > 0
								? countOfAlarms
								: undefined
						"
					>
						{{ tab.textLabel }}
					</span>
					<span *ngIf="tab.textLabel === 'Alerts'"
						[ngClass]="{
							alarmBadge: countOfAlerts > 0
						}"
						[matBadge]="countOfAlerts > 0
								? countOfAlerts
								: undefined
						"
					>
						{{ tab.textLabel }}
					</span>
				</ng-template>
				<div
					*ngIf="tab.textLabel === 'Data' && tab.rendered"
					class="parent-table-container"
				>
					<lib-kendo-grid-parent
						*ngIf="assetId && dataService.cache.assetsObject[assetId].Tags"
						[gridData]="
							dataService.cache.assetsObject[assetId].Tags
						"
						[gridSettings]="kendoGrids.dataGrid.gridSettings"
						[widgetObject]="widgetObject"
						[dataObject]="dataObject"
						(callSave)="saveGridSettings()"
						(checkForMetaDataInGSEService)="
							checkForMetaDataInGSEService($event)
						"
						(createMetaDataPopup)="createMetaDataPopup($event)"
						[style.height.%]="100"
						[gridContextMenuItems]="gridContextMenuItems"
						(onRightClickSelectEmitter)="onRightClickSelect($event)"
						#dataGrid
					>
					</lib-kendo-grid-parent>
				</div>
				<div *ngIf="assetHasCanvasTemplates">
					<div *ngFor="let template of canvasTemplates">
						<div *ngIf="tab.textLabel === template.Name && tab.rendered" class="parent-table-container" style="width: 100%">
							<div *ngIf="template.assetTemplateHasNoWidgetsMessage">
								<lib-centered-message centeredMessage="Template has no widgets configured. Please add them in the canvas widget"></lib-centered-message>
							</div>
							<div class="gridster-container" *ngIf="template.canvasWidgets && template.canvasWidgets.length > 0">
								<lib-generic-gridster-parent [widgets]="template.canvasWidgets" style="height: 100%"></lib-generic-gridster-parent>
							</div>
						</div>
					</div>
				</div>
				<div
					*ngIf="tab.textLabel === 'Map' && tab.rendered"
					style="height: 100%"
				>
					<lib-generic-overview-map
						*ngIf="childMapReadyToLoad === true"
						#gseSummaryGenericMapChild
						[tagOptions]="tagOptions"
						[widgetObject]="widgetObject"
						[assetList]="assetListForUserSites"
						[selectedMatTabIndex]="materialTabSelected"
						[matTabIndexForMap]="1"
						[fleetId]="fleetId"
					></lib-generic-overview-map>

				</div>

				<div
					*ngIf="tab.textLabel === 'Playback' && tab.rendered"
					style="height: 100%"
				>
					<lib-breadcrumbs
						*ngIf="widgetObject !== undefined"
						[widgetObject]="widgetObject"
						#breadcrumbsChild
					></lib-breadcrumbs>
					<lib-breadcrumbs
						*ngIf="dataObject !== undefined"
						[dataObject]="dataObject"
						#breadcrumbsChild
					></lib-breadcrumbs>
					<lib-breadcrumbs
						*ngIf="dataObject == undefined && widgetObject == undefined && assetObject !== undefined"
						[inputAssetObject]="assetObject"
						#breadcrumbsChild
					></lib-breadcrumbs>
				</div>

				<div
					*ngIf="tab.textLabel === 'Faults' && tab.rendered"
					class="parent-table-container"
				>
					<lib-kendo-grid-parent
						*ngIf="assetId !== undefined && this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags"
						[gridData]="faultsData()"
						[gridSettings]="kendoGrids.alarmsGrid.gridSettings"
						[widgetObject]="widgetObject"
						[configuration]="kendoGrids.alarmsGrid.config"
						(callSave)="saveGridSettings()"
						(checkForMetaDataInGSEService)="
							checkForMetaDataInGSEService($event)
						"
						(createMetaDataPopup)="createMetaDataPopup($event)"
						[style.height.%]="100"
						filterable="menu"
						[sortable]="true"
						[reorderable]="true"
						[resizable]="true"
						[gridContextMenuItems]="gridContextMenuItemsAlarms"
						(onRightClickSelectEmitter)="onRightClickSelect($event)"
						#alarmsGrid
					>
					</lib-kendo-grid-parent>
				</div>

				<div
					*ngIf="tab.textLabel === 'Alerts' && tab.rendered"
					class="parent-table-container"
				>
					<lib-kendo-grid-parent
						*ngIf="assetId !== undefined && this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags"
						[gridData]="alertsData()"
						[gridSettings]="kendoGrids.alertsGrid.gridSettings"
						[widgetObject]="widgetObject"
						[configuration]="kendoGrids.alertsGrid.config"
						(callSave)="saveGridSettings()"
						(checkForMetaDataInGSEService)="
							checkForMetaDataInGSEService($event)
						"
						(createMetaDataPopup)="createMetaDataPopup($event)"
						[style.height.%]="100"
						filterable="menu"
						[sortable]="true"
						[reorderable]="true"
						[resizable]="true"
						[gridContextMenuItems]="gridContextMenuItemsAlarms"
						(onRightClickSelectEmitter)="onRightClickSelect($event)"
						#alertsGrid
					>
					</lib-kendo-grid-parent>
				</div>

				<div
					*ngIf="tab.textLabel === 'Last 1000 Events' && tab.rendered"
					class="parent-table-container"
				>
					<lib-last-thousand-grid
						[widgetObject]="widgetObject"
						*ngIf="
							!isDataLoading &&
							kendoGrids.last1000Events.isRendered
						"
						#last1000EventsGrid
						[gridSettings]="kendoGrids.last1000Events.gridSettings"
						[configuration]="kendoGrids.last1000Events.config"
						[parentContainerSize]="parentContainerSize"
						(callSave)="saveGridSettings()"
					></lib-last-thousand-grid>
				</div>

				<div
					*ngIf="tab.textLabel === 'Historic Faults' && tab.rendered"
					class="parent-table-container"
				>
					<lib-last-thousand-grid
						[widgetObject]="widgetObject != undefined ? widgetObject : dataObject "
						*ngIf="
							!isDataLoading &&
							kendoGrids.last1000Alarms.isRendered
						"
						#last1000AlarmsGrid
						[gridSettings]="kendoGrids.last1000Alarms.gridSettings"
						[configuration]="kendoGrids.last1000Alarms.config"
						[parentContainerSize]="parentContainerSize"
						(callSave)="saveGridSettings()"
						(trendBreadcrumbsEvent)="trendBreadCrumbsFromLast1000Grid($event)"
					></lib-last-thousand-grid>
				</div>
				<div
					*ngIf="tab.textLabel === 'Historic Alerts' && tab.rendered"
					class="parent-table-container"
				>
					<lib-last-thousand-grid
						[widgetObject]="widgetObject != undefined ? widgetObject : dataObject "
						*ngIf="
							!isDataLoading &&
							kendoGrids.last1000Alerts.isRendered
						"
						#last1000AlarmsGrid
						[gridSettings]="kendoGrids.last1000Alerts.gridSettings"
						[configuration]="kendoGrids.last1000Alerts.config"
						[parentContainerSize]="parentContainerSize"
						(callSave)="saveGridSettings()"
						(trendBreadcrumbsEvent)="trendBreadCrumbsFromLast1000Grid($event)"
					></lib-last-thousand-grid>
				</div>

				<div
					*ngIf="tab.textLabel === 'Fuel' && tab.rendered"
					class="parent-table-container"
				>
					<lib-dynamic-form
						*ngIf="
							loadingFuelForm !== undefined &&
							loadingFuelForm !== true
						"
						[hidden]="loadingFuelForm"
						[questions]="fuelFields"
						[options]="fuelFormOptions"
						(submittedValues)="submitFuelEvent($event)"
					></lib-dynamic-form>

					<lib-kendo-grid-parent
						[hidden]="loadingFuelForm"
						[gridData]="kendoGrids.fuelEventsGrid.data"
						[gridSettings]="kendoGrids.fuelEventsGrid.gridSettings"
						[widgetObject]="widgetObject"
						(callSave)="saveGridSettings()"
						(checkForMetaDataInGSEService)="
							checkForMetaDataInGSEService($event)
						"
						(createMetaDataPopup)="createMetaDataPopup($event)"
						[style.height.%]="100"
						filterable="menu"
						[sortable]="true"
						[reorderable]="true"
						[resizable]="true"
						*ngIf="kendoGrids.fuelEventsGrid.data"

						#fuelEventsGrid
					>
					</lib-kendo-grid-parent>
				</div>

				<div
					*ngIf="
						tab.textLabel === 'Deicer Events' &&
						tab.rendered &&
						assetIsDeicer
					"
					class="parent-table-container"
				>
					<lib-dynamic-form
						*ngIf="
							loadingDeicerForm !== undefined &&
							loadingDeicerForm !== true
						"
						[hidden]="loadingDeicerForm"
						[questions]="deicerFields"
						[options]="deicerFormOptions"
						(submittedValues)="submitDeicerEvent($event)"
					></lib-dynamic-form>

					<lib-kendo-grid-parent
						[hidden]="loadingFuelForm"
						[gridData]="kendoGrids.deicerEventsGrid.data"
						[gridSettings]="
							kendoGrids.deicerEventsGrid.gridSettings
						"
						[widgetObject]="widgetObject"
						(callSave)="saveGridSettings()"
						(checkForMetaDataInGSEService)="
							checkForMetaDataInGSEService($event)
						"
						(createMetaDataPopup)="createMetaDataPopup($event)"
						[style.height.%]="100"
						filterable="menu"
						[sortable]="true"
						[reorderable]="true"
						[resizable]="true"
						*ngIf="kendoGrids.deicerEventsGrid.data"

						#deicerEventsGrid
					>
					</lib-kendo-grid-parent>
				</div>

				<div
					*ngIf="
						tab.textLabel === 'Maintenance' &&
						tab.rendered &&
						loadingMaintenanceForm === false
					"
					class="parent-table-container"
				>
					<form class="form-horizontal" [formGroup]="maintenanceForm">
						<div style="overflow-x: hidden; overflow-y: auto">
							<div
								*ngIf="
									maintenanceForm &&
									!maintenanceForm.value.outOfService
								"
								class="row col-sm-12 col-md-12"
							>
								<div class="col-sm-3 col-md-3 col-form-label">
									<label for="MaintenanceMode{{widgetInstance}}"
										>Maintenance Mode</label
									>
								</div>
								<div class="col-sm-9 col-md-9">
									<input
										type="checkbox"
										id="MaintenanceMode{{widgetInstance}}"
										formControlName="maintenanceMode"
										class="form-control"
										class="jbt-slider-bar"
									/>
									<label
										class="dynamic-form-slider-switch"
										for="MaintenanceMode{{widgetInstance}}"
										title="Click here if the asset is going into Maintenance Mode."
										(click)="
											sliderSelect(
												$event,
												maintenanceForm
											)
										"
									></label>
									<div
										class="dynamic-form-slider-text"
										title="Click here if the asset is going into Maintenance Mode."
									>
										<div class="slider-text-on">ON</div>
										<div class="slider-text-off">OFF</div>
									</div>
								</div>
							</div>
							<div
								*ngIf="
									maintenanceForm &&
									!maintenanceForm.value.maintenanceMode
								"
								class="row col-sm-12 col-md-12"
							>
								<div class="col-sm-3 col-md-3 col-form-label">
									<label for="OutOfService{{widgetInstance}}"
										>Out of Service</label
									>
								</div>
								<div class="col-sm-9 col-md-9">
									<input
										type="checkbox"
										id="OutOfService{{widgetInstance}}"
										formControlName="outOfService"
										class="jbt-slider-bar"
									/>
									<label
										class="dynamic-form-slider-switch"
										for="OutOfService{{widgetInstance}}"
										title="Click here if the asset is Out of Service."
										(click)="
											sliderSelect(
												$event,
												maintenanceForm
											)
										"
									></label>
									<div
										class="dynamic-form-slider-text"
										title="Click here if the asset is Out of Service."
									>
										<div class="slider-text-on">ON</div>
										<div class="slider-text-off">OFF</div>
									</div>
								</div>
							</div>
							<div
								*ngIf="
									maintenanceForm &&
									!maintenanceForm.value.outOfService &&
									maintenanceForm.value.maintenanceMode
								"
								class="row col-sm-12 col-md-12"
							>
								<div class="col-sm-3 col-md-3 col-form-label">
									<label for="maintenanceModeReasonId"
										>Reason</label
									>
								</div>
								<div class="col-sm-9 col-md-9">
									<select
										class="form-control"
										title="Please select the reason this asset is going into Maintenance Mode."
										id="maintenanceModeReasonId"
										formControlName="maintenanceModeReasonId"
										(change)="
											saveValue($event, maintenanceForm)
										"
									>
										<option
											class="form-control"
											*ngFor="
												let opt of maintenanceModeReasons
											"
											[value]="opt.Id"
										>
											{{ opt.Name }}
										</option>
									</select>
								</div>
							</div>
							<div
								*ngIf="
									maintenanceForm &&
									!maintenanceForm.value.maintenanceMode &&
									maintenanceForm.value.outOfService
								"
								class="row col-sm-12 col-md-12"
							>
								<div class="col-sm-3 col-md-3 col-form-label">
									<label for="outOfServiceReasonId"
										>Reason</label
									>
								</div>
								<div class="col-sm-9 col-md-9">
									<select
										class="form-control"
										title="Please select the reason this asset is Out of Service."
										id="outOfServiceReasonId"
										formControlName="outOfServiceReasonId"
										(change)="
											saveValue($event, maintenanceForm)
										"
									>
										<option
											class="form-control"
											*ngFor="
												let opt of outOfServiceReasons
											"
											[value]="opt.Id"
										>
											{{ opt.Name }}
										</option>
									</select>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div
					*ngIf="tab.textLabel === 'Information' && tab.rendered"
					class="parent-table-container"
				>
					<lib-kendo-grid-parent
						*ngIf="assetId !== undefined"
						[gridData]="informationTab()"
						[gridSettings]="kendoGrids.infoGrid.gridSettings"
						(callSave)="saveGridSettings()"
						[style.height.%]="100"
						filterable="menu"
						[sortable]="true"
						[reorderable]="true"
						[resizable]="true"
						[gridContextMenuItems]="gridContextMenuItemsAlarms"
						(onRightClickSelectEmitter)="onRightClickSelect($event)"

						#infoGrid
					>
					</lib-kendo-grid-parent>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>

<div
	*ngIf="
		!isDataLoading &&
		widgetObject !== undefined &&
		!widgetObject?.WidgetAssetId
	"
	class="card card-tasks center"
	id="card-whole"
>
	<lib-centered-message
		centeredMessage="Please select an asset from the widget settings."
	>
	</lib-centered-message>
</div>
